import React, { useEffect, useState, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import Aside from "../FixedBar/Aside";
import axios from "axios";
import ZoneData from "../SystemDetails/Zone for Hot Water.json";

import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Add, ContentPaste, CopyAllOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ProfileInstallationTeam from "../Trader/Teamdashboard/ProfileInstallationTeam";
import moment from "moment/moment";
import HeaderComponent from "../Dashboard/CommonComponents/HeaderComponent";
import "./CreateHotWaterJob.css";
import ManageFolder from "../Dashboard/CommonComponents/ManageFolder/ManageFolder";
import DocumentsRequired from "../Dashboard/DocumentRequired";
import SignaturePage from "../Dashboard/OnSiteData/SignaturePage";
import { format } from "date-fns";
import { FadeLoader } from "react-spinners";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;

const CreateHWJob = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");
  const retailerId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");

  //All Dropdowns starts
  const [streetOptions, setStreetOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [installationTypeOptions, setInstallationTypeOptions] = useState([]);
  const [installingForOptions, setInstallingForOptions] = useState([]);
  const [storeyTypeOptions, setStoreyTypeOptions] = useState([]);
  const [mountingTypeOptions, setMountingTypeOptions] = useState([]);
  const [addressTypeOptions, setAddressTypeOptions] = useState([]);
  const [showSWUnitDropdown, setShowSWUnitDropdown] = useState(false);
  const [SWUnitData, setSWUnitData] = useState([]);
  const [zone, setZone] = useState("");
  const [tankInfo, setTankInfo] = useState();
  const [loadingJobData, setLoadingJobData] = useState(false);

  const inputUnitField = useRef(null);

  useEffect(() => {
    axios
      .get(
        `${baseURL}/get/masterData?type=addressType,unitType,streetType,state,installingFor,mountingType,systemType,roof,storey,panelType,phase,connectionType,installationType`
      )
      .then((response) => {
        const master = response.data;
        setAddressTypeOptions(master.data[0].values);
        setInstallationTypeOptions(master.data[2].values);
        setInstallingForOptions(master.data[3].values);
        setMountingTypeOptions(master.data[4].values);
        setStateOptions(master.data[8].values);
        setStoreyTypeOptions(master.data[9].values);
        setStreetOptions(master.data[10].values);
        setUnitOptions(master.data[12].values);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  //All Dropdowns ends

  //Form Data starts
  const [formdata, setFormdata] = useState({
    installationAddress: {
      addressType: "Physical",
      unitType: "",
      unitNumber: null,
      streetNumber: null,
      streetName: "",
      streetType: "",
      suburb: "",
      state: "",
      postCode: null,
      latitude: null,
      longitude: null,
    },

    postalAddress: {
      POBox: {
        POBoxNo: null,
        postalCode: null,
        postalArea: "",
      },
      POAddress: {
        addressType: "",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
    },

    installingFor: "",
    systemOwnerType: "",
    installationType: "",

    ownerDetails: {
      firstName: "",
      lastName: "",
      companyName: "",
      mobileNum: "",
      phoneNumber: "",
      emailId: "",
      ABN: null,
      isGST: false,
      gstNo: "",
    },

    coOwnerDetail: {
      firstName: "",
      lastName: "",
      mobileNum: "",
      phoneNumber: "",
      emailId: "",
    },

    installationDate: {
      installingDate: "",
      STCDeemingPeriod: null,
      mountingType: "",
    },

    siteAttributes: {
      roof: "",
      storey: "",
      panelType: "",
      phase: "",
    },

    hotWaterDetail: {
      hotWaterBrand: "",
      hotWaterInfo: {
        hotWaterModel: "",
        hotWaterCapacity: null,
        hotWaterQuantity: 1,
      },
      isHeatPump: false,
      noOfPanels: null,
      stc: null,
      financialPayment: null,
    },

    isInstallerSelfie: false,

    plumbers: [],
    plumberName: "",
    gasfitters: [],
    gasfitterName: "",
    electricians: [],
    electricianName: "",

    instructionForInstallation: "",

    installationDetails: {
      CECAccreditationSystem: true,
      territoryGovermentApproval: true,
      electricalSafetyDoc: true,
      AusNZStandardStatement: true,
    },

    notes: "",

    failedSWH: {
      isItForPreviouslyFailedSWH: false,
      accreditationCode: "",
      explanatoryNotes: "",
    },
    customerInvoice: "",
    claimStcPayment: "",
    plumbingCertificate: "",
    electricalCompliance: "",
    otherDocuments: "",
    jobType: "SWH",
  });
  //Form Data ends
  //Google GeoCoding API Call Starts
  const [status, setStatus] = useState();
  const [statusCode, setStatusCode] = useState();
  useEffect(() => {
    const getLatLong = async () => {
      try {
        const address = `${formdata?.installationAddress.addressType} ${formdata?.installationAddress.unitType} ${formdata?.installationAddress.unitNumber} ${formdata?.installationAddress.streetNumber} ${formdata?.installationAddress.streetName} ${formdata?.installationAddress.streetType}, ${formdata?.installationAddress.suburb}, ${formdata?.installationAddress.state}, ${formdata?.installationAddress.postCode}`;

        const resp = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&address=${encodeURIComponent(
            address
          )}`
        );
        const data = resp?.data?.results[0];
        const latitude = data?.geometry?.location?.lat;
        const longitude = data?.geometry?.location?.lng;

        setFormdata((prevState) => ({
          ...prevState,
          installationAddress: {
            ...prevState.installationAddress,
            latitude: latitude,
            longitude: longitude,
          },
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getLatLong();
  }, [
    formdata?.installationAddress?.addressType,
    formdata?.installationAddress?.unitType,
    formdata?.installationAddress?.unitNumber,
    formdata?.installationAddress?.streetNumber,
    formdata?.installationAddress?.streetName,
    formdata?.installationAddress?.streetType,
    formdata?.installationAddress?.suburb,
    formdata?.installationAddress?.state,
    formdata?.installationAddress?.postCode,
  ]);

  //Get Job Details Starts
  const fetchJobData = async () => {
    setLoadingJobData(true);
    try {
      const resp = await axios.get(`${baseURL}/job/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const flattenedData = resp.data.flat();
      const result = flattenedData.filter((item) => item._id === jobId);
      setStatus(result[0]?.status[0]);
      setStatusCode(result[0]?.statusCode);
      const deepMerge = (target, source) => {
        Object.keys(target).forEach((key) => {
          if (source[key] !== undefined && source[key] !== null) {
            if (
              typeof target[key] === "object" &&
              typeof source[key] === "object"
            ) {
              if (Array.isArray(target[key]) && Array.isArray(source[key])) {
                if (source[key].length > 0) {
                  target[key] = [...source[key]];
                }
              } else {
                deepMerge(target[key], source[key]);
              }
            } else {
              target[key] = source[key];
            }
          }
        });
        return target;
      };

      const updatedFormData = deepMerge({ ...formdata }, result[0]);
      setFormdata(updatedFormData);

      const date = moment(
        updatedFormData?.installationDate?.installingDate
      ).format("YYYY-MM-DD");
      setFormdata((prev) => ({
        ...prev,
        installationDate: {
          ...prev.installationDate,
          installingDate: date,
        },
      }));

      const coOwnerDetailKeys = Object.keys(updatedFormData?.coOwnerDetail);
      const ownerDetailKeys = Object.keys(updatedFormData?.ownerDetails);

      const areDetailsEqual = coOwnerDetailKeys.every(
        (key) =>
          ownerDetailKeys.includes(key) &&
          updatedFormData?.coOwnerDetail[key] ===
            updatedFormData?.ownerDetails[key]
      );

      if (areDetailsEqual) {
        setIsChecked(true);
      }

      // if (updatedFormData?.installationAddress?.addressType === "Postal") {
      //   setSameAsAboveAddress(false);
      // } else {
      const installationAddressKeys = Object.keys(
        updatedFormData?.installationAddress
      );
      const postalAddressKeys = Object.keys(
        updatedFormData?.postalAddress?.POAddress
      );

      const filteredInstallationKeys = installationAddressKeys.filter(
        (key) =>
          key.toLowerCase() !== "latitude" && key.toLowerCase() !== "longitude"
      );

      const areAddressesEqual = filteredInstallationKeys.every(
        (key) =>
          postalAddressKeys.includes(key) &&
          updatedFormData?.installationAddress[key] ===
            updatedFormData?.postalAddress?.POAddress[key]
      );

      if (areAddressesEqual) {
        setSameAsAboveAddress(true);
        // }
      }

      setLoadingJobData(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingJobData(false);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  //Get Job Details Ends

  //input handling starts

  //manualButton
  const [manualButton, setMenualButton] = useState(false);
  const swithingAddress = () => {
    setMenualButton(!manualButton);
  };
  //manualButton

  // google auto address
  const [address, SetAddress] = useState("");
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const lat = result[0].geometry.location.lat();
    const lng = result[0].geometry.location.lng();
    setSelectedLocation({
      lat: lat,
      lng: lng,
    });
    // console.log(lat,lng);

    SetAddress(result[0].formatted_address);
    const addressComponents = result[0].address_components;
    //function to get the components
    const getComponent = (type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type)
      );

      if (component) {
        return component.short_name || "";
      } else {
        return "";
      }
    };

    const fullStreetName = getComponent("route");
    let streetName, streetType;
    if (fullStreetName.split(" ").length > 2) {
      const words = fullStreetName.split(" ");
      streetType = words.pop();
      streetName = words.join(" ");
    } else {
      [streetName, streetType] = fullStreetName.split(" ", 2);
    }

    const streetNo = getComponent("street_number");
    const suburb =
      getComponent("locality") ||
      getComponent("sublocality") ||
      getComponent("political");
    const state = getComponent("administrative_area_level_1");
    const postcode = getComponent("postal_code");

    setFormdata((prevState) => ({
      ...prevState,
      installationAddress: {
        ...prevState.installationAddress,
        unitNumber: prevState.installationAddress.unitNumber,
        streetNumber: streetNo,
        streetName: streetName,
        streetType: streetType,
        suburb: suburb,
        state: state,
        postCode: postcode,
      },
    }));
    SetAddress("");
    setMenualButton(!manualButton);
  };
  // google auto address

  //Map Starts

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  //Map Ends

  //manualButtonPostal
  const [manualButtonPostal, setMenualButtonPostal] = useState(false);

  const swithingPostalAddress = () => {
    setMenualButtonPostal(!manualButtonPostal);
  };
  //manualButtonPostal

  // google auto address postal
  const [postalAddress, SetPostalAddress] = useState("");
  const handlePostalSelect = async (value) => {
    const result = await geocodeByAddress(value);
    SetPostalAddress(result[0].formatted_address);
    const addressComponents = result[0].address_components;

    //function to get the components
    const getComponent = (type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type)
      );

      if (component) {
        return component.short_name || "";
      } else {
        return "";
      }
    };

    const fullStreetName = getComponent("route");
    let streetName, streetType;
    if (fullStreetName.split(" ").length > 2) {
      const words = fullStreetName.split(" ");
      streetType = words.pop();
      streetName = words.join(" ");
    } else {
      [streetName, streetType] = fullStreetName.split(" ", 2);
    }

    const streetNo = getComponent("street_number");
    const suburb =
      getComponent("locality") ||
      getComponent("sublocality") ||
      getComponent("political");
    const state = getComponent("administrative_area_level_1");
    const postcode = getComponent("postal_code");

    setFormdata((prevState) => ({
      ...prevState,
      postalAddress: {
        ...prevState.postalAddress,
        POAddress: {
          ...prevState.postalAddress.POAddress,
          unitNumber: prevState.postalAddress.POAddress.unitNumber, // Keep existing unitNo or add logic to extract
          streetNumber: streetNo,
          streetName: streetName,
          streetType: streetType,
          suburb: suburb,
          state: state,
          postCode: postcode,
        },
      },
    }));
    setMenualButtonPostal(!manualButtonPostal);
  };
  // google auto address postal

  const handleInputsInstallerAddress = (e) => {
    const { name, type, value } = e.target;

    setFormdata((prev) => ({
      ...prev,
      installationAddress: {
        ...prev.installationAddress,
        [name]: type === "number" ? Number(value) : value,
      },
    }));

    if (name === "addressType" && value === "Postal") {
      setSameAsAboveAddress(false);
    }
  };

  const handleInputsInstallationDetails = (e) => {
    const { name, type, value } = e.target;

    setFormdata((prev) => ({
      ...prev,
      installationDetails: {
        ...prev.installationDetails,
        [name]:
          type === "radio"
            ? value === "true"
            : type === "number"
            ? Number(value)
            : value,
      },
    }));
  };

  const [sameAsAboveAddress, setSameAsAboveAddress] = useState(false);

  const handleAddressCheckboxChange = () => {
    setSameAsAboveAddress(!sameAsAboveAddress);
    setpoBox(false);
  };

  useEffect(() => {
    if (sameAsAboveAddress) {
      setpoBoxAdres(true);
      setMenualButtonPostal(true);
      setFormdata((prevFormData) => ({
        ...prevFormData,
        postalAddress: {
          ...prevFormData.postalAddress,
          POAddress: { ...prevFormData.installationAddress },
        },
      }));
    } else {
      setFormdata((prevFormData) => ({
        ...prevFormData,
        postalAddress: {
          POAddress: {
            street: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
        },
      }));
    }
  }, [sameAsAboveAddress]);

  //poBoxclicked
  const [poBox, setpoBox] = useState(false);
  const poBoxclicked = () => {
    setpoBox(!poBox);
    setpoBoxAdres(false);
  };
  //poBoxclicked

  //poBoxAdressclicked
  const [poBoxAdres, setpoBoxAdres] = useState(false);
  const poBoxAdressclicked = () => {
    setpoBoxAdres(!poBoxAdres);
    setpoBox(false);
  };
  //poBoxAdressclicked

  const handleInputsPostalAddressPOBoxPOBoxNo = (e) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      postalAddress: {
        ...prev.postalAddress,
        POBox: {
          ...prev.postalAddress.POBox,
          [name]: type === "number" ? Number(value) : value,
        },
      },
    }));
  };

  const handleInputsPostalAddressPOBoxPOAddress = (e) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      postalAddress: {
        ...prev.postalAddress,
        POAddress: {
          ...prev.postalAddress.POAddress,
          [name]: type === "number" ? Number(value) : value,
        },
      },
    }));
  };

  const handleInputsInstallerPostalAddressDropDown = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      postalAddress: {
        ...prev.postalAddress,
        POAddress: {
          ...prev.postalAddress.POAddress,
          [name]: value,
        },
      },
    }));
  };

  const handleInputsinstallingType = (e) => {
    const { name, value } = e.target;
  
    setFormdata((prev) => {
      let updatedOwnerDetails = { ...prev.ownerDetails };
  
      if (name === "installingFor") {
        if (value === "Individual") {
          updatedOwnerDetails = {
            ...updatedOwnerDetails,
            companyName: "",  
            firstName: updatedOwnerDetails.firstName || "", 
            lastName: updatedOwnerDetails.lastName || "",  
          };
        } else {
          updatedOwnerDetails = {
            ...updatedOwnerDetails,
            firstName: "",  
            lastName: "",  
            companyName: updatedOwnerDetails.companyName || "",
          };
        }
      }
  
      return {
        ...prev,
        [name]: value,  
        ownerDetails: updatedOwnerDetails,
      };
    });
    setIsChecked(false);
  };

  const handleInputsSystemOwnerType = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputCustomerDetails = (event) => {
    const { name, type, value } = event.target;

    if (name === "phoneNumber") {
      if (value.startsWith("0")) {
        setFormdata({
          ...formdata,
          ownerDetails: {
            ...formdata.ownerDetails,
            phoneNumber: value,
          },
        });
      } else {
        const sanitizedValue = value.replace(/^0+/, "");
        setFormdata({
          ...formdata,
          ownerDetails: {
            ...formdata.ownerDetails,
            phoneNumber: sanitizedValue,
          },
        });
      }
    } else {
      setFormdata((prev) => ({
        ...prev,
        ownerDetails: {
          ...prev.ownerDetails,
          [name]: type === "number" ? Number(value) : value,
        },
      }));
    }
  };

  const handleInputCustomerDetailsRadio = (e) => {
    const { name, value, type } = e.target;
    const newValue =
      type === "radio" ? (value === "true" ? true : false) : value;

    setFormdata((prev) => ({
      ...prev,
      ownerDetails: {
        ...prev.ownerDetails,
        [name]: newValue,
        ...(value === "false" && { ABN: null }),
      },
    }));
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      setFormdata((prevFormData) => ({
        ...prevFormData,
        coOwnerDetail: { ...prevFormData.ownerDetails },
      }));
    } else {
      setFormdata((prevFormData) => ({
        ...prevFormData,
        coOwnerDetail: {
          firstName: "",
          lastName: "",
          mobileNum: "",
          phoneNumber: "",
          emailId: "",
        },
      }));
    }
  };

  const handleInputCoOwnerDetails = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      if (value.startsWith("0")) {
        setFormdata((prev) => ({
          ...prev,
          coOwnerDetail: {
            ...prev.coOwnerDetail,
            phoneNumber: value,
          },
        }));
      } else {
        const sanitizedValue = value.replace(/^0+/, "");
        setFormdata((prev) => ({
          ...prev,
          coOwnerDetail: {
            ...prev.coOwnerDetail,
            phoneNumber: sanitizedValue,
          },
        }));
      }
    } else {
      setFormdata((prev) => ({
        ...prev,
        coOwnerDetail: {
          ...prev.coOwnerDetail,
          [name]: value,
        },
      }));
    }
  };

  const deemingPeriods = {
    2024: 7,
    2025: 6,
    2026: 5,
    2027: 4,
    2028: 3,
    2029: 2,
    2030: 1,
  };

  const handleInputsInstallationDate = (e) => {
    const { name, value } = e.target;

    if (name === "installingDate") {
      const selectedYear = new Date(value).getFullYear();
      const deemingPeriod = deemingPeriods[selectedYear] || 1;

      setFormdata((prev) => ({
        ...prev,
        installationDate: {
          ...prev.installationDate,
          [name]: value,
          STCDeemingPeriod: deemingPeriod,
        },
      }));
    } else {
      setFormdata((prev) => ({
        ...prev,
        installationDate: {
          ...prev.installationDate,
          [name]: value,
        },
      }));
    }
  };

  const formatDateForDeemingPeriod = (date) => {
    if (!date) return "";

    const deemingPeriodString =
      {
        1: "One Year",
        2: "Two Years",
        3: "Three Years",
        4: "Four Years",
        5: "Five Years",
        6: "Six Years",
        7: "Seven Years",
      }[date] || "";

    return deemingPeriodString;
  };

  const handleInputSiteDetails = (e) => {
    const { name, value } = e.target;

    setFormdata((prev) => ({
      ...prev,
      siteAttributes: {
        ...prev.siteAttributes,
        [name]: value,
      },
    }));
  };

  const handleInputInstallationType = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlefailedSWHChange = (e) => {
    const { name, value, type } = e.target;
    setFormdata((prevData) => {
      let updatedValue;
      if (type === "radio") {
        updatedValue = value === "true";
      } else {
        updatedValue = value;
      }
      return {
        ...prevData,
        failedSWH: {
          ...prevData.failedSWH,
          [name]: updatedValue,
        },
      };
    });
  };

  const handleInputsPanelInfo = (e) => {
    const { name, type, value } = e.target;

    const parsedValue = type === "radio" ? value === "true" : value;

    setFormdata((prevData) => ({
      ...prevData,
      hotWaterDetail: {
        ...prevData.hotWaterDetail,
        [name]: parsedValue,
      },
    }));
  };

  // Installation Team DropDowns starts
  const [plumberData, setPlumberData] = useState([]);
  const [gasfitterData, setGasfitterData] = useState([]);
  const [electricianData, setElectricianData] = useState([]);

  useEffect(() => {
    const test = async () => {
      try {
        const api =
          userType === "Trader"
            ? `${baseURL}installer/detailsForTrader`
            : `${baseURL}/installer/details`;
        const resp = await axios.get(api, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = resp.data;
        let filteredPlumberData = data.installerData;

        if (userType === "Retailer") {
          filteredPlumberData = filteredPlumberData.filter((innerArray) =>
            innerArray.retailers.some(
              (retailer) =>
                retailer._id === retailerId && retailer.isConnect === 1
            )
          );
        }

        filteredPlumberData = filteredPlumberData.filter(
          (innerArray) =>
            innerArray.installer &&
            innerArray.installer.selectYourRole &&
            innerArray.installer.selectYourRole.includes("Plumber")
        );

        const simplifiedDataPlumber = filteredPlumberData.map(
          (installerData) => ({
            name: installerData?.installer?.name,
            id: installerData?._id,
            cecExpiry: format(
              new Date(installerData?.plumberLicenseExpiry),
              "yyyy-MM-dd"
            ),
          })
        );
        setPlumberData(simplifiedDataPlumber);

        let filteredGasfitterData = data.installerData;

        if (userType === "Retailer") {
          filteredGasfitterData = filteredGasfitterData.filter((innerArray) =>
            innerArray.retailers.some(
              (retailer) =>
                retailer._id === retailerId && retailer.isConnect === 1
            )
          );
        }

        filteredGasfitterData = filteredGasfitterData.filter(
          (installer) =>
            installer.installer &&
            installer.installer.selectYourRole &&
            installer.installer.selectYourRole.includes("Gasfitter")
        );

        const simplifiedDataGasfitter = filteredGasfitterData.map(
          (installerData) => ({
            name: installerData?.installer?.name,
            id: installerData?._id,
            cecExpiry: format(
              new Date(installerData?.gasfitterLicenseExpiry),
              "MMMM d, yyyy"
            ),
          })
        );

        setGasfitterData(simplifiedDataGasfitter);

        let filteredElectricianData = data.installerData;

        if (userType === "Retailer") {
          filteredElectricianData = filteredElectricianData.filter(
            (innerArray) =>
              innerArray.retailers.some(
                (retailer) =>
                  retailer._id === retailerId && retailer.isConnect === 1
              )
          );
        }

        filteredElectricianData = filteredElectricianData.filter(
          (installer) =>
            installer.installer &&
            installer.installer.selectYourRole &&
            installer.installer.selectYourRole.includes("Electrician")
        );

        const simplifiedDataElectrician = filteredElectricianData.map(
          (installerData) => ({
            name: installerData?.installer?.name,
            id: installerData?._id,
            cecExpiry: format(
              new Date(installerData?.electricianlicenceExpiry),
              "MMMM d, yyyy"
            ),
          })
        );

        setElectricianData(simplifiedDataElectrician);
      } catch (err) {}
    };

    test();
  }, []);

  const handleSelectPlumberChange = (event) => {
    const selectedId = event.target.value;
    if (selectedId === "") {
      setFormdata((prev) => ({
        ...prev,
        plumberName: "",
        plumbers: [],
        gasfitterName: "",
        gasfitters: [],
        electricianName: "",
        electricians: [],
      }));
    } else {
      const selectedPlumber = plumberData.find(
        (installer) => installer.id === selectedId
      );

      if (selectedPlumber) {
        const currentDate = moment();
        const licenseExpiryDate = moment(selectedPlumber?.cecExpiry);
        const daysUntilExpiry = licenseExpiryDate.diff(currentDate, "days");
        if (daysUntilExpiry <= 15) {
          alert(
            `The license for ${
              selectedPlumber.name
            } is expiring on ${licenseExpiryDate.format("DD-MM-YYYY")}`
          );
        }
        setFormdata((prev) => ({
          ...prev,
          plumberName: selectedPlumber.name,
          plumbers: [selectedPlumber.id],
          gasfitterName: gasfitterData.some(
            (gasfitter) => gasfitter.id === selectedId
          )
            ? selectedPlumber.name
            : "",
          gasfitters: gasfitterData.some(
            (gasfitter) => gasfitter.id === selectedId
          )
            ? [selectedPlumber.id]
            : [],
          electricianName: electricianData.some(
            (electrician) => electrician.id === selectedId
          )
            ? selectedPlumber.name
            : "",
          electricians: electricianData.some(
            (electrician) => electrician.id === selectedId
          )
            ? [selectedPlumber.id]
            : [],
        }));
      }
    }
  };

  const handleSelectDesignerChange = (event) => {
    const selectedId = event.target.value;

    if (selectedId === "") {
      setFormdata((prev) => ({
        ...prev,
        designerName: "",
        designers: [],
      }));
    } else {
      const selectedData = gasfitterData.find(
        (designer) => designer.id === selectedId
      );

      if (selectedData) {
        const currentDate = moment();
        const licenseExpiryDate = moment(selectedData?.cecExpiry);
        const daysUntilExpiry = licenseExpiryDate.diff(currentDate, "days");
        if (daysUntilExpiry <= 15) {
          alert(
            `The license for ${
              selectedData.name
            } is expiring on ${licenseExpiryDate.format("DD-MM-YYYY")}`
          );
        }
        setFormdata((prev) => ({
          ...prev,
          designerName: selectedData.name,
          designers: [selectedData.id],
        }));
      }
    }
  };

  const handleSelectElectricianChange = (event) => {
    const selectedId = event.target.value;

    if (selectedId === "") {
      setFormdata((prev) => ({
        ...prev,
        electricianName: "",
        electricians: [],
      }));
    } else {
      const selectedData = electricianData.find(
        (electrician) => electrician.id === selectedId
      );

      if (selectedData) {
        const currentDate = moment();
        const licenseExpiryDate = moment(selectedData?.cecExpiry);
        const daysUntilExpiry = licenseExpiryDate.diff(currentDate, "days");
        if (daysUntilExpiry <= 15) {
          alert(
            `The license for ${
              selectedData.name
            } is expiring on ${licenseExpiryDate.format("DD-MM-YYYY")}`
          );
        }
        setFormdata((prev) => ({
          ...prev,
          electricianName: selectedData.name,
          electricians: [selectedData.id],
        }));
      }
    }
  };

  const handleInputsInstructionForInstallation = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleIsInstallerSelfieChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = value === "true" ? true : false;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const constructApiEndpoint = (searchTerm) => {
    return `${baseURL}/hotWater/get?search=${searchTerm}`;
  };

  const handleProductChange = (value) => {
    const endpoint = constructApiEndpoint(value);
    axios
      .get(endpoint)
      .then((response) => {
        setSWUnitData(response.data);
      })
      .catch((error) => {});
  };

  const handleProductSelection = (product) => {
    setShowSWUnitDropdown(false);
    const rawValue =
      (product[zone] * formdata?.installationDate?.STCDeemingPeriod) / 10;
    const value = Math.floor(rawValue);
    setFormdata((prevFormData) => ({
      ...prevFormData,
      hotWaterDetail: {
        ...prevFormData.hotWaterDetail,
        hotWaterBrand: product.Brand || "",
        stc: value || null,
        hotWaterInfo: {
          ...prevFormData.hotWaterDetail.hotWaterInfo,
          hotWaterModel: product.Model || "",
        },
      },
    }));
    if (inputUnitField.current) {
      inputUnitField.current.value = "";
    }
  };

  const handlePanelModelChange = (e) => {
    const selectedModel = e.target.value;
    setFormdata((prevFormData) => ({
      ...prevFormData,
      hotWaterDetail: {
        ...prevFormData.hotWaterDetail,
        hotWaterInfo: {
          ...prevFormData.hotWaterDetail.hotWaterInfo,
          hotWaterModel: selectedModel,
        },
      },
    }));
  };

  const [hotWaterBrandList, setHotWaterBrandList] = useState([]);

  useEffect(() => {
    const hotWaterBrand = formdata?.hotWaterDetail?.hotWaterBrand;

    if (hotWaterBrand) {
      const hotWaterResponse = axios
        .get(`${baseURL}/hotWater/get?search=${hotWaterBrand}`)
        .then((hotWaterResponse) => {
          if (hotWaterResponse.status === 200) {
            const data = hotWaterResponse?.data;
            setHotWaterBrandList(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formdata?.hotWaterDetail?.hotWaterBrand]);

  const handleHotWaterCapacityInputChange = (e) => {
    const { name, value } = e.target;

    setFormdata((prevFormdata) => ({
      ...prevFormdata,
      hotWaterDetail: {
        ...prevFormdata.hotWaterDetail,
        hotWaterInfo: {
          ...prevFormdata.hotWaterDetail.hotWaterInfo,
          [name]: value,
        },
      },
    }));
  };

  //Get Zone as per Installation Address Starts
  useEffect(() => {
    const postCode = formdata?.installationAddress?.postCode;
    if (postCode) {
      const foundZone = ZoneData.find(
        (data) =>
          postCode >= data["Range Start"] && postCode <= data["Range End"]
      );
      if (foundZone) {
        setZone(`Zone${foundZone.Zone}`);
      } else {
        setZone("");
      }
    }
  }, [formdata.installationAddress.postCode]);
  //Get Zone as per Installation Address Ends
  //STC Calculation Starts
  // STC Calculation
  useEffect(() => {
    const model = formdata?.hotWaterDetail?.hotWaterInfo?.hotWaterModel;
    const brand = formdata?.hotWaterDetail?.hotWaterBrand; // Access the brand
    const STCDeemingPeriod = formdata?.installationDate?.STCDeemingPeriod;

    // Check if both model and brand are available before proceeding
    if (model && brand && STCDeemingPeriod && zone) {
      const swUnit = hotWaterBrandList.find(
        (unit) => unit.Model === model && unit.Brand === brand
      );
      if (swUnit) {
        const rawValue = (swUnit[zone] * STCDeemingPeriod) / 10;
        const value = Math.floor(rawValue);
        if (value) {
          setFormdata((prevData) => ({
            ...prevData,
            hotWaterDetail: {
              ...prevData.hotWaterDetail,
              stc: value,
              financialPayment: (value * 39.4).toFixed(3),
            },
          }));
        }
      }
    }
  }, [
    zone,
    formdata.hotWaterDetail.hotWaterInfo.hotWaterModel,
    formdata.hotWaterDetail.hotWaterBrand,
    formdata.installationDate.STCDeemingPeriod,
  ]); // Updated dependencies

  //STC Calculation Ends

  // Installation Team DropDowns ends

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formdata.ownerDetails.isGST &&
      formdata.ownerDetails.ABN.toString().trim().length !== 11
    ) {
      alert("ABN number must be 11 digits when GST is enabled.");
      return;
    }

    try {
      const formData = new FormData();
      Object.keys(formdata).forEach((key) => {
        if (
          key !== "design" &&
          key !== "customerInvoice" &&
          key !== "uploadElectricityBill" &&
          key !== "customerInvoice" &&
          key !== "claimStcPayment" &&
          key !== "plumbingCertificate" &&
          key !== "otherDocuments"
        ) {
          if (typeof formdata[key] === "string") {
            formData.append(key, formdata[key] === "" ? "" : formdata[key]);
          } else {
            formData.append(key, JSON.stringify(formdata[key]));
          }
        }
      });

      formData.append("design", formdata.design || "");
      formData.append("customerInvoice", formdata.customerInvoice || "");
      formData.append(
        "uploadElectricityBill",
        formdata.uploadElectricityBill || ""
      );
      formData.append("STCForm", formdata.STCForm || "");
      formData.append("STCInvoice", formdata.STCInvoice || "");
      formData.append("testingCertificate", formdata.testingCertificate || "");
      formData.append("noticeOfCompletion", formdata.noticeOfCompletion || "");

      const response = await axios.patch(
        `${baseURL}/job/update/${jobId}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response:", response);

      if (response.status === 200 || response.status === 201) {
        alert("Job Updated Successfully");
        localStorage.removeItem("jobId");

        setTimeout(() => {
          window.close();
        }, 800);
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
      }
    } catch (error) {
      console.log("Error Updating Job");
      alert(error?.response?.data?.message);
    }
  };
  //input handling ends

  const [isViewTeamOpen, setViewTeamOpen] = useState(false);
  // View Installer Start from here
  const viewTeam = (installerId) => {
    localStorage.setItem("installerId", installerId);
    setViewTeamOpen(true);
  };
  // View Installer End here

  const [panelSeriesToolTip, setPanelSeriesToolTip] =
    useState("Copy to Clipboard");

  const fetchOnSiteData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/onSite`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp?.data?.data;
      const flattenedData = data.flat();
      const result = flattenedData.filter((item) => item.jobId === jobId);
      const tanks = result[0]?.tanks[0];
      setTankInfo(tanks);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOnSiteData();
  }, []);
  const handleCopyToClipboard = (serialNumbers) => {
    navigator.clipboard.writeText(serialNumbers);
  };

  const handleCopyTankToClipboard = (event) => {
    event.preventDefault();
    const serialNumbers = tankInfo?.serialNumber;
    handleCopyToClipboard(serialNumbers);
    setPanelSeriesToolTip("Copied to Clipboard");
  };

  //Serial Number PopUp Starts

  const [currentSerialNumber, setCurrentSerialNumber] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  const [popupOpenPanel, setPopupOpenPanel] = useState(false);
  const [newSerialNumber, setNewSerialNumber] = useState("");
  const [newTankPhoto, setNewTankPhoto] = useState(null);

  const openPopupPanel = (tankInfo) => {
    if (
      tankInfo?.serialNumber === undefined &&
      tankInfo?.imageUrl === undefined
    ) {
      setCurrentSerialNumber(null);
      setCurrentImageUrl("");
      setNewSerialNumber("");
      setNewTankPhoto(null);
    } else {
      setCurrentSerialNumber(tankInfo?.serialNumber);
      setCurrentImageUrl(tankInfo?.imageUrl);
    }
    setPopupOpenPanel(true);
  };

  const closePopupPanel = () => {
    setPopupOpenPanel(false);
  };

  //Serial Number PopUp Ends

  const handleInputClick = (tankInfo) => {
    openPopupPanel(tankInfo);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowSWUnitDropdown(false);
    }, 200);
  };

  const handleAddSerial = async () => {
    const formData = new FormData();
    formData.append("jobId", jobId);
    formData.append("serialNumber", newSerialNumber);
    formData.append("tankPhoto", newTankPhoto);
    try {
      const response = await axios.post(
        `${baseURL}/onsiteTank/update`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Serial Number added successfully");
      fetchOnSiteData();
      closePopupPanel();
    } catch (error) {
      console.error("Error adding serial:", error);
    }
  };

  //Job Finish Functionality Starts

  const handleButtonClick = async (status) => {
    try {
      const jobId = localStorage.getItem("jobId");
      const requestData = {
        newStatus: status,
      };
      const response = await axios.patch(
        `${baseURL}/users/jobschangeStatus/${jobId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Job Status Updated Successfully");
        localStorage.removeItem("jobId");
        window.close();
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  const shouldShowFinishButton = () => {
    const { tankScanStatus, photosStatus, installerDeclarationStatus } = status;

    return (
      tankScanStatus === 1 &&
      photosStatus === 1 &&
      installerDeclarationStatus === 1
    );
  };

  //Job Finish Functionality Ends

  return (
    <div className="MainOuterContainer">
      <div>
        <Aside />
      </div>
      <div>
        <div className="CreatJobDetailsForm">
          {!loadingJobData ? (
            <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
              <div className="CreateJobContainer">
                <div className="row">
                  <HeaderComponent
                    PageTitle={"Edit Hot Water Job"}
                    subTitle={"Dashboard / Jobs / Edit Job"}
                  />
                </div>
              </div>
              <div className="InformationFormDiv">
                <div>
                  <span className="panel-info">Panel Information</span>
                </div>

                <div className="row">
                  <div className="owner-type">
                    <h4 className="textFont">System Owner Type</h4>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="Owner-type-selection">
                        <img
                          src={`${image}/Assets/CreateJob/housing-area.png`}
                          alt=""
                          onClick={() => {
                            document.getElementById("residential").click();
                          }}
                          height={"80px"}
                          style={{
                            textAlign: "end",
                            position: "relative",
                            bottom: "-3px",
                          }}
                        />

                        <div style={{ textAlign: "center", width: "12%" }}>
                          <img
                            src={`${image}/Assets/CreateJob/residential.png`}
                            alt=""
                            onClick={() => {
                              document.getElementById("commercial").click();
                            }}
                            height={"60px"}
                          />
                        </div>
                      </div>
                      <div className="Owner-type-selection">
                        <ul className="radio-ul">
                          <li>
                            {" "}
                            <input
                              id="residential"
                              type="radio"
                              className=""
                              name="systemOwnerType"
                              value="Residential"
                              onChange={handleInputsSystemOwnerType}
                              checked={
                                formdata?.systemOwnerType === "Residential"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="residential"
                            >
                              Residential
                            </label>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {" "}
                            <input
                              id="commercial"
                              type="radio"
                              className=""
                              name="systemOwnerType"
                              value="Commercial"
                              onChange={handleInputsSystemOwnerType}
                              checked={
                                formdata?.systemOwnerType === "Commercial"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="commercial"
                            >
                              Commercial
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-information">
                  <div className="panel-main-bg postal-address">
                    <p className="sectionTitleText">Installing Type</p>
                  </div>
                  <div className="installing-boxes">
                    <select
                      className="installing-type"
                      name="installingFor"
                      value={formdata?.installingFor || ""}
                      onChange={handleInputsinstallingType}
                    >
                      <option value="" disabled>
                        SELECT
                      </option>
                      {installingForOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="panel-information">
                  <div className="panel-main-bg postal-address">
                    <p className="sectionTitleText">System Owner Details</p>
                  </div>
                  <div className="main-detail">
                    <form method="post">
                      {formdata?.installingFor === "Individual" && (
                        <React.Fragment>
                          <input
                            className="detail-input"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            maxLength={30}
                            value={formdata?.ownerDetails?.firstName || ""}
                            onChange={handleInputCustomerDetails}
                            autoComplete="off"
                            style={{ width: "280px" }}
                          />
                          <input
                            className="detail-input"
                            type="text"
                            name="lastName"
                            maxLength={30}
                            placeholder="Last Name"
                            value={formdata?.ownerDetails?.lastName || ""}
                            onChange={handleInputCustomerDetails}
                            autoComplete="off"
                            style={{ width: "280px" }}
                          />
                        </React.Fragment>
                      )}
                      {formdata?.installingFor !== "Individual" && (
                        <div style={{ display: "flex" }}>
                          <input
                            style={{ display: "flex", width: "434px" }}
                            className="detail-input"
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={formdata?.ownerDetails?.companyName || ""}
                            maxLength={60}
                            onChange={handleInputCustomerDetails}
                            autoComplete="off"
                          />
                        </div>
                      )}

                      <div style={{ display: "flex" }}>
                        <input
                          className="detail-input"
                          type="email"
                          name="emailId"
                          placeholder="Email ID"
                          value={formdata?.ownerDetails?.emailId || ""}
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                          onChange={handleInputCustomerDetails}
                          autoComplete="off"
                          style={{ width: "360px", overflowX: "scroll" }}
                        />
                        <input
                          className="detail-input"
                          type="text"
                          name="mobileNum"
                          placeholder="Mobile Number"
                          value={formdata?.ownerDetails?.mobileNum || ""}
                          onChange={handleInputCustomerDetails}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 14) {
                              window.alert(
                                "Mobile number must be of 14 digits."
                              );
                              e.target.value = " ";
                            }
                          }}
                          autoComplete="off"
                        />
                        <input
                          className="detail-input phone-space"
                          type="text"
                          name="phoneNumber"
                          placeholder="Phone No"
                          value={formdata?.ownerDetails?.phoneNumber || ""}
                          onChange={handleInputCustomerDetails}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 14) {
                              window.alert(
                                "Phone number must be of 14 digits."
                              );
                              e.target.value = " ";
                            }
                          }}
                          autoComplete="off"
                        />
                      </div>
                      <div style={{ display: "flex", gap: "15px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "baseline",
                            flexDirection: "column",
                            margin: "0 0 0 20px",
                          }}
                        >
                          <label className="gst-system">
                            Is Customer Registered for GST?
                          </label>

                          <ul className="system-main">
                            <li className="main-text">
                              {" "}
                              <input
                                id="gstYes"
                                type="radio"
                                name="isGST"
                                className="input-text"
                                value="true"
                                onChange={handleInputCustomerDetailsRadio}
                                checked={formdata?.ownerDetails?.isGST === true}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="gstYes"
                              >
                                Yes
                              </label>
                            </li>
                            <li>
                              {" "}
                              <input
                                id="gstNo"
                                type="radio"
                                name="isGST"
                                className="input-text"
                                value="false"
                                onChange={handleInputCustomerDetailsRadio}
                                checked={
                                  formdata?.ownerDetails?.isGST === false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="gstNo"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                        </div>
                        <input
                          className="detail-input"
                          type="number"
                          name="ABN"
                          placeholder="ABN"
                          value={formdata?.ownerDetails?.ABN || ""}
                          onChange={handleInputCustomerDetails}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 11) {
                              window.alert("ABN number must be of 11 digits.");
                              e.target.value = " ";
                            }
                          }}
                          autoComplete="off"
                          disabled={!formdata?.ownerDetails?.isGST}
                          required={formdata.ownerDetails.isGST === true}
                        />
                        <input
                          className="detail-input gst-space"
                          type="text"
                          name="gstNo"
                          placeholder="GST No."
                          value={formdata?.ownerDetails?.gstNo || ""}
                          onChange={handleInputCustomerDetails}
                          disabled={formdata?.ownerDetails?.isGST !== true}
                          autoComplete="off"
                        />
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "17px",
                    }}
                  >
                    {/* <ul className="system-main">
                      <li className="checkbox-same"> */}
                    <input
                      className="input-same"
                      type="checkbox"
                      id="sameDetails"
                      onChange={handleCheckboxChange}
                      disabled={formdata.installingFor !== "Individual"}
                      checked={isChecked}
                    />
                    <label style={{
                        cursor: "pointer",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                      htmlFor="sameDetails">
                      <b>Same as Above</b>
                    </label>
                    {/* </li>
                    </ul> */}
                  </div>
                </div>

                <div className="panel-information">
                  <div className="panel-main-bg postal-address">
                    <p className="sectionTitleText">Co-Owner Details</p>
                  </div>
                  <div className="main-detail">
                    <form method="post">
                      <input
                        className="detail-input"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        maxLength={30}
                        value={formdata?.coOwnerDetail?.firstName || ""}
                        onChange={handleInputCoOwnerDetails}
                        disabled={isChecked}
                        style={{ width: "280px" }}
                      />
                      <input
                        type="text"
                        name="lastName"
                        className="detail-input"
                        placeholder="Last Name"
                        maxLength={30}
                        value={formdata?.coOwnerDetail?.lastName || ""}
                        onChange={handleInputCoOwnerDetails}
                        disabled={isChecked}
                        style={{ width: "280px" }}
                      />
                      <input
                        className="detail-input"
                        type="email"
                        name="emailId"
                        placeholder="Email ID"
                        value={formdata?.coOwnerDetail?.emailId || ""}
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                        onChange={handleInputCoOwnerDetails}
                        disabled={isChecked}
                        style={{ width: "350px", overflowX: "scroll" }}
                      />

                      <input
                        className="detail-input"
                        type="text"
                        name="mobileNum"
                        placeholder="Mobile Number"
                        value={formdata?.coOwnerDetail?.mobileNum || ""}
                        onChange={handleInputCoOwnerDetails}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 14) {
                            window.alert("Mobile number must be of 14 digits.");
                            e.target.value = " ";
                          }
                        }}
                        disabled={isChecked}
                      />

                      <input
                        className="detail-input phone-space"
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone No"
                        value={formdata?.coOwnerDetail?.phoneNumber || ""}
                        onChange={handleInputCoOwnerDetails}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 14) {
                            window.alert("Phone number must be of 14 digits.");
                            e.target.value = " ";
                          }
                        }}
                        disabled={isChecked}
                      />
                    </form>
                  </div>
                </div>

                <div className="panel-information">
                  <div className="address-bg">
                    <p className="address-text sectionTitleText">
                      Installation Address
                    </p>
                  </div>
                  <div>
                    <div className="search-auto">
                      <div className="Signup-autoAdress-border">
                        <PlacesAutocomplete
                          value={address}
                          onChange={SetAddress}
                          onSelect={handleSelect}
                          searchOptions={{
                            componentRestrictions: { country: "au" },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div
                              className="address-input"
                              style={{ marginLeft: " 10px" }}
                            >
                              <input
                                className="Signup-autoAdress-input"
                                id="address"
                                {...getInputProps({
                                  type: "text",
                                  placeholder: "Search Address",
                                })}
                              />
                              <div className="address-auto-complete">
                                {loading ? <div>Loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                    color: suggestion.active ? "#fff" : "#000",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                      key={suggestion.placeId}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <div className="address-auto-complete"></div>
                      </div>
                    </div>

                    <div className="address-info">
                      <div className="Signup-Adress-container">
                        <div className="Signup-autoAdress-container"></div>
                        <div>
                          <div className="main-address-block">
                            <div className="row">
                              <div className="address-main-boxes address-box">
                                <select
                                  className="address-type"
                                  name="addressType"
                                  value={
                                    formdata?.installationAddress
                                      ?.addressType || ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                >
                                  <option value="" disabled>
                                    Address Type
                                  </option>
                                  {addressTypeOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="address-main-boxes">
                                <select
                                  className="address-type"
                                  name="unitType"
                                  value={
                                    formdata?.installationAddress?.unitType ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                >
                                  <option value="" disabled>
                                    Unit Type
                                  </option>
                                  {unitOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="unit-main-boxes">
                                <input
                                  className="unit-no"
                                  placeholder="Unit Number"
                                  name="unitNumber"
                                  value={
                                    formdata?.installationAddress?.unitNumber ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  onKeyDown={(e) => {
                                    if (e.target.value.length > 8) {
                                      window.alert(
                                        "Unit number must be less than or equal to 8 digits."
                                      );
                                      e.target.value = " ";
                                    }
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="address-main-boxes address-box">
                                <input
                                  className="address-type"
                                  placeholder="Street Number"
                                  maxLength="6"
                                  name="streetNumber"
                                  value={
                                    formdata?.installationAddress
                                      ?.streetNumber || ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  onKeyDown={(e) => {
                                    if (e.target.value.length > 6) {
                                      window.alert(
                                        "Street number must be less than or equal to 6 digits."
                                      );
                                      e.target.value = " ";
                                    }
                                  }}
                                />

                                <span className=""></span>
                              </div>
                              <div className="address-main-boxes">
                                <input
                                  className="address-type"
                                  type="text"
                                  maxLength={50}
                                  name="streetName"
                                  placeholder="Street Name"
                                  value={
                                    formdata?.installationAddress?.streetName ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                />
                              </div>
                              <div className="unit-main-boxes">
                                <select
                                  className="street-type"
                                  name="streetType"
                                  value={
                                    formdata?.installationAddress?.streetType ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                >
                                  <option value="" disabled>
                                    Street Type
                                  </option>
                                  {streetOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="row">
                              <div className="address-main-boxes address-box">
                                <input
                                  className="address-type"
                                  type="text"
                                  maxLength={10}
                                  name="suburb"
                                  placeholder="Suburb"
                                  value={
                                    formdata?.installationAddress?.suburb || ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                />
                              </div>
                              <div className="state-main-boxes">
                                <select
                                  className="state-code"
                                  value={
                                    formdata?.installationAddress?.state || ""
                                  }
                                  name="state"
                                  onChange={handleInputsInstallerAddress}
                                >
                                  <option value="" disabled>
                                    State
                                  </option>
                                  {stateOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="address-postcode">
                                <input
                                  className="address-type"
                                  type="number"
                                  placeholder="Post Code"
                                  name="postCode"
                                  value={
                                    formdata?.installationAddress?.postCode ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  onKeyDown={(e) => {
                                    if (e.target.value.length > 4) {
                                      window.alert(
                                        "Post Code must be equal to 4 digits."
                                      );
                                      e.target.value = " ";
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="map-section">
                            {address ? (
                              <div style={{ position: "relative", right: "0" }}>
                                <GoogleMap
                                  mapContainerStyle={{
                                    width: "421px",
                                    height: "196px",
                                  }}
                                  center={selectedLocation}
                                  zoom={15}
                                  onLoad={onMapLoad}
                                  options={{
                                    mapTypeControl: true,
                                  }}
                                >
                                  {selectedLocation && (
                                    <MarkerF
                                      position={{
                                        lat: selectedLocation.lat,
                                        lng: selectedLocation.lng,
                                      }}
                                    />
                                  )}
                                </GoogleMap>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <span className="border-bottom"></span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="row">
                    <div className="postal-address">
                      <div className="postal-bg">
                        <p className="sectionTitleText">
                          Different Postal Address
                        </p>
                        <div className="row">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <ul className="system-main">
                              <li className="main-checkbox">
                                {" "}
                                <input
                                  className="input-same"
                                  type="checkbox"
                                  id="sameAddress"
                                  onChange={handleAddressCheckboxChange}
                                  checked={sameAsAboveAddress}
                                  disabled={
                                    formdata?.installationAddress
                                      ?.addressType === "Postal"
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="sameAddress"
                                >
                                  <b>Same as Above</b>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <ul className="system-main">
                          {formdata?.installationAddress?.addressType ===
                          "Physical" ? (
                            <li className="main-text">
                              {" "}
                              <input
                                id="poaddress"
                                type="radio"
                                name="radio"
                                className="input-text"
                                onClick={poBoxAdressclicked}
                                checked={
                                  (formdata?.postalAddress?.POAddress &&
                                    Object.keys(
                                      formdata?.postalAddress?.POAddress
                                    ).every(
                                      (key) =>
                                        formdata?.postalAddress?.POAddress[
                                          key
                                        ] === formdata?.installationAddress[key]
                                    ) &&
                                    sameAsAboveAddress) ||
                                  poBoxAdres
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="poaddress"
                              >
                                Address
                              </label>
                            </li>
                          ) : (
                            <li className="main-text">
                              {" "}
                              <input
                                id="pobox"
                                type="radio"
                                name="radio"
                                className="input-text"
                                onClick={poBoxclicked}
                                checked={poBox}
                                disabled={sameAsAboveAddress}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  fontSize: "0.8rem",
                                }}
                                htmlFor="pobox"
                              >
                                Po Box No
                              </label>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {formdata?.installationAddress?.addressType === "Postal" ? (
                    <div className="row">
                      <div className="address-main-boxes address-box suburb-space">
                        <input
                          className="address-type"
                          type="number"
                          placeholder="PO Box No."
                          name="POBoxNo"
                          value={formdata?.postalAddress?.POBox?.POBoxNo || ""}
                          onChange={handleInputsPostalAddressPOBoxPOBoxNo}
                        />
                      </div>

                      <div className="address-main-boxes address-box suburb-space">
                        <input
                          className="address-type"
                          type="area"
                          placeholder="Area"
                          name="postalArea"
                          value={
                            formdata?.postalAddress?.POBox?.postalArea || ""
                          }
                          onChange={handleInputsPostalAddressPOBoxPOBoxNo}
                        />
                      </div>
                      <div className="address-main-boxes address-box suburb-space">
                        <input
                          className="address-type"
                          type="number"
                          name="postalCode"
                          placeholder="Post Code"
                          value={
                            formdata?.postalAddress?.POBox?.postalCode || ""
                          }
                          onChange={handleInputsPostalAddressPOBoxPOBoxNo}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 4) {
                              window.alert(
                                "Post Code must be equal to 4 digits."
                              );
                              e.target.value = " ";
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : null}

                  {formdata?.installationAddress?.addressType === "Physical" ? (
                    <>
                      <div className="row">
                        <div className="main-address-section">
                          <div className="address-main-boxes address-box">
                            <select
                              className="address-type"
                              name="addressType"
                              value={
                                formdata?.postalAddress?.POAddress
                                  ?.addressType || ""
                              }
                              onChange={
                                handleInputsInstallerPostalAddressDropDown
                              }
                              disabled={sameAsAboveAddress}
                            >
                              <option value="" disabled>
                                Address Type
                              </option>
                              {addressTypeOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="address-main-boxes">
                            <select
                              className="address-type"
                              name="unitType"
                              value={
                                formdata?.postalAddress?.POAddress?.unitType ||
                                ""
                              }
                              onChange={
                                handleInputsInstallerPostalAddressDropDown
                              }
                              disabled={sameAsAboveAddress}
                            >
                              <option value="" disabled>
                                Unit Type
                              </option>
                              {unitOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="unit-main-boxes">
                            <input
                              className="unit-no"
                              placeholder="Unit Number"
                              name="unitNumber"
                              value={
                                formdata?.postalAddress?.POAddress
                                  ?.unitNumber || ""
                              }
                              onChange={handleInputsPostalAddressPOBoxPOAddress}
                              onKeyDown={(e) => {
                                if (e.target.value.length > 8) {
                                  window.alert(
                                    "Unit number must be less than or equal to 8 digits."
                                  );
                                  e.target.value = " ";
                                }
                              }}
                              disabled={sameAsAboveAddress}
                            />
                          </div>
                          <div className="address-main-boxes address-box street-space">
                            <input
                              className="address-type"
                              placeholder="Street Number"
                              name="streetNumber"
                              value={
                                formdata?.postalAddress?.POAddress
                                  ?.streetNumber || ""
                              }
                              onChange={handleInputsPostalAddressPOBoxPOAddress}
                              onKeyDown={(e) => {
                                if (e.target.value.length > 6) {
                                  window.alert(
                                    "Street number must be less than or equal to 6 digits."
                                  );
                                  e.target.value = " ";
                                }
                              }}
                              disabled={sameAsAboveAddress}
                            />

                            <span className=""></span>
                          </div>
                          <div className="address-main-boxes">
                            <input
                              className="address-type"
                              type="text"
                              placeholder="Street Name"
                              maxLength={50}
                              name="streetName"
                              value={
                                formdata?.postalAddress?.POAddress
                                  ?.streetName || ""
                              }
                              onChange={handleInputsPostalAddressPOBoxPOAddress}
                              disabled={sameAsAboveAddress}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="unit-main-boxes">
                          <select
                            className="street-type"
                            name="streetType"
                            value={
                              formdata?.postalAddress?.POAddress?.streetType ||
                              ""
                            }
                            onChange={
                              handleInputsInstallerPostalAddressDropDown
                            }
                            disabled={sameAsAboveAddress}
                          >
                            <option value="" disabled>
                              Street Type<span className=""></span>
                            </option>
                            {streetOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="address-main-boxes address-box suburb-space">
                          <input
                            className="address-type"
                            type="text"
                            name="suburb"
                            placeholder="Suburb"
                            maxLength={10}
                            value={
                              formdata?.postalAddress?.POAddress?.suburb || ""
                            }
                            onChange={handleInputsPostalAddressPOBoxPOAddress}
                            disabled={sameAsAboveAddress}
                          />
                        </div>
                        <div className="state-main-boxes">
                          <select
                            className="state-code"
                            name="state"
                            value={
                              formdata?.postalAddress?.POAddress?.state || ""
                            }
                            onChange={
                              handleInputsInstallerPostalAddressDropDown
                            }
                            disabled={sameAsAboveAddress}
                          >
                            <option value="" disabled>
                              State
                            </option>
                            {stateOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="address-postcode">
                          <input
                            className="address-type"
                            type="number"
                            placeholder="Post Code"
                            name="postCode"
                            value={
                              formdata?.postalAddress?.POAddress?.postCode || ""
                            }
                            onChange={handleInputsPostalAddressPOBoxPOAddress}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 4) {
                                window.alert(
                                  "Post Code must be equal to 4 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                            disabled={sameAsAboveAddress}
                          />
                        </div>
                        <button
                          className="Signup-switchingAdress-btn"
                          onClick={swithingPostalAddress}
                          type="button"
                        >
                          Switch to Autocomplete
                        </button>
                      </div>
                    </>
                  ) : null}
                  {poBoxAdres && !manualButtonPostal ? (
                    <div className="Signup-autoAdress-container">
                      <div className="Signup-autoAdress-border">
                        <PlacesAutocomplete
                          value={postalAddress}
                          onChange={SetPostalAddress}
                          onSelect={handlePostalSelect}
                          searchOptions={{
                            // key: process.env.REACT_APP_GOOGLE_API_KEY,
                            componentRestrictions: { country: "au" },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="address-input">
                              <input
                                className="Signup-autoAdress-input"
                                id="address"
                                {...getInputProps({
                                  type: "text",
                                  placeholder: "Address",
                                })}
                              />
                              <div className="address-auto-complete">
                                {loading ? <div>Loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                    color: suggestion.active ? "#fff" : "#000",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <div className="address-auto-complete"></div>
                      </div>
                      <div className="Signup-menualAdress-container">
                        <button
                          className="Signup-switchingAdress-btn"
                          onClick={swithingPostalAddress}
                        >
                          Enter Manually
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="install-bg">
                  <div className="postal-address">
                    <p className="sectionTitleText ">Installation Type</p>
                  </div>
                </div>
                <div className="system-boxes" style={{ width: "435px" }}>
                  <select
                    className="system-type"
                    name="installationType"
                    value={formdata?.installationType || ""}
                    onChange={handleInputInstallationType}
                  >
                    <option value="" disabled>
                      SELECT
                    </option>
                    {installationTypeOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="install-bg">
                  <div className="postal-address">
                    <p className="sectionTitleText">Installation Details</p>
                  </div>
                  <div className="main-detail">
                    <input
                      className="detail-input"
                      type="date"
                      name="installingDate"
                      placeholder="Installation Date"
                      value={formdata?.installationDate?.installingDate || ""}
                      onChange={handleInputsInstallationDate}
                      // min={new Date().toISOString().split("T")[0]}
                    />
                    <div className="relation-boxes">
                      <input
                        className="relation-type"
                        type="text"
                        name="STCDeemingPeriod"
                        placeholder="STC Deeming Period"
                        value={
                          formatDateForDeemingPeriod(
                            formdata?.installationDate?.STCDeemingPeriod
                          ) || ""
                        }
                        onChange={handleInputsInstallationDate}
                        readOnly
                      />
                    </div>
                    <div className="mainmodel-boxes">
                      <select
                        className="installing-type"
                        name="storey"
                        value={formdata?.siteAttributes?.storey || ""}
                        onChange={handleInputSiteDetails}
                      >
                        <option value="" disabled>
                          Storey
                        </option>
                        {storeyTypeOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="install-bg">
                  <div className="postal-address">
                    <p className="sectionTitleText">System detail:</p>
                  </div>
                  <div className="panel-information">
                    <div className="panel-main-bg postal-address">
                      <p className="sectionTitleText">SW Unit</p>
                    </div>
                  </div>
                  <div className="panel-information">
                    <input
                      ref={inputUnitField}
                      className="search-panel"
                      style={{ width: "785px" }}
                      type="text"
                      placeholder="Search SW Unit"
                      onChange={(e) => handleProductChange(e.target.value)}
                      onFocus={() => setShowSWUnitDropdown(true)}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {showSWUnitDropdown && (
                      <table
                        className="createjob_panelbrand_ul-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "785px",
                          marginLeft: "21px",
                          maxHeight: "200px",
                          overflowY: "auto",
                          zIndex: 1000,
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <thead>
                          <tr
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <th style={{ width: "35%" }}>Brand</th>
                            <th style={{ width: "25%" }}>Model</th>
                            {/* <th style={{ width: "20%" }}>Accept Date</th>
                          <th style={{ width: "20%" }}>Expiry Date</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {SWUnitData.length > 0 ? (
                            SWUnitData.map((swUnit, index) => (
                              <tr
                                style={{ display: "flex", gap: "140px" }}
                                key={index}
                                onMouseDown={() =>
                                  handleProductSelection(swUnit)
                                }
                              >
                                <td style={{ width: "35%" }}>{swUnit.Brand}</td>
                                <td style={{ width: "25%" }}>{swUnit.Model}</td>
                                {/* <td style={{ width: "20%" }}></td>
                              <td style={{ width: "20%" }}></td> */}
                              </tr>
                            ))
                          ) : (
                            <tr
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <td colSpan="4">
                                No Solar Water Heater Unit Data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="panelAndAddButton">
                    <div>
                      <input
                        className="brand-input"
                        type="text"
                        placeholder="Brand"
                        value={formdata?.hotWaterDetail?.hotWaterBrand || ""}
                        autoComplete="off"
                      />
                      <div className="Model-boxes">
                        <select
                          className="installing-type"
                          name="hotWaterModel"
                          id="hotWaterModel"
                          value={
                            formdata?.hotWaterDetail?.hotWaterInfo
                              ?.hotWaterModel || ""
                          }
                          onChange={(e) => handlePanelModelChange(e)}
                        >
                          <option value="" disabled>
                            Model
                          </option>
                          {hotWaterBrandList.map((hotWaterModel, index) => (
                            <option key={index} value={hotWaterModel.Model}>
                              {hotWaterModel.Model}
                            </option>
                          ))}
                        </select>
                      </div>

                      <input
                        className="quantity-input"
                        placeholder="Capacity"
                        id="panelbrand-input"
                        type="number"
                        name="hotWaterCapacity"
                        value={
                          formdata?.hotWaterDetail?.hotWaterInfo
                            ?.hotWaterCapacity || ""
                        }
                        autoComplete="off"
                        onChange={handleHotWaterCapacityInputChange}
                      />
                      <div style={{ display: "flex" }}>
                        <span className="job-panel" htmlFor="" required>
                          Heat Pump System?
                        </span>

                        <ul className="main-text">
                          <li className="main-text">
                            {" "}
                            <input
                              className="input-text"
                              type="radio"
                              name="isHeatPump"
                              id="isHeatPumpYes"
                              value="true"
                              onChange={handleInputsPanelInfo}
                              checked={
                                formdata?.hotWaterDetail?.isHeatPump === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="isHeatPumpYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li className="main-text">
                            {" "}
                            <input
                              className="input-text"
                              type="radio"
                              name="isHeatPump"
                              id="isHeatPumpNo"
                              value="false"
                              onChange={handleInputsPanelInfo}
                              checked={
                                formdata?.hotWaterDetail?.isHeatPump === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="isHeatPumpNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="ColumnSection">
                        <div
                          className="innerSectionDetails"
                          style={{ paddingLeft: "12px" }}
                        >
                          <input
                            className="quantity-text"
                            type="number"
                            placeholder="Number of Panels"
                            style={{ width: "150px", marginLeft: "8px" }}
                            name="noOfPanels"
                            value={formdata?.hotWaterDetail?.noOfPanels || ""}
                            onChange={handleInputsPanelInfo}
                            disabled={
                              formdata?.hotWaterDetail?.isHeatPump === true
                            }
                          />
                          <input
                            className="numberput-input"
                            type="number"
                            placeholder="Number of STCs"
                            name="stc"
                            value={formdata?.hotWaterDetail?.stc || ""}
                            readOnly
                          />

                          <input
                            className="finanacial-input"
                            type="number"
                            placeholder="Financial Payment"
                            name="financialPayment"
                            value={
                              formdata?.hotWaterDetail?.financialPayment || ""
                            }
                            readOnly
                          />
                        </div>

                        <div className="SectionWithClipBoard">
                          <a
                            href="#"
                            target="_self"
                            onClick={handleCopyTankToClipboard}
                            style={{ display: "none" }}
                          >
                            <Tooltip
                              className="clipboard-copy"
                              toolTip={panelSeriesToolTip}
                            >
                              <ContentPaste sx={{ fontSize: "20px" }} />
                            </Tooltip>
                          </a>
                          <div>
                            {tankInfo?.serialNumber ? (
                              <input
                                className="serialno-addinput"
                                style={{ marginLeft: "25px" }}
                                type="text"
                                placeholder="Serial Number"
                                value={tankInfo?.serialNumber}
                                readOnly
                                onClick={() => handleInputClick(tankInfo)}
                              />
                            ) : (
                              <input
                                className="serialno-addinput"
                                type="text"
                                style={{ marginLeft: "20px" }}
                                placeholder="Serial Number"
                                value={
                                  formdata?.hotWaterDetail?.hotWaterInfo
                                    ?.serialNumber
                                }
                                readOnly
                                onClick={() =>
                                  handleInputClick(
                                    formdata?.hotWaterDetail?.hotWaterInfo
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {popupOpenPanel && (
                        <div
                          style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            backdropFilter: "blur(4px)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: "1999",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              padding: "20px",
                              borderRadius: "8px",
                              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                              zIndex: "999",
                              width: tankInfo?.serialNumber ? "50%" : "30%",
                              height: tankInfo?.serialNumber ? "90%" : "35%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className="PopupBoxStyle"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "90%",
                                }}
                              >
                                <button
                                  className="add-model-close-btn"
                                  style={{
                                    position: "relative",
                                    left: tankInfo?.serialNumber
                                      ? "655px"
                                      : "370px",
                                  }}
                                  type="button"
                                  onClick={closePopupPanel}
                                >
                                  ✕
                                </button>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingTop: "28px",
                                  }}
                                >
                                  {tankInfo?.serialNumber ? (
                                    <p>
                                      Serial Number: {tankInfo.serialNumber}
                                    </p>
                                  ) : (
                                    <>
                                      <input
                                        className="serialno-addinput"
                                        type="text"
                                        placeholder="Enter Serial Number"
                                        value={newSerialNumber}
                                        onChange={(e) =>
                                          setNewSerialNumber(e.target.value)
                                        }
                                      />
                                      <input
                                        className="serialno-addinput"
                                        type="file"
                                        onChange={(e) =>
                                          setNewTankPhoto(e.target.files[0])
                                        }
                                        style={{ width: "355px" }}
                                      />
                                      <br />
                                      <div className="add-model-actions">
                                        <button
                                          type="button"
                                          className="submit-hover"
                                          onClick={() => {
                                            handleAddSerial();
                                          }}
                                          style={{ marginLeft: 0 }}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <br />
                            {tankInfo?.tankPhoto ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "88%",
                                }}
                              >
                                <img
                                  style={{ width: "90%", height: "98%" }}
                                  src={tankInfo?.tankPhoto}
                                  alt="Serial Number Image"
                                />
                              </div>
                            ) : null}
                            {tankInfo?.distance ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span>Distance: {tankInfo?.distance}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <form>
                      <div></div>
                    </form>
                  </div>

                  <div className="panel-information">
                    <div className="panel-main-bg postal-address">
                      <p className="sectionTitleText">Installation Team</p>
                    </div>
                  </div>
                </div>
                <div className="innerSectionDetails">
                  <p
                    htmlFor=""
                    required
                    className="textFont"
                    style={{ paddingLeft: "10px" }}
                  >
                    Installer Signature Selfie
                  </p>

                  <div style={{ display: "flex", gap: "19px" }}>
                    <div>
                      {" "}
                      <input
                        className="input-text"
                        type="radio"
                        name="isInstallerSelfie"
                        id="isInstallerSelfieYes"
                        value="true"
                        onChange={handleIsInstallerSelfieChange}
                        checked={formdata?.isInstallerSelfie === true}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        htmlFor="isInstallerSelfieYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div>
                      {" "}
                      <input
                        className="input-text"
                        type="radio"
                        name="isInstallerSelfie"
                        id="isInstallerSelfieNo"
                        value="false"
                        onChange={handleIsInstallerSelfieChange}
                        checked={formdata?.isInstallerSelfie === false}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        htmlFor="isInstallerSelfieNo"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="subSectionInstallationteam">
                  <div className="row">
                    <button
                      type="button"
                      onClick={() => formdata?.plumbers[0]? viewTeam(formdata?.plumbers[0]):alert("Please select Plumber first ")}
                      className="view-details"
                    >
                      View Details
                    </button>
                    <div className="">
                      <h4>Plumber Selection</h4>
                      <div className="name-boxes">
                        <select
                          className="installing-type"
                          onChange={handleSelectPlumberChange}
                          value={formdata.plumbers[0]}
                        >
                          <option value="">Name</option>
                          {plumberData.map((plumber) => {
                            const installingDate = moment(
                              formdata?.installationDate?.installingDate
                            );
                            let cecExpiry = moment(plumber?.cecExpiry);
                            const fifteenDaysBeforeExpiry = cecExpiry.diff(
                              installingDate,
                              "days"
                            );
                            if (fifteenDaysBeforeExpiry <= 1) {
                              return null;
                            } else {
                              return (
                                <option key={plumber.id} value={plumber.id}>
                                  {plumber.name}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {isViewTeamOpen && (
                    <ProfileInstallationTeam
                      onClose={() => setViewTeamOpen(false)}
                    />
                  )}
                  <div className="row">
                    <button
                      type="button"
                      onClick={() => formdata?.electricians[0] ? viewTeam(formdata?.electricians[0]): alert("Select a Electrician first")}
                      className="view-details"
                    >
                      View Details
                    </button>
                    <h4>Electrician Selection</h4>
                    <div className="name-boxes">
                      <select
                        className="installing-type"
                        onChange={handleSelectElectricianChange}
                        value={formdata.electricians[0]}
                      >
                        <option value="">Name</option>
                        {electricianData.map((installer) => {
                          const installingDate = moment(
                            formdata?.installationDate?.installingDate
                          );
                          let cecExpiry = moment(installer?.cecExpiry);
                          const fifteenDaysBeforeExpiry = cecExpiry.diff(
                            installingDate,
                            "days"
                          );
                          if (fifteenDaysBeforeExpiry <= 1) {
                            return null;
                          } else {
                            return (
                              <option key={installer.id} value={installer.id}>
                                {installer.name}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <button
                      type="button"
                      onClick={() => formdata?.gasfitters[0] ?viewTeam(formdata?.gasfitters[0]):alert('Please Select Plumber first')}
                      className="view-details"
                    >
                      View Details
                    </button>
                    <h4>Gasfitter Selection</h4>
                    <div className="name-boxes">
                      <select
                        className="installing-type"
                        onChange={handleSelectDesignerChange}
                        value={formdata.gasfitters[0]}
                      >
                        <option value="">Name</option>
                        {gasfitterData.map((gasfitter) => {
                          const installingDate = moment(
                            formdata?.installationDate?.installingDate
                          );
                          let cecExpiry = moment(gasfitter?.cecExpiry);
                          const fifteenDaysBeforeExpiry = cecExpiry.diff(
                            installingDate,
                            "days"
                          );
                          if (fifteenDaysBeforeExpiry <= 1) {
                            return null;
                          } else {
                            return (
                              <option key={gasfitter.id} value={gasfitter.id}>
                                {gasfitter.name}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <textarea
                      className="instructions-input"
                      style={{ height: "120px", width: "305px" }}
                      type="text"
                      name="instructionForInstallation"
                      placeholder="Instructions for Installation"
                      value={formdata?.instructionForInstallation || ""}
                      onChange={handleInputsInstructionForInstallation}
                      maxLength={500}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="system-approved">
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className="system-text">
                          CEC Accreditation System
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="CECAccreditationSystemYes"
                              type="radio"
                              name="CECAccreditationSystem"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.CECAccreditationSystem === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="CECAccreditationSystemYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="CECAccreditationSystemNo"
                              type="radio"
                              name="CECAccreditationSystem"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.CECAccreditationSystem === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="CECAccreditationSystemNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>

                        <label className="system-text">
                          Electrical Safety Documentation
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="electricalSafetyDocYes"
                              type="radio"
                              name="electricalSafetyDoc"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.electricalSafetyDoc === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="electricalSafetyDocYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="electricalSafetyDocNo"
                              type="radio"
                              name="electricalSafetyDoc"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.electricalSafetyDoc === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="electricalSafetyDocNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="approved-govt"
                      >
                        <label className="system-text">
                          Local, State and Territory Government Sitting Approval
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="territoryGovermentApprovalYes"
                              type="radio"
                              name="territoryGovermentApproval"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.territoryGovermentApproval === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="territoryGovermentApprovalYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="territoryGovermentApprovalNo"
                              type="radio"
                              name="territoryGovermentApproval"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.territoryGovermentApproval === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="territoryGovermentApprovalNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <label className="system-text">
                          Australian / New Zealand Standard Statement
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="AusNZStandardStatementYes"
                              type="radio"
                              name="AusNZStandardStatement"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.AusNZStandardStatement === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="AusNZStandardStatementYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="AusNZStandardStatementNo"
                              type="radio"
                              name="AusNZStandardStatement"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.AusNZStandardStatement === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="AusNZStandardStatementNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <div>
                    <label className="system-text">
                      Creating Certificates for previously failed SWH?
                    </label>
                    <ul className="system-main">
                      <li className="main-text">
                        {" "}
                        <input
                          id="failedSWH"
                          type="radio"
                          name="isItForPreviouslyFailedSWH"
                          className="input-text"
                          value="true"
                          checked={
                            formdata?.failedSWH?.isItForPreviouslyFailedSWH ===
                            true
                          }
                          onChange={handlefailedSWHChange}
                        />
                        <label
                          style={{ cursor: "pointer" }}
                          htmlFor="failedSWHYes"
                        >
                          Yes
                        </label>
                      </li>
                      <li>
                        {" "}
                        <input
                          id="failedSWHNo"
                          type="radio"
                          name="isItForPreviouslyFailedSWH"
                          className="input-text"
                          value="false"
                          checked={
                            formdata?.failedSWH?.isItForPreviouslyFailedSWH ===
                            false
                          }
                          onChange={handlefailedSWHChange}
                        />
                        <label
                          style={{ cursor: "pointer" }}
                          htmlFor="failedSWHNo"
                        >
                          No
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <input
                      className="attributes-input"
                      type="text"
                      style={{ margin: "0px" }}
                      placeholder="Accreditation Code"
                      name="accreditationCode"
                      maxLength={20}
                      value={formdata?.failedSWH?.accreditationCode || ""}
                      disabled={
                        formdata?.failedSWH?.isItForPreviouslyFailedSWH ===
                        false
                      }
                      onChange={handlefailedSWHChange}
                    />
                  </div>
                  <textarea
                    className="instructions-input notes-input"
                    style={{
                      height: "70px",
                      width: "305px",
                      marginLeft: "10px",
                    }}
                    type="text"
                    name="explanatoryNotes"
                    placeholder="Explanatory Notes"
                    value={formdata?.failedSWH?.explanatoryNotes || ""}
                    disabled={
                      formdata?.failedSWH?.isItForPreviouslyFailedSWH === false
                    }
                    onChange={handlefailedSWHChange}
                    maxLength={500}
                  />
                </div>
                <div className="panel-information">
                  <ManageFolder jobType={formdata?.jobType} />
                </div>
                <br />
                <div className="panel-information">
                  <DocumentsRequired
                    jobType={formdata?.jobType}
                    jobData={formdata}
                    getJobData={fetchJobData}
                  />
                </div>

                <br />
                <div className="panel-information">
                  <SignaturePage
                    emailId={formdata?.ownerDetails?.emailId}
                    handleSubmit={handleSubmit}
                    jobType={formdata?.jobType}
                  />
                </div>
                <br />
                <div className="footer-bg">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "90%",
                      paddingLeft: "10%",
                    }}
                  >
                    <div>
                      <button type="submit" className="btn-partial">
                        Partial Save & Exit
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          window.close();
                          localStorage.removeItem("jobId");
                        }}
                        className="submit-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  {statusCode === 3 && shouldShowFinishButton() && (
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          handleButtonClick(6);
                        }}
                        className="submit-btn"
                      >
                        Finish Job
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          ) : (
            <div
              style={{
                display: "flex",
                height: "60vh",
                justifyContent: "center",
                alignItems: "center",
                width: "82vw",
              }}
            >
              <FadeLoader color="#154397" radius={100} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateHWJob;
