import React, { useState, useEffect, useMemo } from "react";
import Aside from "../../FixedBar/Aside";
import HeaderComponent from "../../Dashboard/CommonComponents/HeaderComponent";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RememberMeIcon from "@mui/icons-material/RememberMe";

import "../../Dashboard/Dashboard.css";
import { Download, EmailRounded } from "@mui/icons-material";
import { Badge } from "@mui/material";
import moment from "moment";
import Chat from "../../Dashboard/CommonComponents/Chat/Chat";
import NotesComponent from "../../Dashboard/NotesComponent";
import { BeatLoader, FadeLoader } from "react-spinners";
import JSZip from "jszip";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const DashboardHW = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");
  const userType = localStorage.getItem("userType");

  const [selectedJobTab, setSelectedJobTab] = useState("Hot Water");
  const [jobType, setJobType] = useState(false);
  const [showSPVButtons, setShowSPVButtons] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  //Form Data starts
  const [formdata, setFormdata] = useState({
    installationAddress: {
      addressType: "",
      unitType: "",
      unitNumber: null,
      streetNumber: null,
      streetName: "",
      streetType: "",
      suburb: "",
      state: "",
      postCode: null,
    },

    installationDetails: {
      installationType: "",
      newPanelLocation: "",
      kilowatt: null,
      remarks: "",
      CECAccreditationSystem: true,
      territoryGovermentApproval: true,
      electricalSafetyDoc: true,
      AusNZStandardStatement: true,
    },

    postalAddress: {
      POBox: {
        POBoxNo: null,
        postalCode: null,
        postalArea: "",
      },
      POAddress: {
        addressType: "",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
    },

    installingFor: "",
    systemOwnerType: "",

    ownerDetails: {
      firstName: "",
      lastName: "",
      companyName: "",
      mobileNum: null,
      phoneNumber: null,
      emailId: "",
      ABN: null,
      isGST: false,
      gstNo: "",
    },

    coOwnerDetail: {
      firstName: "",
      lastName: "",
      mobileNum: null,
      phoneNumber: null,
      emailId: "",
    },

    installationDate: {
      installingDate: "",
      STCDeemingPeriod: null,
      mountingType: "",
    },

    gridConnectionDetail: {
      connectionType: "",
      systemType: "",
      nmi: "",
      gridAppReferenceNo: "",
      meterNum: null,
      distributor: "",
    },

    panelDetail: {
      panelInfo: {
        panelBrand: "",
        panelModel: "",
        panelPower: null,
        Quantity: null,
        wattage: null,
        stc: null,
        financialPayment: null,
        isAssignPanelJob: false,
      },
    },

    inverterDetail: {
      inverterInfo: {
        inverterBrand: "",
        inverterSeries: "",
        inverterModel: "",
        inverterSupplier: "",
        Quantity: null,
      },
    },

    batteryDetail: {
      batteryInfo: {
        manufacturer: "",
        batteryBrand: "",
        batterySeries: "",
        batteryModel: "",
        Quantity: null,
        batterySystemAggregate: false,
        defaultManufacturerBatteryStorage: false,
      },
    },

    warrantyDetail: {
      warrantyYear: null,
      warrantyDescription: "",
    },

    instructionForInstallation: "",

    additionalAttributes: {
      CRMID: "",
      currentEnergyRetailer: "",
      WillThereBeAnyOptimisersInstalled: false,
    },

    siteAttributes: {
      roof: "",
      storey: "",
      panelType: "",
      phase: "",
    },

    design: "",
    customInvoice: "",
    uploadElectricityBill: "",
    STCForm: "",
    STCInvoice: "",
    testingCertificate: "",
    noticeOfCompletion: "",

    installers: [],
    installerName: "",
    designers: [],
    designerName: "",
    electricians: [],
    electricianName: "",
  });
  //Form Data ends

  // Installation Team View Start

  const [viewActionsPanel, setViewActionsPanel] = useState(false);
  // Team View System Info Starts
  const viewActions = (jobId) => {
    localStorage.setItem("jobId", jobId);
    setViewActionsPanel(true);
  };
  // Installation Team View Ends

  //Submit Job PopUp Starts
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  //Submit Job PopUp Starts

  //Delete Job PopUp Starts

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`${baseURL}/job/deleteJob/${jobId}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        alert("Job Deleted Successfully");
        localStorage.removeItem("jobId");
        window.location.reload();
      } else {
        console.log("Error: " + (response.data || response.statusText));
      }
    } catch (error) {
      console.error("Error in update request:", error);
      console.log("Error status:", error.response?.status);
    }
    setShowDeleteConfirmation(false);
  };

  //Delete Job PopUp Ends

  //API Call to get Job Details Starts
  const [jobDataShow, setJobDataShow] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const test = async () => {
    setJobLoading(true);
    let apiUrl = "";
    if (userType === "Trader") {
      apiUrl = `${baseURL}/traders/jobs`;
    } else {
      apiUrl = `${baseURL}/job/all`;
    }
    try {
      const resp = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setJobLoading(false);
      const data = userType === "Trader" ? resp?.data?.jobs : resp?.data;
      const SWHData = data.filter((job) => {
        return job?.jobType === "SWH";
      });
      setJobData(SWHData);
      setJobDataShow(SWHData);
    } catch (err) {
      console.log("Error:", err);
      setJobLoading(false);
    }
  };
  useEffect(() => {
    test();
  }, [refresh]);

  const handleEditJob = (jobId) => {
    localStorage.setItem("jobId", jobId);
    const editJobWindow = window.open("/createSHWJob", "_blank");

    const checkWindowClosed = setInterval(() => {
      if (editJobWindow.closed) {
        clearInterval(checkWindowClosed);
        setRefresh((prev) => !prev);
      }
    }, 500);
  };

  //Job Status Change API Call Starts
  const handleButtonClick = async (status) => {
    try {
      const jobId = localStorage.getItem("jobId");
      const requestData = {
        newStatus: status,
      };
      const response = await axios.patch(
        `${baseURL}/users/jobschangeStatus/${jobId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Job Status Updated Successfully");
        localStorage.removeItem("jobId");
        window.location.reload();
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  //Job Status Change API Call Ends
  //API Call to get Job Details Ends

  // Filter Jobs Logic Starts
  const [searchQuery, setSearchQuery] = useState("");
  const [showQuickFilters, setShowQuickFilters] = useState(false);
  const [activeFilter, setActiveFilter] = useState(
    userType === "Trader" ? 10 : 3
  );
  const filteredJobList = useMemo(() => {
    let filteredList = jobDataShow;
    if (searchQuery) {
      filteredList = filteredList.filter((jobDataArray) =>
        [
          jobDataArray?.ownerDetails?.firstName,
          jobDataArray?.ownerDetails?.lastName,
          jobDataArray?.ownerDetails?.companyName,
          jobDataArray?.additionalAttributes?.CRMID,
          jobDataArray?.jobIdentifyId,
        ]
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }
    // Filter by job status
    if (activeFilter !== null && filteredList) {
      filteredList = filteredList.filter(
        (jobDataArray) =>
          (activeFilter === 8 && jobDataArray?.siteInspection === true) ||
          (activeFilter === 3 &&
            (jobDataArray?.statusCode === 3 ||
              jobDataArray?.statusCode === 6)) ||
          (activeFilter === 17 &&
            (jobDataArray?.statusCode === 11 ||
              jobDataArray?.statusCode === 13 ||
              jobDataArray?.statusCode === 14)) ||
          (activeFilter !== 8 &&
            activeFilter !== 3 &&
            activeFilter !== 17 &&
            jobDataArray?.statusCode === activeFilter)
      );
      return filteredList.reverse();
    }

    return filteredList.reverse();
  }, [jobDataShow, searchQuery, activeFilter]);

  const clearFilters = () => {
    setShowQuickFilters(false);
    setActiveFilter(userType === "Trader" ? 10 : 3);
    setSearchQuery("");
  };
  const reverseFilteredJobList = [...filteredJobList];

  // Filter Jobs Logic Ends

  // ToolTips Starts
  const showTooltip = (e) => {
    const tooltip = e.currentTarget.querySelector(".tooltip");
    if (tooltip) {
      tooltip.classList.add("show-tooltip");
    }
  };

  const hideTooltip = (e) => {
    const tooltip = e.currentTarget.querySelector(".tooltip");
    if (tooltip) {
      tooltip.classList.remove("show-tooltip");
    }
  };

  const toggleTooltip = (e) => {
    const tooltip = e.currentTarget.querySelector(".tooltip");
    if (tooltip) {
      tooltip.classList.toggle("show-tooltip");
    }
  };
  // ToolTips Ends

  // Create Job by uploading file Starts
  const [file, setFile] = useState(null);
  const handleUploadButtonClick = () => {
    if (userType !== "Trader") {
      document.getElementById("fileInput").click();
    }
  };
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile.type !== "text/csv") {
      alert("Please select a CSV file.");
      return;
    }
    try {
      const csvData = await readCSVFile(selectedFile);
      await createJobsFromCSV(csvData);
      alert("Jobs created successfully.");
    } catch (error) {
      console.error("Error creating jobs:", error);
      alert("Error creating jobs. Please try again.");
    }
  };
  const readCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        resolve(csvData);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };
  const createJobsFromCSV = async (csvData) => {
    const rows = csvData.split("\n");
    const headers = rows
      .shift()
      .split(",")
      .map((header) => header.trim());
    for (const row of rows) {
      const rowData = row.split(",").map((data) => data.trim());
      if (rowData.length === 0 || rowData.every((cell) => cell === ""))
        continue;
      const formData = prepareFormData(rowData, headers);
      await createJob(formData);
    }
  };
  const prepareFormData = (rowData, headers) => {
    const formData = {};
    headers.forEach((header, index) => {
      const keys = header.split("_");
      let ref = formData;
      keys.forEach((key, idx) => {
        if (idx === keys.length - 1) {
          ref[key] = rowData[index];
        } else {
          if (!ref[key]) ref[key] = {};
          ref = ref[key];
        }
      });
    });
    return formData;
  };
  const createJob = async (formData) => {
    setLoading(true);
    try {
      const formDataObject = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataObject.append(key, JSON.stringify(formData[key]));
      });
      const response = await axios.post(
        `${baseURL}/job/create-job`,
        formDataObject,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Jobs Created Successfully");
      window.location.reload();
      setLoading(false);
    } catch (error) {
      console.error("Error creating job:", error);
      setLoading(false);
      throw error;
    }
  };
  // Create Job by uploading file Ends

  //Toogle button functions for Generate SPV button click
  const handleChooseJob = () => {
    window.open("/dashboard", "_blank");
    return;
  };

  const handleKeyChange = async (jobId) => {
    try {
      const formData = new FormData();
      formData.append("isSPV", true);

      const response = await axios.patch(
        `${baseURL}/job/update/${jobId}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setShowSPVButtons(true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 800);
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const handleGenerateButton = (data) => {
    if (data?.isSPV) {
      alert("Feature coming soon");
    } else {
      if (data.panelDetail.isAssignPanelJob === true) {
        alert("This is a Non-SPV Job.\nThis feature is only for SPV Job");
      } else {
        setJobType(true);
        handleKeyChange(data?._id);
      }
    }
  };
  const handleDownloadButtons = (field, id) => {
    // if(field==="csvx" || field=="SRES-CSV" || field=="SRES-CSVX" || field=='csv' ){
    alert("Feature Coming Soon");
    // }
  };
  const convertToCSV = (result, id) => {
    const csvRows = [];
    const headers = Object.keys(result);
    csvRows.push(headers.join(","));
    const values = headers.map((header) =>
      JSON.stringify(result[header], replaceNullWithEmptyString)
    );

    csvRows.push(values.join(","));
    return csvRows.join("\n");
  };
  const replaceNullWithEmptyString = (key, value) =>
    value === null ? "" : value;
  // Convert to xml function
  const objectToXml = (data, parentTag = "root") => {
    let xml = "";
    if (parentTag) {
      xml += `<?xml version="1.0" encoding="UTF-8"?>\n<${parentTag}>\n`;
    }
    for (const [key, value] of Object.entries(data)) {
      xml += convertValueToXML(key, value);
    }
    if (parentTag) {
      xml += `</${parentTag}>`;
    }
    return xml;
  };
  const convertValueToXML = (key, value) => {
    if (Array.isArray(value)) {
      return value.map((item) => convertValueToXML(key, item)).join("");
    } else if (typeof value === "object" && value !== null) {
      return `<${key}>\n${objectToXml(value, null)}\n</${key}>\n`;
    } else {
      return `  <${key}>${XMLSafeValue(value)}</${key}>\n`;
    }
  };
  const XMLSafeValue = (value) => {
    if (typeof value === "string") {
      return value.replace(/[<>&'"]/g, (c) => {
        switch (c) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;";
          case "&":
            return "&amp;";
          case "'":
            return "&apos;";
          case '"':
            return "&quot;";
          default:
            return c;
        }
      });
    }
    return value;
  };
  // const handleExport = async (field, id) => {
  //   const flattenedData = jobData?.jobs.flat();
  //   const result = flattenedData.find((item) => item._id === id);
  //   if (field === "CSV" || field === "CSVX") {
  //     const csvData = convertToCSV(result, id);
  //     const blob = new Blob([csvData], { type: "text/csv" });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.setAttribute("hidden", "");
  //     a.setAttribute("href", url);
  //     a.setAttribute("download", "download.csv");
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //   } else {
  //     try {
  //       const formData = new FormData();
  //       formData.append("countXML", result?.countXML + 1);

  //       const response = await axios.patch(
  //         `${baseURL}/job/update/${id}`,
  //         formData,
  //         {
  //           withCredentials: true,
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );
  //       if (response.status === 200 || response.status === 201) {
  //         setShowSPVButtons(true);
  //         setTimeout(() => {
  //           navigate("/dashboard");
  //         }, 800);
  //       } else {
  //         console.error(
  //           "Login failed. Unexpected status code:",
  //           response.status
  //         );
  //       }
  //     } catch (error) {
  //       alert(error?.response?.data?.message);
  //     }
  //     const xmlData = objectToXml(result);
  //     const blob = new Blob([xmlData], { type: "text/plain" });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.setAttribute("hidden", "");
  //     a.setAttribute("href", url);
  //     a.setAttribute("download", "download.xml");
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //   }
  // };

  const headers = [
    "System brand",
    "System model",
    "Tank serial number(s)",
    "Number of panels",
    "Installation date",
    "Installation type",
    "Is the volumetric capacity of this installation greater than 700L",
    "Statutory declarations sent",
    "Is your water heater second hand",
    "Reference",
    "Certificate tag",
    "Installation property type",
    "Installation single or multi-story",
    "Installation property name or boat name or chassis number",
    "Installation unit type",
    "Installation unit number",
    "Installation street number",
    "Installation street name",
    "Installation street type",
    "Installation town/suburb",
    "Installation state",
    "Installation postcode",
    "Installation latitude",
    "Installation longitude",
    "Is there more than one SWH/ASHP at this address",
    "Additional system information",
    "Creating certificates for previously failed SWH",
    "Failed accreditation code",
    "Explanatory notes for failed accreditation code",
    "Installation special address",
    "Owner type",
    "Owner organisation name",
    "Owner first name",
    "Owner surname",
    "Owner phone",
    "Owner fax",
    "Owner mobile",
    "Owner email",
    "Owner address type",
    "Owner postal delivery type",
    "Owner postal delivery number",
    "Owner unit type",
    "Owner unit number",
    "Owner street number",
    "Owner street name",
    "Owner street type",
    "Owner town/suburb",
    "Owner state",
    "Owner postcode",
    "Owner country",
    "Installer first name",
    "Installer surname",
    "Installer phone",
    "Installer fax",
    "Installer mobile",
    "Installer email",
    "Installer address type",
    "Installer postal delivery type",
    "Installer postal delivery number",
    "Installer unit type",
    "Installer unit number",
    "Installer street number",
    "Installer street name",
    "Installer street type",
    "Installer town/suburb",
    "Installer state",
    "Installer postcode",
  ];

  const handleExport = async (field, id) => {
    try {
      const resp = await axios.get(`${baseURL}/jobMaster/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp.data.data[0];
      const jobDetails = data?.jobDetails;
      const plumbers = data?.plumbers[0];
      const tanks = data?.tanks[0];
      const reference =
        jobDetails?.installingFor === "Individual"
          ? (jobDetails?.ownerDetails?.firstName || "") +
            " " +
            (jobDetails?.ownerDetails?.lastName || "")
          : jobDetails?.ownerDetails?.companyName || "";

      const values = [
        [
          jobDetails?.hotWaterDetail?.hotWaterBrand,
          jobDetails?.hotWaterDetail?.hotWaterInfo?.hotWaterModel,
          tanks?.serialNumber,
          jobDetails?.hotWaterDetail?.noOfPanels || 0,
          moment(jobDetails?.installationDate?.installingDate).format(
            "DD/MM/YYYY"
          ),
          jobDetails?.installationType,
          jobDetails?.hotWaterDetail?.hotWaterInfo?.hotWaterCapacity > 700
            ? "Yes"
            : "No",
          "",
          "No",
          reference,
          "",
          jobDetails?.systemOwnerType,
          jobDetails?.siteAttributes?.storey
            ? `${jobDetails?.siteAttributes?.storey} story`
            : "",
          "",
          jobDetails?.installationAddress?.unitType,
          jobDetails?.installationAddress?.unitNumber,
          jobDetails?.installationAddress?.streetNumber,
          jobDetails?.installationAddress?.streetName,
          jobDetails?.installationAddress?.streetType === "Cres"
            ? "Cr"
            : jobDetails?.installationAddress?.streetType === "Ave"
            ? "AV"
            : jobDetails?.installationAddress?.streetType,
          jobDetails?.installationAddress?.suburb,
          jobDetails?.installationAddress?.state,
          jobDetails?.installationAddress?.postCode,
          jobDetails?.installationAddress?.latitude,
          jobDetails?.installationAddress?.longitude,
          "No",
          "",
          jobDetails?.failedSWH?.isItForPreviouslyFailedSWH === true
            ? "Yes"
            : "No",
          jobDetails?.failedSWH?.isItForPreviouslyFailedSWH === true
            ? jobDetails?.failedSWH?.accreditationCode
            : "",
          jobDetails?.failedSWH?.isItForPreviouslyFailedSWH === true
            ? jobDetails?.failedSWH?.explanatoryNotes
            : "",
          "",
          jobDetails?.installingFor,
          jobDetails?.ownerDetails?.companyName
            ? jobDetails?.ownerDetails?.companyName
            : "",
          jobDetails?.ownerDetails?.firstName
            ? jobDetails?.ownerDetails?.firstName
            : "",
          jobDetails?.ownerDetails?.lastName
            ? jobDetails?.ownerDetails?.lastName
            : "",
          jobDetails?.ownerDetails?.phoneNumber,
          "",
          jobDetails?.ownerDetails?.mobileNum,
          jobDetails?.ownerDetails?.emailId,
          jobDetails?.postalAddress?.POAddress?.addressType,
          "",
          "",
          jobDetails?.postalAddress?.POAddress?.unitType,
          jobDetails?.postalAddress?.POAddress?.unitNumber,
          jobDetails?.postalAddress?.POAddress?.streetNumber,
          jobDetails?.postalAddress?.POAddress?.streetName,
          jobDetails?.postalAddress?.POAddress?.streetType === "Cres"
            ? "Cr"
            : jobDetails?.postalAddress?.POAddress?.streetType === "Ave"
            ? "AV"
            : jobDetails?.postalAddress?.POAddress?.streetType,
          jobDetails?.postalAddress?.POAddress?.suburb,
          jobDetails?.postalAddress?.POAddress?.state,
          jobDetails?.postalAddress?.POAddress?.postCode,
          "AU",
          jobDetails.plumberName.trim().split(" ").slice(0, -1).join(" "),
          jobDetails.plumberName.trim().split(" ").pop(),
          plumbers?.installer?.mobileNo,
          "",
          plumbers?.installer?.mobileNo,
          plumbers?.installer?.emailId,
          plumbers?.installer?.fullAddress?.addressType,
          "",
          "",
          plumbers?.installer?.fullAddress?.unitType,
          plumbers?.installer?.fullAddress?.unitNumber,
          plumbers?.installer?.fullAddress?.streetNumber,
          plumbers?.installer?.fullAddress?.streetName,
          plumbers?.installer?.fullAddress?.streetType === "Cres"
            ? "Cr"
            : plumbers?.installer?.fullAddress?.streetType === "Ave"
            ? "AV"
            : plumbers?.installer?.fullAddress?.streetType,
          plumbers?.installer?.fullAddress?.suburb,
          plumbers?.installer?.fullAddress?.state,
          plumbers?.installer?.fullAddress?.postCode,
        ],
      ];

      const escapeCsvValue = (value) => {
        if (typeof value === "string" && value.includes(",")) {
          return `"${value}"`;
        }
        return value;
      };

      const csvContent = [
        headers.map(escapeCsvValue).join(","),
        ...values.map((row) => row.join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "csvData.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Download CSV of Reports Starts
  const handleDownload = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    const currentDateString = `${day}_${month}_${year}`;

    const headers = [
      "Job ID",
      "Owner First Name",
      "Owner Last Name",
      "Installation Date",
      "Address",
      "Plumber",
      "Gasfitter",
      "Electrician",
      "STC",
      "Job Status",
    ];

    let csv = headers.join(",") + "\n";
    const allData = userType === "Trader" ? jobData?.jobs : jobData;

    allData.forEach((data) => {
      if (data?.jobIdentifyId?.startsWith("SWH")) {
        let address = `${data?.installationAddress?.addressType ?? ""} ${
          data?.installationAddress?.unitType ?? ""
        } ${data?.installationAddress?.unitNumber ?? ""} ${
          data?.installationAddress?.streetNumber ?? ""
        } ${data?.installationAddress?.streetName ?? ""} ${
          data?.installationAddress?.streetType ?? ""
        } ${data?.installationAddress?.suburb ?? ""} ${
          data?.installationAddress?.state ?? ""
        } ${data?.installationAddress?.postCode ?? ""}`;

        let row = [
          data?.jobIdentifyId ?? "",
          data?.ownerDetails?.firstName ?? "",
          data?.ownerDetails?.lastName ?? "",
          data?.installationDate?.installingDate
            ? moment(data?.installationDate?.installingDate).format(
                "DD/MM/YYYY"
              )
            : "",
          address,
          data?.plumberName ?? "",
          data?.gasfitterName ?? "",
          data?.electricianName ?? "",
          data?.hotWaterDetail?.stc ?? "",
          data?.statusDescription ?? "",
        ].join(",");

        csv += row + "\n";
      }
    });
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Job_Status_${currentDateString}.csv`;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };
  //Download CSV of Reports Ends

  //Job Pack Function Starts

  const [jobPackLoadingID, setJobPackLoadingID] = useState(null);

  const downloadAllFormsZip = async (responseData) => {
    try {
      const zip = new JSZip();
      const imagesFolder = zip.folder("images");

      for (const urls of Object.values(responseData)) {
        for (let i = 0; i < urls.length; i++) {
          const url = urls[i].url;
          try {
            const response = await axios.get(url, {
              responseType: "arraybuffer",
            });
            const filename = url.split("/").pop();
            imagesFolder.file(`${filename}`, response.data, { binary: true });
          } catch (error) {
            console.error(`Error fetching image ${url}:`, error);
          }
        }
      }

      // Generate zip content
      const content = await zip.generateAsync({ type: "blob" });
      // Create download link and trigger download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "forms.zip";
      link.click();
      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadZip = async (data) => {
    const jobId = data?._id;
    localStorage.setItem("jobId", jobId);
    let apiUrl = `${baseURL}/get/photosAndDocuments/${jobId}`;
    try {
      const apiResponse = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const files = apiResponse.data;
      await downloadAllFormsZip(files);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const openJobPackTab = async (jobId) => {
    const url = `/dashboard-SWH/job-pack/${jobId}`;
    window.open(url, "_blank");
  };

  const handleDownloadClick = async (data) => {
    setJobPackLoadingID(data._id);
    await downloadZip(data);
    await openJobPackTab(data._id);
    setJobPackLoadingID(null);
  };

  //Job Pack Function Ends

  return (
    <div className="MainOuterContainer">
      <Aside />
      <div style={{ width: "100%" }}>
        <div className=" main-part-ratailer">
          <div>
            <HeaderComponent
              PageTitle={
                userType == "Trader" ? "Trader Dashboard" : "Retailer Dashboard"
              }
              location="Dashboard"
              handleUploadButtonClick={handleUploadButtonClick}
              handleFileChange={handleFileChange}
              JobType="Hot Water"
              loading={loading}
              getJobs={test}
            />
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "22%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "auto",
                width: "100%",
              }}
            >
              <div className="STCPriceSection ">
                <p className="PriceDataText"> Unregistered STCs : </p>
                <span className="priceTextLive">$39.40</span>
              </div>
              <div className="STCPriceSection ">
                <p className="PriceDataText"> Registered STCs : </p>
                <span className="priceTextLive">$39+GST</span>
              </div>
            </div>
            <div className="TabsDiv">
              <div className="tabsButtonDIv">
                <button
                  type="button"
                  style={{
                    backgroundColor:
                      selectedJobTab === "Solar PV Jobs"
                        ? "rgba(0, 67, 229, 0.3)"
                        : "transparent",
                    color: selectedJobTab === "Solar PV Jobs" ? "#fff" : "#333",
                  }}
                  onClick={handleChooseJob}
                >
                  <img
                    src="/Assets/DashboardPV/solar-panel.svg"
                    alt="Solar panel"
                    width={"33px"}
                  />
                  <span style={{ fontSize: "14px", lineHeight: "16px" }}>
                    Solar PV
                  </span>
                </button>
                <button
                  type="button"
                  style={{
                    backgroundColor:
                      selectedJobTab === "Hot Water"
                        ? "rgba(0, 67, 229, 0.3)"
                        : "transparent",
                    color: selectedJobTab === "Hot Water" ? "#fff" : "#333",
                  }}
                  onClick={handleChooseJob}
                >
                  <img
                    src="/Assets/DashboardPV/hot-surface.svg"
                    width={"33px"}
                  />
                  <span style={{ fontSize: "14px", lineHeight: "16px" }}>
                    {" "}
                    Hot Water
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="search-filter-section">
            <div className="search-container">
              <form action="">
                <input
                  type="text"
                  placeholder="Search by Owner Name, Company Name, CRM ID or SWH Job Number"
                  name="search"
                  className="input-search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </div>
            <div className="buttons-container">
              <div className="btn-filter">
                <button
                  className="filter-text"
                  onClick={() => {
                    setShowQuickFilters(true);
                  }}
                >
                  Filter
                </button>
              </div>
              <div className="btn-clear">
                <button
                  className="clear-text"
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          {showQuickFilters ? (
            <div className="quick-filter" style={{ flexWrap: "wrap" }}>
              <span>Quick Filters:</span>
              {userType === "Retailer" ? (
                <button
                  className={`filter-job-btn ${
                    activeFilter === 3 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(3)}
                >
                  In Progress
                </button>
              ) : null}
              <button
                className={`filter-submit-btn ${
                  activeFilter === 10 ? "selected" : ""
                }`}
                onClick={() => setActiveFilter(10)}
              >
                {userType === "Retailer" ? "Submitted" : "In Progress"}
              </button>

              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 11 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(11)}
                >
                  Waiting For Approval
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 7 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(7)}
                >
                  Parked
                </button>
              )}

              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 12 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(12)}
                >
                  Resubmitted after failure
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 8 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(8)}
                >
                  Site Inspection
                </button>
              )}
              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 13 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(13)}
                >
                  Failed
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 9 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(9)}
                >
                  Cancelled
                </button>
              )}
              {userType === "Trader" ? (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 14 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(14)}
                >
                  Approved
                </button>
              ) : (
                <button
                  className={`filter-submit-btn ${
                    activeFilter === 17 ? "selected" : ""
                  }`}
                  onClick={() => setActiveFilter(17)}
                >
                  Completed
                </button>
              )}
            </div>
          ) : null}
          <div className="row">
            <div className="filter-two-btn"></div>
          </div>
          {reverseFilteredJobList?.length > 0 ? (
            <div>
              <div
                style={{
                  width: "200px",
                  float: "right",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <button
                  type="button"
                  className="buttonForReports"
                  onClick={handleDownload}
                  style={{ display: "none" }}
                >
                  <Download sx={{ fontSize: "large", marginRight: "2px" }} />{" "}
                  Reports
                </button>
              </div>
            </div>
          ) : null}
          {userType === "Retailer" && reverseFilteredJobList?.length > 0 ? (
            <div>
              <div
                style={{
                  width: "200px",
                  float: "right",
                  justifyContent: "center",
                  display:
                    activeFilter === 10 || activeFilter === 3 ? "flex" : "none",
                }}
              >
                <button
                  type="button"
                  className="buttonForReports"
                  onClick={handleDownload}
                >
                  <Download sx={{ fontSize: "large", marginRight: "2px" }} />{" "}
                  Reports
                </button>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="dashboard-information">
              {jobLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FadeLoader color="#154397" radius={800} />
                </div>
              ) : reverseFilteredJobList?.length > 0 ? (
                reverseFilteredJobList.map((data, outerIndex) => (
                  <div className="information-bg" key={outerIndex}>
                    <div className="dashboard-part">
                      <div className="DashboardFirstSection">
                        <div className="DashboardCardFirstSectionRow">
                          <div className="dash-text">
                            <p className="inform-text">
                              {data?.jobIdentifyId ?? "N/A"}
                            </p>
                            <p className="show-date">
                              {data?.additionalAttributes?.CRMID ?? "N/A"}
                            </p>
                            <p>
                              <span className="create-date">
                                Installation Date:
                                <b className="show-date">
                                  {data?.installationDate?.installingDate ? (
                                    <>
                                      {String(
                                        new Date(
                                          data.installationDate?.installingDate
                                        ).getDate()
                                      ).padStart(2, "0")}
                                      -
                                      {String(
                                        new Date(
                                          data.installationDate?.installingDate
                                        ).getMonth() + 1
                                      ).padStart(2, "0")}
                                      -
                                      {new Date(
                                        data.installationDate?.installingDate
                                      ).getFullYear()}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </b>
                              </span>
                            </p>
                            <p>
                              <span className="create-install">
                                {userType === "Trader" ? "Retailer" : "Plumber"}
                                :
                                <b className="show-install">
                                  {userType === "Trader"
                                    ? data?.retailerData?.companyInformation
                                        ?.tradingName
                                      ? `${data?.retailerData?.companyInformation?.tradingName}`
                                      : "N/A"
                                    : data?.plumberName
                                    ? data?.plumberName
                                    : "N/A"}
                                  {/* {userType === "Trader"
                                    ? data?.retailerData?.basicInformation
                                        ?.firstName &&
                                      data?.retailerData?.basicInformation
                                        ?.lastName
                                      ? `${data.retailerData.basicInformation.firstName} ${data.retailerData.basicInformation.lastName}`
                                      : "N/A"
                                    : data?.plumberName
                                    ? data.plumberName
                                    : "N/A"} */}
                                </b>
                              </span>
                            </p>
                          </div>
                          <div className="DetailsOfOwner">
                            <div
                              className="iconAndDetail"
                              style={{ gap: "8px" }}
                            >
                              <img
                                src={`${image}/Assets/DashboardPV/User.svg`}
                                alt=""
                              />
                              {data?.installingFor === "Individual" ? (
                                <div className="user-name">
                                  {data?.ownerDetails?.firstName &&
                                  data?.ownerDetails?.lastName
                                    ? `${data.ownerDetails.firstName} ${data.ownerDetails.lastName}`
                                    : "N/A"}
                                </div>
                              ) : (
                                <div className="user-name">
                                  {data?.ownerDetails?.companyName
                                    ? data.ownerDetails.companyName
                                    : "N/A"}
                                </div>
                              )}
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ gap: "5px" }}
                            >
                              <img
                                src={`${image}/Assets/DashboardPV/Location.svg`}
                                alt=""
                                className="user-icon location-icon"
                              />
                              <div className="user-text user-address">
                                {(() => {
                                  const addressParts = [
                                    data?.installationAddress?.unitType &&
                                      `${data.installationAddress.unitType} `,
                                    data?.installationAddress?.unitNumber &&
                                      `${data.installationAddress.unitNumber}, `,
                                    data?.installationAddress?.streetNumber &&
                                      `${data.installationAddress.streetNumber} `,
                                    data?.installationAddress?.streetName &&
                                      `${data.installationAddress.streetName} `,
                                    data?.installationAddress?.streetType &&
                                      `${data.installationAddress.streetType}, `,
                                    data?.installationAddress?.suburb &&
                                      `${data.installationAddress.suburb}, `,
                                    data?.installationAddress?.state &&
                                      `${data.installationAddress.state}, `,
                                    data?.installationAddress?.postCode &&
                                      `${data.installationAddress.postCode}`,
                                  ]
                                    .filter(Boolean)
                                    .join("");

                                  return addressParts || "N/A";
                                })()}
                              </div>
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ gap: "5px" }}
                            >
                              <EmailRounded
                                className="user-icon"
                                sx={{ width: "20px" }}
                              />
                              <div
                                style={{ overflowWrap: "wrap" }}
                                className="emailSection"
                              >
                                <p className="email-id-owner">
                                  {data?.ownerDetails?.emailId || "N/A"}
                                </p>
                              </div>
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ alignItems: "center", gap: "5px" }}
                            >
                              <RememberMeIcon sx={{ fontSize: "19px" }} />
                              <div className="user-text">
                                {data?.ownerDetails?.mobileNum
                                  ? data?.ownerDetails?.mobileNum
                                  : "N/A"}
                              </div>
                            </div>
                            <div
                              className="iconAndDetail"
                              style={{ alignItems: "center", gap: "5px" }}
                            >
                              <RememberMeIcon sx={{ fontSize: "19px" }} />
                              <div className="user-text">
                                {data?.ownerDetails?.phoneNumber
                                  ? data?.ownerDetails?.phoneNumber
                                  : "N/A"}
                              </div>
                            </div>
                            <p
                              className="iconAndDetail"
                              style={{ alignItems: "center" }}
                            >
                              <span>Property Type:</span>
                              <b className="show-Residential">
                                {data?.systemOwnerType || "N/A"}
                              </b>
                            </p>
                          </div>
                          {viewActionsPanel && (
                            <div className="invite-model-backdrop background-viewbg">
                              <div
                                className="delete-model-content actionpopup-height"
                                style={{ height: "465px" }}
                              >
                                <div className="row">
                                  <h3 className="popupblue-text">
                                    {data?.jobIdentifyId} - Action
                                    <button
                                      className="invite-model-close-btn"
                                      onClick={() => {
                                        localStorage.removeItem("jobId");
                                        setViewActionsPanel(false);
                                      }}
                                    >
                                      ✕
                                    </button>
                                  </h3>
                                  <span className="bottom-border-popup"></span>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/document-required"
                                          );
                                        }}
                                      >
                                        <Download sx={{ marginRight: "2px" }} />{" "}
                                        Documents
                                      </div>
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate("/dashboard/upload-files");
                                        }}
                                      >
                                        Upload Files
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          window.open(
                                            "dash/written-statement",
                                            "_blank"
                                          );
                                        }}
                                      >
                                        View Written Statement
                                      </div>
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/important-actions"
                                          );
                                        }}
                                      >
                                        Important Pending Actions
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div
                                        className="main-square"
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/view-site-inspection"
                                          );
                                        }}
                                      >
                                        View Site Inspection
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                          {showSubmitConfirmation && (
                            <div className="modal-backdrop">
                              <div
                                className="modal-centered"
                                style={{ height: "160px" }}
                              >
                                <h2>Are you sure you want to submit?</h2>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="button-invite"
                                    onClick={() => {
                                      handleButtonClick(10);
                                      setShowSubmitConfirmation(false);
                                    }}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => {
                                      localStorage.removeItem("jobId");
                                      setShowSubmitConfirmation(false);
                                    }}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {showDeleteConfirmation && (
                            <div className="modal-backdrop">
                              <div
                                className="modal-centered"
                                style={{ height: "160px" }}
                              >
                                <h2>Are you sure you want to delete?</h2>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    className="btn-cancel"
                                    onClick={handleDelete}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="button-invite"
                                    onClick={() => {
                                      setShowDeleteConfirmation(false);
                                      localStorage.removeItem("jobId");
                                    }}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="main-stc text-stc">
                            <span className="stc-count">STCs </span>
                            <span className="stc-count">
                              {data?.hotWaterDetail?.stc || "N/A"}
                            </span>
                            <span className="stc-text">
                              STC Amount:&nbsp;&nbsp;
                              {data?.hotWaterDetail?.financialPayment
                                ? `$${data?.hotWaterDetail?.financialPayment}`
                                : "N/A"}
                            </span>
                            {data?.statusCode === 3 ||
                            data?.statusCode === 6 ||
                            data?.statusCode === 11 ||
                            data?.statusCode === 13 ||
                            data?.statusCode === 14 ? (
                              <span className="stc-text">
                                Status:&nbsp;&nbsp;
                                {data?.statusDescription &&
                                  data.statusDescription.replace(
                                    /\b\w/g,
                                    (char) => char.toUpperCase()
                                  )}
                              </span>
                            ) : null}
                          </div>
                          <div className="system-info">
                            <ul className="system-all">
                              <li className="system-img buttonBox ">
                                <button
                                  type="button"
                                  className="traderDashButtons button"
                                  style={{ background: "#BD0000" }}
                                  // onClick={() => {
                                  //   localStorage.setItem("jobId", data?._id);
                                  //   window.open("/createSHWJob", "_blank");
                                  // }}
                                  onClick={() => {
                                    handleEditJob(data?._id);
                                  }}
                                >
                                  Edit Job
                                </button>
                              </li>
                              <li className="system-battery buttonBox ">
                                <button
                                  type="button"
                                  className="traderDashButtons "
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    window.open(
                                      "/dashboard-SWH/unsigned-stc",
                                      "_blank"
                                    );
                                  }}
                                >
                                  STC Form
                                </button>
                              </li>
                              <li className="system-img buttonBox">
                                {data?.statusCode === 7 ? (
                                  <button
                                    type="button"
                                    className="traderDashButtons"
                                    onClick={() => {
                                      localStorage.setItem("jobId", data?._id);
                                      handleButtonClick(3);
                                    }}
                                  >
                                    Unpark Job
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="traderDashButtons"
                                    onClick={() => {
                                      localStorage.setItem("jobId", data?._id);
                                      handleButtonClick(7);
                                    }}
                                  >
                                    Park Job
                                  </button>
                                )}
                              </li>
                              <li className="system-img buttonBox">
                                {userType === "Trader" ? (
                                  <button
                                    type="button"
                                    className="traderDashButtons button"
                                    onClick={() =>
                                      handleExport("CSV", data?._id)
                                    }
                                    disabled={data?.statusCode === 3}
                                  >
                                    <Download
                                      fontSize="small"
                                      sx={{ marginRight: "4px" }}
                                    />
                                    CSV
                                  </button>
                                ) : null}
                              </li>
                              {/* <li className="system-img buttonBox">
                              <button
                                type="button"
                                className="traderDashButtons"
                                onClick={() => {
                                  if (
                                    !(
                                      data?.installationAddress?.state ===
                                        "QLD" ||
                                      data?.installationAddress?.state === "NSW"
                                    )
                                  ) {
                                    window.alert(
                                      "Compliance is only available for Queensland and New South Wales."
                                    );
                                  } else {
                                    localStorage.setItem("jobId", data?._id);
                                    window.open(
                                      "/compliance-certificate",
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                Compliance
                              </button>
                            </li> */}
                              {/* <li className="system-battery buttonBox">
                              {userType === "Trader" ? (
                                <button
                                  type="button"
                                  className="traderDashButtons"
                                  onClick={() => {
                                    handleDownloadButtons("CustCerti");
                                  }}
                                  disabled={data?.statusCode === 3}
                                  style={{ display: "none" }}
                                >
                                  Customer Certificate
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="traderDashButtons"
                                  style={{ display: "none" }}
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    navigate("/dashboard/site-inspection");
                                  }}
                                  disabled={data?.statusCode === 7}
                                >
                                  Site Inspection
                                </button>
                              )}
                            </li> */}
                              {/* {data?.statusCode === 7 ? (
                              <li className="system-img buttonBox">
                                <button
                                  type="button"
                                  className="traderDashButtons"
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    handleButtonClick(3);
                                  }}
                                >
                                  Unpark Job
                                </button>
                              </li>
                            ) : (
                              <>
                                <li className="system-img buttonBox">
                                  {userType === "Trader" ? (
                                    <button
                                      type="button"
                                      className="traderDashButtons button"
                                      onClick={() =>
                                        handleExport("CSV", data?._id)
                                      }
                                      disabled={data?.statusCode === 3}
                                    >
                                      {data?.isSPV ? (
                                        <>
                                          <Download
                                            fontSize="small"
                                            sx={{ marginRight: "4px" }}
                                          />
                                          CSVX
                                        </>
                                      ) : (
                                        <>
                                          <Download
                                            fontSize="small"
                                            sx={{ marginRight: "4px" }}
                                          />
                                          CSV
                                        </>
                                      )}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="traderDashButtons"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "jobId",
                                          data?._id
                                        );
                                        handleButtonClick(7);
                                      }}
                                    >
                                      Park Job
                                    </button>
                                  )}
                                </li>
                              </>
                            )} */}
                              {/* <li className="system-img buttonBox">
                              {userType === "Trader" ? (
                                <button
                                  type="button"
                                  className="traderDashButtons button"
                                  onClick={() =>
                                    handleDownloadButtons("SRES-CSV")
                                  }
                                  disabled={data?.statusCode === 3}
                                  style={{ display: "none" }}
                                >
                                  {data?.isSPV ? (
                                    <>
                                      <Download
                                        fontSize="small"
                                        sx={{ marginRight: "4px" }}
                                      />{" "}
                                      SRES CSVX
                                    </>
                                  ) : (
                                    <>
                                      <Download
                                        fontSize="small"
                                        sx={{ marginRight: "4px" }}
                                      />
                                      SRES CSV
                                    </>
                                  )}
                                </button>
                              ) : (
                                <>
                                  {data?.siteInspection === true ? (
                                    <button
                                      type="button"
                                      className="traderDashButtons"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "jobId",
                                          data?._id
                                        );
                                        navigate(
                                          "/dashboard/view-site-inspection"
                                        );
                                      }}
                                      disabled={data?.statusCode === 7}
                                    >
                                      View Site Inspection
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="traderDashButtons"
                                      onClick={() => {
                                        window.open(
                                          "dash/written-statement",
                                          "_blank"
                                        );
                                      }}
                                      disabled={data?.statusCode === 7}
                                      style={{ display: "none" }}
                                    >
                                      Retailer Declaration
                                    </button>
                                  )}
                                </>
                              )}
                            </li> */}
                              {/* {userType === "Trader" ? (
                              <li className="system-img buttonBox">
                                <button
                                  type="button"
                                  className="traderDashButtons button XMLButton"
                                  onClick={() =>
                                    data?.isSPV
                                      ? handleExport("XML", data?._id)
                                      : handleGenerateButton(data)
                                  }
                                  disabled={data?.statusCode === 3}
                                  style={{ display: "none" }}
                                >
                                  {data?.isSPV ? (
                                    <Badge
                                      badgeContent={data?.countXML}
                                      color="success"
                                      style={{
                                        display: "flex",
                                        marginTop: "6px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#fff",
                                          marginRight: "6px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <Download sx={{ marginRight: "2px" }} />{" "}
                                        XML
                                      </span>
                                    </Badge>
                                  ) : (
                                    `Generate SDP`
                                  )}
                                </button>
                              </li>
                            ) : null} */}
                            </ul>
                          </div>
                        </div>
                        <div>
                          <ul className="system-detail">
                            <li className="system-img panel-details">
                              <div class="text-no">
                                {data?.hotWaterDetail?.hotWaterInfo
                                  ?.hotWaterModel &&
                                data?.hotWaterDetail?.hotWaterInfo
                                  ?.hotWaterCapacity ? (
                                  <p>
                                    {data?.hotWaterDetail?.hotWaterBrand}{" "}
                                    {
                                      data?.hotWaterDetail?.hotWaterInfo
                                        ?.hotWaterModel
                                    }{" "}
                                    {} -{" "}
                                    <b>
                                      {"("}
                                      {
                                        data?.hotWaterDetail?.hotWaterInfo
                                          ?.hotWaterCapacity
                                      }
                                      {")"}
                                    </b>
                                  </p>
                                ) : (
                                  <p> Water Heater System Details N/A</p>
                                )}
                              </div>
                            </li>
                            {/* <li className="system-img panel-details">
                          {data?.inverterDetail?.inverterInfo &&
                            data.inverterDetail.inverterInfo.map(
                              (inverter, index) => (
                                <div key={index} class="text-no">
                                  {inverter.inverterModel &&
                                  inverter.Quantity ? (
                                    <p>
                                      {data.inverterDetail.inverterBrand}{" "}
                                      {inverter.inverterModel} X{" "}
                                      {inverter.Quantity}
                                    </p>
                                  ) : (
                                    <p> Inverter Details N/A</p>
                                  )}
                                </div>
                              )
                            )}
                        </li> */}
                          </ul>
                        </div>
                      </div>

                      <div className="jobCardRightSection">
                        <div className="all-person">
                          <ul
                            className="popupsystem-all"
                            style={{ justifyContent: "center" }}
                          >
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardSWH/Heater-tank-blue.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Tank Scan Status
                                </span>
                              </a>
                              {/* <p className="house-no">
                            {data?.panelsUploadedCount}
                          </p> */}
                              {data?.status[0]?.tankScanStatus === 1 ? (
                                <img
                                  className="tick-mark"
                                  style={{ top: "-60px" }}
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardSWH/certificate-blue.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Plumbing Certificate Status
                                </span>
                              </a>

                              {data?.status[0]?.plumbingCertificateStatus ===
                              1 ? (
                                <img
                                  className="tick-panel"
                                  style={{ top: "-60px" }}
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            {data?.status[0]?.electricalComplianceStatus ===
                            1 ? (
                              <li
                                className="popup-person"
                                onMouseOver={showTooltip}
                                onMouseOut={hideTooltip}
                                onClick={toggleTooltip}
                              >
                                <a className="house-img">
                                  <img
                                    src={`${image}/Assets/DashboardSWH/shield-blue.svg`}
                                    alt=""
                                  />
                                  <span className="tooltip">
                                    Compliance Certificate Status
                                  </span>
                                </a>

                                {data?.status[0]?.electricalComplianceStatus ===
                                1 ? (
                                  <img
                                    className="tick-right"
                                    style={{ top: "-60px" }}
                                    src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                    alt=""
                                  />
                                ) : null}
                              </li>
                            ) : null}

                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/installer-person.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Plumber Signature Status
                                </span>
                              </a>

                              {data?.status[0]?.installerDeclarationStatus ===
                              1 ? (
                                <img
                                  className="tick-right"
                                  style={{ top: "-60px" }}
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/ele-man.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Customer Signature Status
                                </span>
                              </a>

                              {data?.status[0]?.CustomerSignStatus === 1 ? (
                                <img
                                  className="tick-right"
                                  style={{ top: "-60px" }}
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                            <li
                              className="popup-person"
                              onMouseOver={showTooltip}
                              onMouseOut={hideTooltip}
                              onClick={toggleTooltip}
                              style={{ display: "none" }}
                            >
                              <a className="house-img">
                                <img
                                  src={`${image}/Assets/DashboardPV/ele-man.svg`}
                                  alt=""
                                />
                                <span className="tooltip">
                                  Retailer Signature Status
                                </span>
                              </a>

                              {data?.status[0]?.RetailerSignStatus === 1 ? (
                                <img
                                  className="tick-right"
                                  style={{ top: "-60px" }}
                                  src={`${image}/Assets/DashboardPV/tick-white.svg`}
                                  alt=""
                                />
                              ) : null}
                            </li>
                          </ul>
                          {/* <ul className="teampopup-all">
                        <li
                          className="popup-person"
                          onMouseOver={showTooltip}
                          onMouseOut={hideTooltip}
                          onClick={toggleTooltip}
                        >
                          <a href="#" className="house-img">
                            <img
                              src={`${image}/Assets/DashboardPV/action.svg`}
                              alt=""
                            />
                            <span className="tooltip">
                              Compliance Upload Status
                            </span>
                          </a>
                          {data?.status[0]?.documentsUploadStatus === 1 ? (
                            <img
                              className="tick-action"
                              src={`${image}/Assets/DashboardPV/tick-white.svg`}
                              alt=""
                            />
                          ) : null}
                        </li>
                        <li
                          className="popup-person"
                          onMouseOver={showTooltip}
                          onMouseOut={hideTooltip}
                          onClick={toggleTooltip}
                        >
                          <a className="house-img">
                            <img
                              src={`${image}/Assets/DashboardPV/person-main.svg`}
                              alt=""
                            />
                            <span className="tooltip">
                              Photos Upload Status
                            </span>
                          </a>
                          {data?.status[0]?.photosStatus === 1 ? (
                            <img
                              className="tick-panel"
                              src={`${image}/Assets/DashboardPV/tick-white.svg`}
                              alt=""
                            />
                          ) : null}
                        </li>
                        <li
                          className="popup-person"
                          onMouseOver={showTooltip}
                          onMouseOut={hideTooltip}
                          onClick={toggleTooltip}
                        >
                          <a className="house-img">
                            <img
                              src={`${image}/Assets/DashboardPV/start-selfi.svg`}
                              alt=""
                            />
                            <span className="tooltip">Start Selfie Status</span>
                          </a>

                          {data?.status[0]?.installerStartSelfie === 1 ? (
                            <img
                              className="tick-right"
                              src={`${image}/Assets/DashboardPV/tick-white.svg`}
                              alt=""
                            />
                          ) : null}
                        </li>
                        <li
                          className="popup-person"
                          onMouseOver={showTooltip}
                          onMouseOut={hideTooltip}
                          onClick={toggleTooltip}
                        >
                          <a className="house-img">
                            <img
                              src={`${image}/Assets/DashboardPV/middle-selfi.svg`}
                              alt=""
                            />
                            <span className="tooltip">
                              Middle Selfie Status
                            </span>
                          </a>

                          {data?.status[0]?.installerMiddleSelfie === 1 ? (
                            <img
                              className="tick-right"
                              src={`${image}/Assets/DashboardPV/tick-white.svg`}
                              alt=""
                            />
                          ) : null}
                        </li>
                        <li
                          className="popup-person"
                          onMouseOver={showTooltip}
                          onMouseOut={hideTooltip}
                          onClick={toggleTooltip}
                        >
                          <a className="house-img">
                            <img
                              src={`${image}/Assets/DashboardPV/end-selfi.svg`}
                              alt=""
                            />
                            <span className="tooltip">End Selfie Status</span>
                          </a>

                          {data?.status[0]?.installerEndSelfie === 1 ? (
                            <img
                              className="tick-right"
                              src={`${image}/Assets/DashboardPV/tick-white.svg`}
                              alt=""
                            />
                          ) : null}
                        </li>
                        <li
                          className="popup-person"
                          onMouseOver={showTooltip}
                          onMouseOut={hideTooltip}
                          onClick={toggleTooltip}
                        >
                          <a className="house-img">
                            <img
                              src={`${image}/Assets/DashboardPV/ele-man.svg`}
                              alt=""
                            />
                            <span className="tooltip">
                              Retailer Signature Status
                            </span>
                          </a>

                          {data?.status[0]?.RetailerSignStatus === 1 ? (
                            <img
                              className="tick-right"
                              src={`${image}/Assets/DashboardPV/tick-white.svg`}
                              alt=""
                            />
                          ) : null}
                        </li>
                      </ul> */}
                        </div>
                        {userType != "Trader" ? (
                          <ul className="system-all buttonContainer">
                            {activeFilter !== 17 ? (
                              <li className="system-img">
                                <button
                                  className="edit-job"
                                  style={{
                                    display:
                                      data?.statusCode === 10 ? "none" : "flex",
                                  }}
                                  onClick={() => {
                                    if (data?.statusCode !== 6) {
                                      alert(
                                        "Job Not Finished Yet from Plumber Side."
                                      );
                                      return;
                                    }
                                    localStorage.setItem("jobId", data?._id);
                                    setShowSubmitConfirmation(true);
                                  }}
                                  disabled={data?.statusCode === 7}
                                >
                                  Submit Job
                                </button>
                              </li>
                            ) : null}
                          </ul>
                        ) : (
                          <ul className="system-all buttonContainer">
                            <li className="system-img">
                              <button
                                className="edit-job"
                                onClick={() => {
                                  localStorage.setItem("jobId", data?._id);
                                  handleButtonClick(11);
                                }}
                                style={{
                                  display:
                                    activeFilter === 11 ||
                                    activeFilter === 12 ||
                                    activeFilter === 13 ||
                                    activeFilter === 14
                                      ? "none"
                                      : "block",
                                }}
                              >
                                Submit to CER
                              </button>
                            </li>
                          </ul>
                        )}
                        <ul className="system-all buttonContainer">
                          <li className="system-img">
                            <button
                              className="edit-job"
                              style={{ fontSize: "12px" }}
                              onClick={() => {
                                handleDownloadClick(data);
                              }}
                              // disabled={data?.statusCode === 7}
                            >
                              {jobPackLoadingID === data._id ? (
                                <BeatLoader color="#fff" />
                              ) : (
                                "Job Pack"
                              )}
                            </button>
                          </li>
                        </ul>
                        <ul>
                          <NotesComponent data={data} />
                        </ul>
                        {userType != "Trader" ? (
                          <ul className="system-all buttonContainer">
                            {activeFilter !== 17 ? (
                              <li className="system-img">
                                <button
                                  type="button"
                                  className="edit-job"
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    {
                                      data?.statusCode === 9
                                        ? setShowDeleteConfirmation(true)
                                        : handleButtonClick(9);
                                    }
                                  }}
                                  disabled={data?.statusCode === 7}
                                >
                                  {data?.statusCode === 9 ? "Delete" : "Cancel"}{" "}
                                  Job
                                </button>
                              </li>
                            ) : null}

                            {data?.statusCode === 6 ? (
                              <li className="system-img">
                                <button
                                  type="button"
                                  className="edit-job"
                                  onClick={() => {
                                    localStorage.setItem("jobId", data?._id);
                                    handleButtonClick(3);
                                  }}
                                >
                                  Revisit Site
                                </button>
                              </li>
                            ) : null}
                          </ul>
                        ) : null}

                        {userType !== "Trader" && data?.statusCode === 9 ? (
                          <ul className="system-all buttonContainer">
                            <li className="system-img">
                              <button
                                type="button"
                                className="edit-job"
                                onClick={() => {
                                  localStorage.setItem("jobId", data?._id);
                                  handleButtonClick(3);
                                }}
                              >
                                Resume Job
                              </button>
                            </li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                    <span className="dash-border-bottom"></span>
                  </div>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>No Jobs Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Chat userType={userType} />
    </div>
  );
};

export default DashboardHW;
