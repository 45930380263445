import React, { useState, useEffect } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const RetailerProfile = ({ onClose }) => {
  const token = localStorage.getItem("userToken");
  const id = localStorage.getItem("retailerId");

  const [retailerData, setRetailerData] = useState();
  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/traders/retailers/${id}`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp.data;
        setRetailerData(data.retailer);
      } catch (err) {
        console.log("Error:", err);
      }
    };
    getData();
  }, []);

  return (
    <>
      <div className="profileview-model-backdrop">
        <div className="profileview-model-content" style={{ height: "845px" }}>
          <div className="profile-model-header">
            <h3 style={{ display: "flex", gap: "10px" }}>
              {retailerData?.basicInformation?.firstName}{" "}
              {retailerData?.basicInformation?.lastName}{" "}
              <span> {retailerData?.basicInformation?.role} </span>
            </h3>
            <button
              className="invite-model-close-btn"
              onClick={() => {
                localStorage.removeItem("retailerId");
                onClose();
              }}
            >
              ✕
            </button>
          </div>
          <div className="row">
            <span className="viewbottom-border"></span>
          </div>
          <div className="row">
            <div style={{ display: "flex" }}>
              <div style={{ flexDirection: "column" }}>
                <div className="right-profile-details">
                  <img
                    src={`${image}/Assets/Teamdashboard/Location.svg`}
                    alt=""
                  />
                  <span className="profile-addresss">
                    {retailerData?.companyInformation?.address?.unitType &&
                      `${retailerData?.companyInformation?.address?.unitType} `}
                    {retailerData?.companyInformation?.address?.unitNo &&
                      `${retailerData?.companyInformation?.address?.unitNo}, `}
                    {retailerData?.companyInformation?.address?.streetNo &&
                      `${retailerData?.companyInformation?.address?.streetNo} `}
                    {retailerData?.companyInformation?.address?.streetName &&
                      `${retailerData?.companyInformation?.address?.streetName} `}
                    {retailerData?.companyInformation?.address?.streetType &&
                      `${retailerData?.companyInformation?.address?.streetType}, `}
                    {retailerData?.companyInformation?.address?.suburb &&
                      `${retailerData?.companyInformation?.address?.suburb}, `}
                    {retailerData?.companyInformation?.address?.state &&
                      `${retailerData?.companyInformation?.address?.state}, `}
                    {retailerData?.companyInformation?.address?.postcode &&
                      `${retailerData?.companyInformation?.address?.postcode}`}
                  </span>
                </div>
                <div className="right-profile-details">
                  <img
                    src={`${image}/Assets/Teamdashboard/Phone-receiver.svg`}
                    alt=""
                  />
                  <span className="phone-receiver">
                    {retailerData?.companyInformation?.mobile}
                  </span>
                </div>
                <div className="right-profile-details">
                  <img src={`${image}/Assets/Teamdashboard/Email.svg`} alt="" />
                  <span className="email-detail">
                    {retailerData?.basicInformation?.emailId}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="want-serve">
              <b>Company Information</b>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    color: "rgba(44, 58, 100, 1)",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  Trading Name: {retailerData?.companyInformation?.tradingName}
                </span>
                <span
                  style={{
                    display: "flex",
                    color: "rgba(44, 58, 100, 1)",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  Legal Entity Name:{" "}
                  {retailerData?.companyInformation?.legalEntityName}
                </span>
                <span
                  style={{
                    display: "flex",
                    color: "rgba(44, 58, 100, 1)",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  ABN: {retailerData?.companyInformation?.ABN}
                </span>
              </div>
            </div>
          </div>
          <br />
          <div className="want-serve">
            <b>Bank Information</b>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  display: "flex",
                  marginTop: "5px",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Bank Name: {retailerData?.bankInformation?.bankName}
              </span>
              <span
                style={{
                  display: "flex",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Account Holder Name: {retailerData?.bankInformation?.accName}
              </span>
              <span
                style={{
                  display: "flex",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Account Number: {retailerData?.bankInformation?.accNo}
              </span>
              <span
                style={{
                  display: "flex",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                BSB Number: {retailerData?.bankInformation?.bsbNo}
              </span>
            </div>
          </div>
          <br />
          <div className="want-serve">
            <b>Prices</b>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  display: "flex",
                  marginTop: "5px",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                PV Best Price:{" "}
                {retailerData?.prices?.pv?.bestPrice?.price
                  ? `$${retailerData.prices.pv.bestPrice.price}`
                  : ""}
              </span>
              <span
                style={{
                  display: "flex",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                PV Fast Track Price:{" "}
                {retailerData?.prices?.pv?.fastTrack?.price
                  ? `$${retailerData?.prices?.pv?.fastTrack?.price}`
                  : ""}
              </span>
              <span
                style={{
                  display: "flex",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                SWH Best Price:{" "}
                {retailerData?.prices?.swh?.bestPrice?.price
                  ? `$${retailerData?.prices?.swh?.bestPrice?.price}`
                  : ""}
              </span>
              <span
                style={{
                  display: "flex",
                  color: "rgba(44, 58, 100, 1)",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                SWH Fast Track Price:{" "}
                {retailerData?.prices?.swh?.fastTrack?.price
                  ? `$${retailerData?.prices?.swh?.fastTrack?.price}`
                  : ""}
              </span>
            </div>
          </div>
          <br />

          <div className="row">
            <span className="viewbottom-border"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetailerProfile;
