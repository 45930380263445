import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { usePDF } from "react-to-pdf";
import SignatureCanvas from "react-signature-canvas";
import "./RetailerDeclaration.css";
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
const image = process.env.PUBLIC_URL;
const baseURL = process.env.REACT_APP_API_BASE_URL;

const RetailerWrittenStatement = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  const navigate = useNavigate();

  //Request Body for Post API Starts

  const [requestBody, setRequestBody] = useState({
    jobId: jobId,
    radio1: false,
    radio2: false,
    checkbox: false,
  });

  //Request Body for Post API Ends

  const handleFormInputChange = (event) => {
    const { name, checked } = event.target;

    setRequestBody({
      ...requestBody,
      [name]: checked,
    });
  };
  const [dataRetailer, setDataRetailer] = useState();
  const [jobData, setJobData] = useState();
  const [retailerSignature, setRetailerSignature] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);
  const signatureRef = useRef(null);
  const [editSignature, setEditSignature] = useState(false);
  const [uploadSignature, setUploadSignature] = useState(false);
  const [declarationData, setDeclarationData] = useState([]);

  const fetchData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/users/getDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDataRetailer(resp.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fecthDeclarationData = async () => {
    try {
      const resp = await axios.get(
        `${baseURL}/RetailerDeclaration/get/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRequestBody({
        ...requestBody,
        radio1: resp?.data?.radio1,
        radio2: resp?.data?.radio2,
        checkbox: resp?.data?.checkbox,
      });
      setDeclarationData(resp.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    fecthDeclarationData();
  }, []);

  const fetchSignatureData = async () => {
    try {
      const resp = await axios.get(`${baseURL}/onSite`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp?.data?.data;
      const flattenedData = data.flat();
      const result = flattenedData.filter((item) => item.jobId === jobId);
      const signature = result[0]?.signatures[0]?.retailerSignature?.url;
      signature ? setRetailerSignature(signature) : setRetailerSignature("");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchSignatureData();
  }, []);

  // Fetching Data Logic:

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/job/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp?.data;
        const flattenedData = data.flat();
        const result = flattenedData.filter((item) => item._id === jobId);
        setJobData(result[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [selectedFile, setSelectedFile] = useState();
  // Function to convert signature canvas to File and send in API
  const convertSignatureToFile = () => {
    const canvas = signatureRef?.current?.getTrimmedCanvas();
    if (canvas || selectedFile) {
      if (canvas) {
        canvas.toBlob(async (blob) => {
          if (blob) {
            const formData = new FormData();
            formData.append("retailerSignature", blob);
            formData.append("jobId", jobId);
            try {
              const response = await axios.post(
                `${baseURL}/update/signature`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (response.status === 200 || response.status === 201) {
                alert("Retailer Signature Uploaded Successfully");
                navigate("/dashboard");
                RetailerDeclarationUpdate();
              } else {
                console.log("Error Sending Signature");
              }
            } catch (error) {
              alert(error?.response?.data?.message);
            }
          }
        });
      } else {
        uploadFile();
      }
    }
    RetailerDeclarationUpdate();
  };

  // PDF Logic:
  const { toPDF, targetRef } = usePDF({ filename: "Retailer Declaration.pdf" });

  const handleDownloadPDF = async () => {
    convertSignatureToFile();
    await toPDF();
  };

  const handleFormChange = () => {
    const isOwnerSelected =
      document.querySelector('input[name="owner"]:checked') !== null;
    const isPerformanceSelected =
      document.querySelector('input[name="perform"]:checked') !== null;
    const isCheckboxChecked = document.querySelector(
      'input[type="checkbox"]'
    ).checked;
    const isSignatureProvided = signatureRef.current?.isEmpty() === false;

    setIsFormComplete(
      isOwnerSelected &&
        isPerformanceSelected &&
        isCheckboxChecked &&
        isSignatureProvided
    );
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const uploadFile = async () => {
    try {
      if (!selectedFile) {
        alert("Please select a file.");
        return;
      }
      const formData = new FormData();
      formData.append("retailerSignature", selectedFile);
      formData.append("jobId", jobId);
      const response = await axios.post(
        `${baseURL}/update/signature`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Signature Uploaded Successfully");
        fetchSignatureData();
        fetchData();
        window.location.reload();
      } else {
        console.error("Error Occured");
      }
      setUploadSignature(false);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const RetailerDeclarationUpdate = async () => {
    try {
      // const requestBody = {
      //   jobId: jobId,
      //   radio1: radioButton1,
      //   radio2: radioButton2,
      //   checkbox: ischeckBox,
      // };
      const response = await axios.post(
        `${baseURL}/RetailerDeclaration/add`,
        requestBody
      );
      if (response.status === 200 || response.status === 201) {
        alert("Retailer Declaration Data Saved");
      } else {
        console.error("Error occured");
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  // Function to open the browser's print dialog
  const openPrintDialog = () => {
    window.print();
  };
  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    // const signatureImage = signatureRef.current.toDataURL();
    const canvas = signatureRef?.current?.getTrimmedCanvas();
    if (canvas || selectedFile) {
      if (canvas) {
        canvas.toBlob(async (blob) => {
          if (blob) {
            const formData = new FormData();
            formData.append("retailerSignature", blob);
            formData.append("jobId", jobId);
            try {
              const response = await axios.post(
                `${baseURL}/update/signature`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (response.status === 200 || response.status === 201) {
                alert("Retailer Signature Uploaded Successfully");
                navigate("/dashboard");
                RetailerDeclarationUpdate();
              } else {
                console.log("Error Sending Signature");
              }
            } catch (error) {
              alert(error?.response?.data?.message);
            }
          }
        });
      } else {
        uploadFile();
      }
    }
    RetailerDeclarationUpdate();
  };
  return (
    <div className="retailer-wrapper">
      <div className="declaration" ref={targetRef}>
        <h1>Retailer Declaration & Written Statement</h1>
        <p>
          I,{" "}
          <b>{`${dataRetailer?.basicInformation?.firstName} ${dataRetailer?.basicInformation?.lastName}`}</b>{" "}
          am the authorised representative of{" "}
          <b>{`${dataRetailer?.companyInformation?.tradingName} (ABN: ${dataRetailer?.companyInformation?.ABN})`}</b>{" "}
          that sold the solar PV system to{" "}
          <b>{`${jobData?.ownerDetails?.firstName} ${jobData?.ownerDetails?.lastName}`}</b>{" "}
          and verify that:
        </p>
        <ul>
          <li>
            installed the unit at,{" "}
            <b>
              {jobData?.installationAddress?.unitType &&
                `${jobData?.installationAddress?.unitType} `}
              {jobData?.installationAddress?.unitNumber &&
                `${jobData?.installationAddress?.unitNumber}, `}
              {jobData?.installationAddress?.streetNumber &&
                `${jobData?.installationAddress?.streetNumber} `}
              {jobData?.installationAddress?.streetName &&
                `${jobData?.installationAddress?.streetName} `}
              {jobData?.installationAddress?.streetType &&
                `${jobData?.installationAddress?.streetType}, `}
              {jobData?.installationAddress?.suburb &&
                `${jobData?.installationAddress?.suburb}, `}
              {jobData?.installationAddress?.state &&
                `${jobData?.installationAddress?.state}, `}
              {jobData?.installationAddress?.postCode &&
                `${jobData?.installationAddress?.postCode}`}{" "}
            </b>
            and they are
            <ul
              className="innerList "
              style={{
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <li>
                <input
                  type="radio"
                  name="radio1"
                  id="employee"
                  onChange={handleFormInputChange}
                  checked={requestBody?.radio1 || null}
                />
                <label for="employee">
                  <i>An employee</i> or
                </label>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  name="radio1"
                  id="subcontractor"
                  onChange={handleFormInputChange}
                  checked={!requestBody?.radio1 || null}
                />
                <label for="subcontractor">
                  <i>Subcontractor</i>
                </label>
              </li>
            </ul>
            of <b>{dataRetailer?.companyInformation?.tradingName}</b>
          </li>
          <li>
            that the unit will perform in accordance with the
            <ul
              className="innerList "
              style={{
                listStyleType: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <li>
                <input
                  type="radio"
                  name="radio2"
                  id="contract"
                  onChange={handleFormInputChange}
                  checked={requestBody?.radio2 || null}
                />
                <label for="contract">
                  <i>Contract</i> or
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  name="radio2"
                  id="quote"
                  onChange={handleFormInputChange}
                  checked={!requestBody?.radio2 || null}
                />
                <label for="quote">
                  <i>Quote accepted</i>
                </label>
              </li>
            </ul>
            for the sale of the unit to the owner of the unit, except to the
            extent that the performance is prevented by circumstances outside
            the solar retailer's control.
          </li>
          <li>
            that the unit is{" "}
            <i>
              complete and generating electricity or capable of generating
              electricity
            </i>
          </li>
          <li>
            that if{" "}
            <i>
              the unit is grid connected, the unit is connected to the grid{" "}
            </i>
            or <b>{dataRetailer?.companyInformation?.tradingName}</b>{" "}
            <i>
              has completed its obligations under the contract (or quote
              accepted) relating to the connection of the unit to the grid
            </i>
          </li>
          <li>
            <b>{dataRetailer?.companyInformation?.tradingName}</b> provided
            information in writing to{" "}
            <b>
              {jobData?.ownerDetails?.firstName}{" "}
              {jobData?.ownerDetails?.lastName}
            </b>{" "}
            about feed in tariffs and export limits for the unit and it is true,
            correct and complete.
          </li>
          <li>
            <b>{dataRetailer?.companyInformation?.tradingName}</b> provided
            information in writing to the owner of the unit about one or more of
            the following of the unit, <i>expected payback period</i> or{" "}
            <i>expected energy savings</i> or <i>expected cost savings</i> and
            that information is true, correct and complete.
          </li>
          <li>
            any actual or potential conflicts of interest of{" "}
            <b>{dataRetailer?.companyInformation?.tradingName}</b> relating to
            the sale or installation of the unit, or the creation of
            certificates for the unit, including any conflicts of interest in
            relation to persons or entities related to{" "}
            <b>{dataRetailer?.companyInformation?.tradingName}</b>, have been
            disclosed to{" "}
            <b>
              {jobData?.customerDetails?.firstName}{" "}
              {jobData?.customerDetails?.lastName}
            </b>{" "}
            and managed appropriately.
            <br />a declaration deeming{" "}
            <b>{dataRetailer?.companyInformation?.tradingName}</b> ineligible to
            make statement under regulation 20AH is not in effect on the day the
            statement is given.
          </li>
        </ul>
        <br />
        <div>
          <input
            type="checkbox"
            name="checkbox"
            onChange={handleFormInputChange}
            checked={requestBody?.checkbox || null}
          />
          <label style={{ fontSize: "13px" }}>
            {" "}
            I declare that the above statements are true, correct and complete
            and understand that penalties apply under the{" "}
            <i>Renewable Energy (Electricity) Act 2000</i> and Renewable Energy
            (Electricity) Regulations 2001 for providing false or misleading
            information. I understand that giving false or misleading
            information is a serious offence under the Criminal Code Act 1995.
          </label>
        </div>
        <br />
        <p>
          I (<b>{dataRetailer?.companyInformation?.tradingName}</b>) am aware
          that penalties can be applied for providing misleading information in
          this form under the Renewable Energy (Electricity) Act 2000. I
          understand that if this assignment is unable to be created, unable to
          be approved or becomes invalid at any point in the future, I will
          reimburse <b>UVEAA AUSTRALIA PTY LTD</b> for the full value of money
          paid to me.
        </p>
        <br />
        <br />
        <div className="signature">
          {retailerSignature === "" || editSignature ? (
            <>
              <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{
                  width: 400,
                  height: 150,
                  className: "sigCanvas",
                  
                }}
                onEnd={handleFormChange}
                
              />
              <p>Retailer's Signature</p>
              {/* {isFormComplete?( */}
                <div style={{display:"flex",gap:'10px'}}>
                <button onClick={clearSignature} style={{fontSize:"12px",borderRadius:'15px',backgroundColor:"rgb(21 67 176)",color:"#fff",border:'none',padding:"4px 6px"}}>Clear Signature</button>
                <button onClick={saveSignature} style={{fontSize:"12px",borderRadius:'15px',backgroundColor:"rgb(21 67 176)",color:"#fff",border:'none',padding:"4px 6px"}}>Save Signature</button>
                </div>

              {/* ):null} */}
              <button
                type="button"
                className="btn-uploadsignature"
                onClick={() => setUploadSignature(true)}
              >
                Upload Signature
              </button>
              {editSignature ?(
                <button onClick={() => setEditSignature(!editSignature)}>
                Cancel Edit
              </button>
              ):null}
              
            </>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <>
                <img
                  src={retailerSignature}
                  alt="Retailer Signature"
                  style={{ width: "100px" }}
                />
                <div
                  style={{
                    position: "relative",
                    right: 0,
                    width: "100%",
                    top: "0",
                  }}
                >
                  <Tooltip title="Edit Signature">
                    <Edit onClick={() => setEditSignature(!editSignature)} />
                  </Tooltip>
                </div>
              </>
            </div>
          )}
        
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          // onClick={handleDownloadPDF}
          onClick={openPrintDialog}
          disabled={isFormComplete}
        >
          Download
        </button>
      </div>
      <div className="row">
        {uploadSignature && (
          <div className="invite-model-backdrop">
            <div className="delete-model-content uploadsignature-popup-height">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 className="text-panel">Upload Retailer Signature</h2>
                <button
                  type="button"
                  class="onsitedata-model-close-btn"
                  onClick={() => setUploadSignature(false)}
                  style={{ padding: "0" }}
                >
                  ✕
                </button>
              </div>
              <span className="addphoto-bottom-border"></span>

              <div className="row">
                <form method="post">
                  <div class="retailer-main doc-upload">
                    <input
                      class="photoupload-input"
                      type="file"
                      placeholder="Upload Photo"
                      accept="image/png, image/jpeg, application/pdf"
                      name="customerSignature"
                      onChange={handleFileChange}
                    />
                    <span className="file-text">
                      (JPG Max 50KB, PNG Max 100KB)
                    </span>
                    <img
                      src={`${image}/Assets/OnSiteData/Signature/uploadphoto.svg`}
                      alt=""
                      className="photoupload"
                    />
                  </div>
                </form>
              </div>

              <span className="addphoto-bottom-border"></span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="onsitebtn-save"
                  type="button"
                  onClick={uploadFile}
                >
                  Save
                </button>
                <button
                  className="onsitebtn-cancel"
                  type="button"
                  onClick={() => setUploadSignature(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RetailerWrittenStatement;
