import React, { useEffect, useState, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import Aside from "../FixedBar/Aside";
import axios from "axios";
import RatingData from "../SystemDetails/Zone Ratings.json";
import ManageFolder from "./CommonComponents/ManageFolder/ManageFolder";
import DocumentRequired from "./DocumentRequired";
import SignaturePage from "./OnSiteData/SignaturePage";
import { CloseRounded, ContentPaste, Edit, Save } from "@mui/icons-material";
import { Button, Fade, Tooltip } from "@mui/material";
import ProfileInstallationTeam from "../Trader/Teamdashboard/ProfileInstallationTeam";
import moment from "moment/moment";
import HeaderComponent from "./CommonComponents/HeaderComponent";
import { FadeLoader } from "react-spinners";
import ImageModal from "../Dashboard/ImageModal";
import { format } from "date-fns";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;
const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;

const CreatePVJob = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");
  const retailerId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");

  //All Dropdowns starts
  const [streetOptions, setStreetOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [installingForOptions, setInstallingForOptions] = useState([]);
  const [mountingTypeOptions, setMountingTypeOptions] = useState([]);
  const [systemTypeOptions, setSystemTypeOptions] = useState([]);
  const [addressTypeOptions, setAddressTypeOptions] = useState([]);
  const [roofTypeOptions, setRoofTypeOptions] = useState([]);
  const [storeyTypeOptions, setStoreyTypeOptions] = useState([]);
  const [panelTypeOptions, setPanelTypeOptions] = useState([]);
  const [phaseTypeOptions, setPhaseTypeOptions] = useState([]);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const [connectionTypeOptions, setConnectionTypeOptions] = useState([]);
  const [multiplefieldsBattery, setMultiplefieldsBattery] = useState(false);
  const [multiplefieldsInverter, setMultipleFieldsInverter] = useState(false);
  const [ismultipleFieldsPanel, setIsMultipleFieldsPanel] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [defaultValue, setDefaultValue] = useState(
    "This installation will be a replacement"
  );
  const [startSelfie, setStartSelfie] = useState(null);
  const [middleSelfie, setMiddleSelfie] = useState(null);
  const [endSelfie, setEndSelfie] = useState(null);
  const [multiplePanelSerial, setMultiplePanelSerial] = useState(null);
  const [manualPanelBrand, setManualPanelBrand] = useState("");
  const [manualPanelModel, setManualPanelModel] = useState("");
  const [manualInverterBrand, setManualInverterBrand] = useState("");
  const [manualInverterModel, setManualInverterModel] = useState("");
  const [manualInverterSeries, setManualInverterSeries] = useState("");
  const [manualBatteryBrand, setManualBatteryBrand] = useState("");
  const [manualBatteryModel, setManualBatteryModel] = useState("");
  const [manualBatterySeries, setManualBatterySeries] = useState("");
  //Panels enter manually Starts
  const [serialNumbers, setSerialNumbers] = useState("");
  const [loadingJobData, setLoadingJobData] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(null);
  const [editedSerialNumbers, setEditedSerialNumbers] =
    useState(multiplePanelSerial);
  const [inverterSerialNumbers, setInverterSerialNumbers] = useState("");
  const [batterySerialNumbers, setbatterySerialNumbers] = useState("");

  const [deleteLoading, setdeleteLoading] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${baseURL}/get/masterData?type=addressType,unitType,streetType,state,installingFor,mountingType,systemType,roof,storey,panelType,phase,connectionType,distributor`
      )
      .then((response) => {
        const master = response.data;
        setAddressTypeOptions(master.data[0].values);
        setConnectionTypeOptions(master.data[1].values);
        setDistributorOptions(master.data[2].values);
        setInstallingForOptions(master.data[3].values);
        setMountingTypeOptions(master.data[4].values);
        setPanelTypeOptions(master.data[5].values);
        setPhaseTypeOptions(master.data[6].values);
        setRoofTypeOptions(master.data[7].values);
        setStateOptions(master.data[8].values);
        setStoreyTypeOptions(master.data[9].values);
        setStreetOptions(master.data[10].values);
        setSystemTypeOptions(master.data[11].values);
        setUnitOptions(master.data[12].values);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [formdata, setFormdata] = useState({
    installationAddress: {
      addressType: "Physical",
      unitType: "",
      unitNumber: null,
      streetNumber: null,
      streetName: "",
      streetType: "",
      suburb: "",
      state: "",
      postCode: null,
      latitude: null,
      longitude: null,
    },

    installationDetails: {
      installationType: "",
      newPanelLocation: "",
      kilowatt: null,
      remarks: "",
      CECAccreditationSystem: true,
      territoryGovermentApproval: true,
      electricalSafetyDoc: true,
      AusNZStandardStatement: true,
    },

    postalAddress: {
      POBox: {
        POBoxNo: null,
        postalCode: null,
        postalArea: "",
      },
      POAddress: {
        addressType: "",
        unitType: "",
        unitNumber: null,
        streetNumber: null,
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: null,
      },
    },

    installingFor: "",
    systemOwnerType: "",

    ownerDetails: {
      firstName: "",
      lastName: "",
      companyName: "",
      mobileNum: "",
      phoneNumber: "",
      emailId: "",
      ABN: null,
      isGST: false,
      gstNo: "",
    },

    coOwnerDetail: {
      firstName: "",
      lastName: "",
      mobileNum: "",
      phoneNumber: "",
      emailId: "",
    },

    installationDate: {
      installingDate: "",
      STCDeemingPeriod: null,
      mountingType: "",
    },

    gridConnectionDetail: {
      connectionType: "",
      systemType: "S.G.U. - Solar (Deemed)",
      nmi: "",
      gridAppReferenceNo: "",
      meterNum: null,
      distributor: "",
    },

    panelDetail: {
      panelInfo: [
        {
          panelBrand: "",
          panelModel: "",
          panelPower: null,
          Quantity: null,
        },
      ],
      wattage: null,
      stc: null,
      financialPayment: null,
      isAssignPanelJob: false,
    },

    inverterDetail: {
      inverterInfo: [
        {
          inverterBrand: "",
          inverterSeries: "",
          inverterModel: "",
          inverterSupplier: "",
          inverterCapacity: null,
          Quantity: null,
        },
      ],
    },

    batteryDetail: {
      batteryInfo: [
        {
          manufacturer: "",
          batteryBrand: "",
          batterySeries: "",
          batteryModel: "",
          batteryCapacity: null,
          Quantity: null,
        },
      ],
      batterySystemAggregate: false,
      defaultManufacturerBatteryStorage: false,
    },

    warrantyDetails: {
      warrantyYear: null,
      warrantyDescription: "",
    },

    instructionForInstallation: "",
    notes: "",

    additionalAttributes: {
      CRMID: "",
      currentEnergyRetailer: "",
      WillThereBeAnyOptimisersInstalled: false,
    },

    siteAttributes: {
      roof: "",
      storey: "",
      panelType: "",
      phase: "",
    },

    failedPV: {
      isItForPreviouslyFailedPV: false,
      accreditationCode: "",
      explanatoryNotes: "",
    },

    design: "",
    customInvoice: "",
    uploadElectricityBill: "",
    STCForm: "",
    STCInvoice: "",
    testingCertificate: "",
    noticeOfCompletion: "",

    installers: [],
    installerName: "",
    designers: [],
    designerName: "",
    electricians: [],
    electricianName: "",
    jobType: "PV",
  });
  //Form Data ends
  //Google GeoCoding API Call Starts
  const [status, setStatus] = useState();
  const [statusCode, setStatusCode] = useState();
  useEffect(() => {
    const getLatLong = async () => {
      try {
        const address = `${formdata?.installationAddress.addressType} ${formdata?.installationAddress.unitType} ${formdata?.installationAddress.unitNumber} ${formdata?.installationAddress.streetNumber} ${formdata?.installationAddress.streetName} ${formdata?.installationAddress.streetType}, ${formdata?.installationAddress.suburb}, ${formdata?.installationAddress.state}, ${formdata?.installationAddress.postCode}`;

        const resp = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&address=${encodeURIComponent(
            address
          )}`
        );
        const data = resp?.data?.results[0];
        const latitude = data?.geometry?.location?.lat;
        const longitude = data?.geometry?.location?.lng;

        setFormdata((prevState) => ({
          ...prevState,
          installationAddress: {
            ...prevState.installationAddress,
            latitude: latitude,
            longitude: longitude,
          },
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getLatLong();
  }, [
    formdata?.installationAddress?.addressType,
    formdata?.installationAddress?.unitType,
    formdata?.installationAddress?.unitNumber,
    formdata?.installationAddress?.streetNumber,
    formdata?.installationAddress?.streetName,
    formdata?.installationAddress?.streetType,
    formdata?.installationAddress?.suburb,
    formdata?.installationAddress?.state,
    formdata?.installationAddress?.postCode,
  ]);
  const fetchJobData = async () => {
    setLoadingJobData(true);
    try {
      const resp = await axios.get(`${baseURL}/job/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const flattenedData = resp.data.flat();
      const result = flattenedData.filter((item) => item._id === jobId);
      setStatus(result[0]?.status[0]);
      setStatusCode(result[0]?.statusCode);
      const deepMerge = (target, source) => {
        Object.keys(target).forEach((key) => {
          if (source[key] !== undefined && source[key] !== null) {
            if (
              typeof target[key] === "object" &&
              typeof source[key] === "object"
            ) {
              if (Array.isArray(target[key]) && Array.isArray(source[key])) {
                if (source[key].length > 0) {
                  target[key] = [...source[key]];
                }
              } else {
                deepMerge(target[key], source[key]);
              }
            } else {
              target[key] = source[key];
            }
          }
        });
        return target;
      };
      const updatedFormData = deepMerge({ ...formdata }, result[0]);
      setFormdata(updatedFormData);

      const date = moment(
        updatedFormData?.installationDate?.installingDate
      ).format("YYYY-MM-DD");
      setFormdata((prev) => ({
        ...prev,
        installationDate: {
          ...prev.installationDate,
          installingDate: date,
        },
      }));

      const coOwnerDetailKeys = Object.keys(updatedFormData?.coOwnerDetail);
      const ownerDetailKeys = Object.keys(updatedFormData?.ownerDetails);

      const areDetailsEqual = coOwnerDetailKeys.every(
        (key) =>
          ownerDetailKeys.includes(key) &&
          updatedFormData?.coOwnerDetail[key] ===
            updatedFormData?.ownerDetails[key]
      );

      if (areDetailsEqual) {
        setIsChecked(true);
      }

      const installationAddressKeys = Object.keys(
        updatedFormData?.installationAddress
      );
      const postalAddressKeys = Object.keys(
        updatedFormData?.postalAddress?.POAddress
      );

      const filteredInstallationKeys = installationAddressKeys.filter(
        (key) =>
          key.toLowerCase() !== "latitude" && key.toLowerCase() !== "longitude"
      );
      const areAddressesEqual = filteredInstallationKeys.every(
        (key) =>
          postalAddressKeys.includes(key) &&
          updatedFormData?.installationAddress[key] ===
            updatedFormData?.postalAddress?.POAddress[key]
      );
      if (areAddressesEqual) {
        setSameAsAboveAddress(true);
      }
      setLoadingJobData(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingJobData(false);
    }
  };
  useEffect(() => {
    fetchJobData();
    fetchMultipleSerialNumbers();
  }, []);

  const [manualButton, setMenualButton] = useState(false);
  const swithingAddress = () => {
    setMenualButton(!manualButton);
  };

  const [address, setAddress] = useState("");

  const handleAddressChange = (value) => {
    setAddress(value);
    setFormdata((prevFormdata) => ({
      ...prevFormdata,
      installationAddress: {
        ...prevFormdata.installationAddress,
        addressType: "Physical",
        unitType: "",
        unitNumber: "",
        streetNumber: "",
        streetName: "",
        streetType: "",
        suburb: "",
        state: "",
        postCode: "",
      },
    }));
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const lat = result[0].geometry.location.lat();
    const lng = result[0].geometry.location.lng();
    setSelectedLocation({
      lat: lat,
      lng: lng,
    });

    setAddress(result[0].formatted_address);
    const addressComponents = result[0].address_components;
    const getComponent = (type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type)
      );

      if (component) {
        return component.short_name || "";
      } else {
        return "";
      }
    };
    const fullStreetName = getComponent("route");
    let streetName, streetType;
    if (fullStreetName.split(" ").length > 2) {
      const words = fullStreetName.split(" ");
      streetType = words.pop();
      streetName = words.join(" ");
    } else {
      [streetName, streetType] = fullStreetName.split(" ", 2);
    }

    const streetNo = getComponent("street_number");
    const suburb =
      getComponent("locality") ||
      getComponent("sublocality") ||
      getComponent("political");
    const state = getComponent("administrative_area_level_1");
    const postcode = getComponent("postal_code");

    setFormdata((prevState) => ({
      ...prevState,
      installationAddress: {
        ...prevState.installationAddress,
        unitNumber: prevState.installationAddress?.unitNumber,
        streetNumber: streetNo,
        streetName: streetName,
        streetType: streetType,
        suburb: suburb,
        state: state,
        postCode: postcode,
      },
    }));
    setAddress("");
    setMenualButton(!manualButton);
  };

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const [manualButtonPostal, setMenualButtonPostal] = useState(false);

  const swithingPostalAddress = () => {
    setMenualButtonPostal(!manualButtonPostal);
  };

  const [postalAddress, SetPostalAddress] = useState("");
  const handlePostalSelect = async (value) => {
    const result = await geocodeByAddress(value);
    SetPostalAddress(result[0].formatted_address);
    const addressComponents = result[0].address_components;

    const getComponent = (type) => {
      const component = addressComponents.find((component) =>
        component.types.includes(type)
      );

      if (component) {
        return component.short_name || "";
      } else {
        return "";
      }
    };

    const fullStreetName = getComponent("route");
    let streetName, streetType;
    if (fullStreetName.split(" ").length > 2) {
      const words = fullStreetName.split(" ");
      streetType = words.pop();
      streetName = words.join(" ");
    } else {
      [streetName, streetType] = fullStreetName.split(" ", 2);
    }

    const streetNo = getComponent("street_number");
    const suburb =
      getComponent("locality") ||
      getComponent("sublocality") ||
      getComponent("political");
    const state = getComponent("administrative_area_level_1");
    const postcode = getComponent("postal_code");

    setFormdata((prevState) => ({
      ...prevState,
      postalAddress: {
        ...prevState.postalAddress,
        POAddress: {
          ...prevState.postalAddress.POAddress,
          unitNumber: prevState.postalAddress.POAddress.unitNumber,
          streetNumber: streetNo,
          streetName: streetName,
          streetType: streetType,
          suburb: suburb,
          state: state,
          postCode: postcode,
        },
      },
    }));
    setMenualButtonPostal(!manualButtonPostal);
  };

  const handleInputsInstallerAddress = (e) => {
    const { name, type, value } = e.target;

    setFormdata((prev) => ({
      ...prev,
      installationAddress: {
        ...prev.installationAddress,
        [name]: type === "number" ? Number(value) : value,
      },
    }));

    if (name === "unitType") {
      setFormdata((prev) => ({
        ...prev,
        installationAddress: {
          ...prev.installationAddress,
          unitNumber: null,
        },
      }));
    }
  };

  const [oldCustomerDetails, setOldCustomerDetails] = useState("");

  const handleInputsInstallationDetailsRadio = (e) => {
    const { name, value } = e.target;
    let remarksValue = "";

    if (value === "Additional" || value === "Extension") {
      setOldCustomerDetails(value);
    } else {
      setOldCustomerDetails(null);
    }

    if (value === "Replacement") {
      setOldCustomerDetails(value);
      remarksValue = "This installation will be a replacement";
    }

    setFormdata((prev) => ({
      ...prev,
      installationDetails: {
        ...prev.installationDetails,
        [name]: value,
        remarks: remarksValue,
      },
    }));
  };

  const handleInputsInstallationDetails = (e) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      installationDetails: {
        ...prev.installationDetails,
        [name]:
          type === "radio"
            ? value === "true"
            : type === "number"
            ? Number(value)
            : value,
      },
    }));
  };

  const [sameAsAboveAddress, setSameAsAboveAddress] = useState(false);

  const handleAddressCheckboxChange = () => {
    setSameAsAboveAddress(!sameAsAboveAddress);
    setpoBox(false);
  };

  useEffect(() => {
    if (sameAsAboveAddress) {
      setpoBoxAdres(true);
      setpoBox(false);
      setMenualButtonPostal(true);
      setFormdata((prevFormData) => ({
        ...prevFormData,
        postalAddress: {
          ...prevFormData.postalAddress,
          POAddress: { ...prevFormData.installationAddress },
        },
      }));
    } else {
      setFormdata((prevFormData) => ({
        ...prevFormData,
        postalAddress: {
          POAddress: {
            street: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
        },
      }));
    }
  }, [sameAsAboveAddress]);

  const [poBox, setpoBox] = useState(false);
  const poBoxclicked = () => {
    setpoBox(!poBox);
    setpoBoxAdres(false);
  };

  const [poBoxAdres, setpoBoxAdres] = useState(true);
  const poBoxAdressclicked = () => {
    setpoBoxAdres(!poBoxAdres);
    setpoBox(false);
  };

  const handleInputsPostalAddressPOBoxPOBoxNo = (e) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      postalAddress: {
        ...prev.postalAddress,
        POBox: {
          ...prev.postalAddress.POBox,
          [name]: type === "number" ? Number(value) : value,
        },
      },
    }));
  };
  const handleInputsPostalAddressPOBoxPOAddress = (e) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      postalAddress: {
        ...prev.postalAddress,
        POAddress: {
          ...prev.postalAddress.POAddress,
          [name]: type === "number" ? Number(value) : value,
        },
      },
    }));
  };

  const handleInputsInstallerPostalAddressDropDown = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      postalAddress: {
        ...prev.postalAddress,
        POAddress: {
          ...prev.postalAddress.POAddress,
          [name]: value,
        },
      },
    }));
  };

const handleInputsInstallingFor = (e) => {
  const { value } = e.target;

  setFormdata((prev) => {
    let updatedOwnerDetails = { ...prev.ownerDetails };

    if (value === "Individual") {
      updatedOwnerDetails = {
        ...updatedOwnerDetails,
        companyName: "", 
        firstName: updatedOwnerDetails.firstName || "", 
        lastName: updatedOwnerDetails.lastName || "", 
      };
    } else {
      updatedOwnerDetails = {
        ...updatedOwnerDetails,
        firstName: "",  
        lastName: "",   
        companyName: updatedOwnerDetails.companyName || "", 
      };
    }

    return {
      ...prev,
      installingFor: value, 
      ownerDetails: updatedOwnerDetails,
    };
  });

  setIsChecked(false);
};



  const handleInputsSystemOwnerType = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputCustomerDetails = (event) => {
    const { name, type, value } = event.target;

    if (name === "phoneNumber") {
      if (value.startsWith("0")) {
        setFormdata({
          ...formdata,
          ownerDetails: {
            ...formdata.ownerDetails,
            phoneNumber: value,
          },
        });
      } else {
        const sanitizedValue = value.replace(/^0+/, "");
        setFormdata({
          ...formdata,
          ownerDetails: {
            ...formdata.ownerDetails,
            phoneNumber: sanitizedValue,
          },
        });
      }
    } else {
      setFormdata((prev) => ({
        ...prev,
        ownerDetails: {
          ...prev.ownerDetails,
          [name]: type === "number" ? Number(value) : value,
        },
      }));
    }
  };

  const handleInputCustomerDetailsRadio = (e) => {
    const { name, value, type } = e.target;
    const newValue =
      type === "radio" ? (value === "true" ? true : false) : value;

    setFormdata((prev) => ({
      ...prev,
      ownerDetails: {
        ...prev.ownerDetails,
        [name]: newValue,
        ...(value === "false" && { ABN: null }),
      },
    }));
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      setFormdata((prevFormData) => ({
        ...prevFormData,
        coOwnerDetail: { ...prevFormData.ownerDetails },
      }));
    } else {
      setFormdata((prevFormData) => ({
        ...prevFormData,
        coOwnerDetail: {
          firstName: "",
          lastName: "",
          mobileNum: "",
          phoneNumber: "",
          emailId: "",
        },
      }));
    }
  };

  const handleInputCoOwnerDetails = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      if (value.startsWith("0")) {
        setFormdata((prev) => ({
          ...prev,
          coOwnerDetail: {
            ...prev.coOwnerDetail,
            phoneNumber: value,
          },
        }));
      } else {
        const sanitizedValue = value.replace(/^0+/, "");
        setFormdata((prev) => ({
          ...prev,
          coOwnerDetail: {
            ...prev.coOwnerDetail,
            phoneNumber: sanitizedValue,
          },
        }));
      }
    } else {
      setFormdata((prev) => ({
        ...prev,
        coOwnerDetail: {
          ...prev.coOwnerDetail,
          [name]: value,
        },
      }));
    }
  };

  const deemingPeriods = {
    2024: 7,
    2025: 6,
    2026: 5,
    2027: 4,
    2028: 3,
    2029: 2,
    2030: 1,
  };

  const handleInputsInstallationDate = (e) => {
    const { name, value } = e.target;

    if (name === "installingDate") {
      const selectedYear = new Date(value).getFullYear();
      const deemingPeriod = deemingPeriods[selectedYear] || 1;

      setFormdata((prev) => ({
        ...prev,
        installationDate: {
          ...prev.installationDate,
          [name]: value,
          STCDeemingPeriod: deemingPeriod,
        },
      }));
    } else {
      setFormdata((prev) => ({
        ...prev,
        installationDate: {
          ...prev.installationDate,
          [name]: value,
        },
      }));
    }
  };

  const formatDateForDeemingPeriod = (date) => {
    if (!date) return "";

    const deemingPeriodString =
      {
        1: "One Year",
        2: "Two Years",
        3: "Three Years",
        4: "Four Years",
        5: "Five Years",
        6: "Six Years",
        7: "Seven Years",
      }[date] || "";

    return deemingPeriodString;
  };

  const handleInputIsGridConnectionDetailsSystemType = (e) => {
    const { name, value } = e.target;
    if (
      name === "connectionType" &&
      value === "Stand-alone (not connected to an electricity grid)"
    ) {
      setFormdata((prev) => ({
        ...prev,
        gridConnectionDetail: {
          ...prev.gridConnectionDetail,
          [name]: value,
          systemType: "",
          nmi: "",
          gridAppReferenceNo: "",
          meterNum: null,
          distributor: "",
        },
      }));
    } else if (
      name === "connectionType" &&
      value === "Connected to an electricity grid without battery storage"
    ) {
      setFormdata((prev) => ({
        ...prev,
        gridConnectionDetail: {
          ...prev.gridConnectionDetail,
          [name]: value,
        },
        batteryDetail: {
          batteryInfo: [
            {
              manufacturer: "",
              batteryBrand: "",
              batterySeries: "",
              batteryModel: "",
              batteryCapacity: null,
              Quantity: null,
            },
          ],
        },
        batterySystemAggregate: false,
        defaultManufacturerBatteryStorage: false,
      }));
    } else {
      setFormdata((prev) => ({
        ...prev,
        gridConnectionDetail: {
          ...prev.gridConnectionDetail,
          [name]: value,
        },
      }));
    }
  };

  const handleInputIsGridConnectionDetails = (e) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      gridConnectionDetail: {
        ...prev.gridConnectionDetail,

        [name]: type === "number" ? Number(value) : value,
      },
    }));
  };

  const [vnmi, setVNMI] = useState(0);
  const [isValidNMI, setIsValidNMI] = useState(false);

  const handleNMI = (e) => {
    const inputNMI = e.target.value;
    setVNMI(inputNMI);
    let runningTotal = 0;
    const length = inputNMI.length;

    for (let i = 0; i < length - 1; i++) {
      let asciiVal = inputNMI[i].charCodeAt(0);

      if (i % 2 > 0) {
        asciiVal = asciiVal * 2;
      }

      let sumOfChars = 0;
      for (let j = 0, leng = asciiVal.toString().length; j < leng; j++) {
        sumOfChars = sumOfChars + parseInt(asciiVal.toString()[j]);
      }

      runningTotal = runningTotal + sumOfChars;
    }

    let checkSum = 0;
    if (runningTotal % 10 !== 0) {
      checkSum = 10 - (runningTotal % 10);
    }
    inputNMI[10] == checkSum
      ? setIsValidNMI(!isValidNMI)
      : setIsValidNMI(false);
  };

  useEffect(() => {
    const newWattage = formdata?.panelDetail.panelInfo
      .reduce((totalWattage, panel) => {
        return totalWattage + handleWattage(panel.Quantity, panel.panelPower);
      }, 0)
      .toFixed(3);
    setFormdata((prevData) => ({
      ...prevData,
      panelDetail: {
        ...prevData.panelDetail,
        wattage: newWattage,
      },
    }));
    if (
      formdata &&
      formdata?.panelDetail &&
      formdata?.panelDetail.panelInfo &&
      formdata?.installationDate &&
      formdata?.installationDate.STCDeemingPeriod &&
      zonerating &&
      zonerating.rating
    ) {
      const newSTC = handleSTC(
        formdata?.installationDate.STCDeemingPeriod,
        zonerating.rating,
        newWattage
      );

      setWattage(newWattage);
      setStc(newSTC);

      setFormdata((prevData) => ({
        ...prevData,
        panelDetail: {
          ...prevData.panelDetail,
          stc: newSTC,
          financialPayment: (newSTC * 39.4).toFixed(3),
        },
      }));
    }
  }, [
    formdata?.panelDetail?.panelInfo,
    formdata?.installationDate?.STCDeemingPeriod,
    formdata?.installationAddress?.postCode,
  ]);

  const handleInputsPanelWarrantyDetails = (e) => {
    const { name, value, type } = e.target;
    setFormdata((prev) => ({
      ...prev,
      warrantyDetails: {
        ...prev.warrantyDetails,
        [name]: type === "number" ? (value ? parseInt(value) : null) : value,
      },
    }));
  };

  const [activeProductType, setActiveProductType] = useState("batteries");
  const [searchTerms, setSearchTerms] = useState({
    panels: "",
    inverters: "",
    batteries: "",
  });

  const [showPanelDropdown, setShowPanelDropdown] = useState(false);
  const [showInverterDropdown, setShowInverterDropdown] = useState(false);
  const [showBatteryDropdown, setShowBatteryDropdown] = useState(false);
  const [panelData, setPanelData] = useState([]);
  const [inverterData, setInverterData] = useState([]);
  const [batteryData, setBatteryData] = useState([]);

  const constructApiEndpoint = (productType) => {
    return `${baseURL}/${productType}/get?search=${searchTerms[productType]}`;
  };

  const handleProductChange = (type, value) => {
    setSearchTerms((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleProductSelection = (type, product) => {
    if (type === "panel") {
      setShowPanelDropdown(false);
      const newPanelInfo = {
        panelBrand: product.Brand || "",
        panelSupplier: product.Brand || "",
        panelModel: product["Model Number"] || "",
        panelPower: product.Watt || "",
      };
      let panelInfo = [...formdata.panelDetail.panelInfo];
      if (panelInfo.length > 0 && panelInfo[0].panelBrand === "") {
        panelInfo.shift();
      }

      const isDuplicate = panelInfo.some(
        (panel) =>
          panel.panelBrand === newPanelInfo.panelBrand &&
          panel.panelModel === newPanelInfo.panelModel &&
          panel.panelPower === newPanelInfo.panelPower
      );

      if (!isDuplicate) {
        setFormdata((prevFormData) => ({
          ...prevFormData,
          panelDetail: {
            ...prevFormData.panelDetail,
            panelInfo: [...panelInfo, newPanelInfo],
          },
        }));
      } else {
        alert("Same Brand and Model is already selected.");
        setSearchTerms({
          panels: "",
          inverters: "",
          batteries: "",
        });
      }
      setSearchTerms({
        panels: "",
        inverters: "",
        batteries: "",
      });
    } else if (type === "inverter") {
      setShowInverterDropdown(false);
      const newInverterInfo = {
        inverterBrand: product.Manufacturer || "",
        inverterSupplier: product.Manufacturer || "",
        inverterSeries: product.Series || "",
        inverterModel: product["Model Number"] || "",
        inverterCapacity: product["AC Power (kW)"] || "",
      };
      let inverterInfo = [...formdata.inverterDetail.inverterInfo];
      if (inverterInfo.length > 0 && inverterInfo[0].inverterBrand === "") {
        inverterInfo.shift();
      }

      const isDuplicate = inverterInfo.some(
        (inverter) =>
          inverter.inverterBrand === newInverterInfo.inverterBrand &&
          inverter.inverterSeries === newInverterInfo.inverterSeries &&
          inverter.inverterModel === newInverterInfo.inverterModel &&
          inverter.inverterCapacity === newInverterInfo.inverterCapacity
      );

      if (!isDuplicate) {
        setFormdata((prevFormData) => ({
          ...prevFormData,
          inverterDetail: {
            ...prevFormData.inverterDetail,
            inverterInfo: [...inverterInfo, newInverterInfo],
          },
        }));
      } else {
        alert("Same Brand, Series and Model is already selected.");
        setSearchTerms({
          panels: "",
          inverters: "",
          batteries: "",
        });
      }
      setSearchTerms({
        panels: "",
        inverters: "",
        batteries: "",
      });
    } else {
      setShowBatteryDropdown(false);
      const newBatteryInfo = {
        manufacturer: product?.Brand || "",
        batteryBrand: product?.Brand || "",
        batterySeries: product.Series || "",
        batteryModel: product["Model Number"] || "",
        batteryCapacity: product["Usable Capacity (kWh)"] || "",
        Quantity: null,
      };
      let batteryInfo = [...formdata.batteryDetail.batteryInfo];
      if (batteryInfo.length > 0 && batteryInfo[0].batteryBrand === "") {
        batteryInfo.shift();
      }

      const isDuplicate = batteryInfo.some(
        (battery) =>
          battery.batteryBrand === newBatteryInfo.batteryBrand &&
          battery.batterySeries === newBatteryInfo.batterySeries &&
          battery.batteryModel === newBatteryInfo.batteryModel &&
          battery.batteryCapacity === newBatteryInfo.batteryCapacity
      );

      if (!isDuplicate) {
        setFormdata((prevFormData) => ({
          ...prevFormData,
          batteryDetail: {
            ...prevFormData.batteryDetail,
            batteryInfo: [...batteryInfo, newBatteryInfo],
          },
        }));
      } else {
        alert("Same Brand, Series and Model is already selected.");
        setSearchTerms({
          panels: "",
          inverters: "",
          batteries: "",
        });
      }
      setSearchTerms({
        panels: "",
        inverters: "",
        batteries: "",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const searchTerm = searchTerms[activeProductType];
        if (searchTerm.length > 0 || searchTerm.length === 0) {
          const endpoint = constructApiEndpoint(activeProductType);
          const response = await axios.get(endpoint);

          if (activeProductType === "panels") {
            setPanelData(response.data);
          } else if (activeProductType === "inverters") {
            setInverterData(response.data);
          } else {
            setBatteryData(response.data);
          }
        }
      } catch (error) {
        // Handle error here
      }
    };

    fetchData();
  }, [searchTerms, activeProductType]);

  const [panelBrandList, setPanelBrandList] = useState([]);
  const [inverterBrandList, setInverterBrandList] = useState([]);
  const [batteryBrandList, setBatteryBrandList] = useState([]);

  useEffect(() => {
    const fetchBrandModels = async () => {
      const panelRequests = formdata?.panelDetail?.panelInfo
        .filter((panel) => panel?.panelBrand)
        .map(async (panel) => {
          const response = await axios.get(
            `${baseURL}/panels/get?search=${panel?.panelBrand}`
          );
          return response?.data;
        });

      const inverterRequests = formdata?.inverterDetail?.inverterInfo
        .filter((inverter) => inverter?.inverterBrand)
        .map(async (inverter) => {
          const response = await axios.get(
            `${baseURL}/inverters/get?search=${inverter?.inverterBrand}`
          );
          return response?.data;
        });

      const batteryRequests = formdata?.batteryDetail?.batteryInfo
        .filter((battery) => battery?.batteryBrand)
        .map(async (battery) => {
          const response = await axios.get(
            `${baseURL}/batteries/get?search=${battery?.batteryBrand}`
          );
          return response?.data;
        });

      const panelBrandModels = await Promise.all(panelRequests);
      const inverterBrandModels = await Promise.all(inverterRequests);
      const batteryBrandModels = await Promise.all(batteryRequests);

      setPanelBrandList(panelBrandModels);
      setInverterBrandList(inverterBrandModels);
      setBatteryBrandList(batteryBrandModels);
    };

    fetchBrandModels();
  }, [
    formdata?.panelDetail?.panelInfo,
    formdata?.inverterDetail?.inverterInfo,
    formdata?.batteryDetail?.batteryInfo,
  ]);

  // const handleAddPanelInfo = () => {
  //   const newPanelInfo = {
  //     panelSupplier: "",
  //     panelModel: "",
  //     panelPower: null,
  //     Quantity: null,
  //   };

  //   if (formdata?.panelDetail.panelInfo.length === 0) {
  //     setFormdata({
  //       ...formdata,
  //       panelDetail: {
  //         ...formdata.panelDetail,
  //         panelInfo: [newPanelInfo],
  //       },
  //     });
  //   } else {
  //     setFormdata({
  //       ...formdata,
  //       panelDetail: {
  //         ...formdata.panelDetail,
  //         panelInfo: [...formdata.panelDetail.panelInfo, newPanelInfo],
  //       },
  //     });
  //   }
  //   setIsMultipleFieldsPanel(true);
  // };

  const handlePanelModelChange = (e, index) => {
    const selectedModelNumber = e.target.value;
    const selectedPanelModel = panelBrandList[index]?.find(
      (panel) => panel["Model Number"] === selectedModelNumber
    );

    setFormdata((prevFormData) => ({
      ...prevFormData,
      panelDetail: {
        ...prevFormData.panelDetail,
        panelInfo: prevFormData.panelDetail.panelInfo.map((panel, idx) => {
          if (idx === index) {
            return {
              ...panel,
              panelModel: selectedModelNumber,
              panelSupplier: selectedPanelModel.Brand || "",
              panelPower: selectedPanelModel.Watt || null,
            };
          }
          return panel;
        }),
      },
    }));
  };

  const handleInputsPanelInfo = (e, index) => {
    const { name, type, value } = e.target;
    if (type === "radio") {
      setFormdata((prev) => ({
        ...prev,
        panelDetail: {
          ...prev.panelDetail,
          [name]: value === "true" ? true : false,
        },
      }));
    } else {
      setFormdata((prev) => ({
        ...prev,
        panelDetail: {
          ...prev.panelDetail,
          panelInfo: prev.panelDetail.panelInfo.map((info, i) => {
            if (i !== index) {
              return info;
            }
            return {
              ...info,
              [name]: type === "number" ? Number(value) : value,
            };
          }),
        },
      }));
    }
  };

  // const handleAddInverterInfo = () => {
  //   const newInverterInfo = {
  //     inverterSeries: "",
  //     inverterModel: "",
  //     inverterCapacity: null,
  //     Quantity: null,
  //   };

  //   if (formdata?.inverterDetail?.inverterInfo.length === 0) {
  //     setFormdata({
  //       ...formdata,
  //       inverterDetail: {
  //         ...formdata.inverterDetail,
  //         inverterInfo: [newInverterInfo],
  //       },
  //     });
  //   } else {
  //     setFormdata({
  //       ...formdata,
  //       inverterDetail: {
  //         ...formdata.inverterDetail,
  //         inverterInfo: [
  //           ...formdata.inverterDetail.inverterInfo,
  //           newInverterInfo,
  //         ],
  //       },
  //     });
  //   }
  //   setMultipleFieldsInverter(true);
  // };

  const handleInverterModelChange = (e, index) => {
    const selectedModelNumber = e.target.value;
    const selectedInverterModel = inverterBrandList[index]?.find(
      (inverter) => inverter["Model Number"] === selectedModelNumber
    );

    setFormdata((prevFormData) => ({
      ...prevFormData,
      inverterDetail: {
        ...prevFormData.inverterDetail,
        inverterInfo: prevFormData.inverterDetail.inverterInfo.map(
          (inverter, idx) => {
            if (idx === index) {
              return {
                ...inverter,
                inverterModel: selectedModelNumber,
                inverterSeries: selectedInverterModel?.Series || "",
                inverterCapacity:
                  selectedInverterModel["AC Power (kW)"] || null,
              };
            }
            return inverter;
          }
        ),
      },
    }));
  };

  const handleInputsInverterInfo = (e, index) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      inverterDetail: {
        ...prev.inverterDetail,
        inverterInfo: prev.inverterDetail.inverterInfo.map((info, i) => {
          if (i !== index) {
            return info;
          }
          return {
            ...info,
            [name]:
              type === "number" || type === "radio"
                ? type === "radio"
                  ? value === "true"
                    ? true
                    : false
                  : Number(value)
                : value,
          };
        }),
      },
    }));
  };

  // const handleAddBatteryInfo = () => {
  //   const newBatteryInfo = {
  //     batterySeries: "",
  //     batteryModel: "",
  //     batteryCapacity: null,
  //     Quantity: null,
  //   };

  //   if (formdata.batteryDetail.batteryInfo.length === 0) {
  //     setFormdata({
  //       ...formdata,
  //       batteryDetail: {
  //         ...formdata.batteryDetail,
  //         batteryInfo: [newBatteryInfo],
  //       },
  //     });
  //   } else {
  //     setFormdata({
  //       ...formdata,
  //       batteryDetail: {
  //         ...formdata.batteryDetail,
  //         batteryInfo: [...formdata.batteryDetail.batteryInfo, newBatteryInfo],
  //       },
  //     });
  //   }
  //   setMultiplefieldsBattery(true);
  // };

  const handleBatteryModelChange = (e, index) => {
    const selectedModelNumber = e.target.value;
    const selectedBatteryModel = batteryBrandList[index]?.find(
      (battery) => battery["Model Number"] === selectedModelNumber
    );

    setFormdata((prevFormData) => ({
      ...prevFormData,
      batteryDetail: {
        ...prevFormData.batteryDetail,
        batteryInfo: prevFormData.batteryDetail.batteryInfo.map(
          (battery, idx) => {
            if (idx === index) {
              return {
                ...battery,
                batteryModel: selectedModelNumber,
                batterySeries: selectedBatteryModel?.Series || "",
                batteryCapacity:
                  selectedBatteryModel["Usable Capacity (kWh)"] || "",
              };
            }
            return battery;
          }
        ),
      },
    }));
  };

  const handleInputsBatteryInfo = (e, index) => {
    const { name, type, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      batteryDetail: {
        ...prev.batteryDetail,
        batteryInfo: prev.batteryDetail.batteryInfo.map((info, i) => {
          if (i !== index) {
            return info;
          }
          return {
            ...info,
            [name]:
              type === "number" || type === "radio"
                ? type === "radio"
                  ? value === "true"
                    ? true
                    : false
                  : Number(value)
                : value,
          };
        }),
      },
    }));
  };

  const [wattage, setWattage] = useState(0);
  const [stc, setStc] = useState(0);

  const handleWattage = (qty, power) => {
    return (power * qty) / 1000;
  };

  const checkRange = (number, data) => {
    const matchingRange = data.find(
      (entry) => number >= entry["Range Start"] && number <= entry["Range End"]
    );

    if (matchingRange) {
      return { rating: matchingRange.Ratings };
    } else {
      return { message: "No range found for the given number." };
    }
  };

  const zonerating = checkRange(
    formdata?.installationAddress?.postCode,
    RatingData
  );

  const handleSTC = (deem, rate, qty) => {
    const STC = deem * rate * qty;
    return Math.floor(STC);
  };
  const [installerData, setInstallerData] = useState([]);
  const [designerData, setDesignerData] = useState([]);
  const [electricianData, setElectricianData] = useState([]);

  useEffect(() => {
    const test = async () => {
      try {
        const api =
          userType === "Trader"
            ? `${baseURL}/traders/detailsForTrader`
            : `${baseURL}/installer/details`;
        const resp = await axios.get(api, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp.data;
        let filteredInstallerData = data.installerData;

        if (userType === "Retailer") {
          filteredInstallerData = filteredInstallerData.filter((innerArray) =>
            innerArray.retailers.some(
              (retailer) =>
                retailer._id === retailerId && retailer.isConnect === 1
            )
          );
        }

        filteredInstallerData = filteredInstallerData.filter(
          (innerArray) =>
            innerArray.installer &&
            innerArray.installer.selectYourRole &&
            innerArray.installer.selectYourRole.includes("Installer")
        );
        const simplifiedDataInstaller = filteredInstallerData.map(
          (installerData) => ({
            name: installerData?.installer?.name,
            id: installerData?._id,
            cecExpiry: format(
              new Date(installerData?.installerlicenceExpiry),
              "yyyy-MM-dd"
            ),
          })
        );

        setInstallerData(simplifiedDataInstaller);

        let filteredDesignerData = data.installerData;

        if (userType === "Retailer") {
          filteredDesignerData = filteredDesignerData.filter((innerArray) =>
            innerArray.retailers.some(
              (retailer) =>
                retailer._id === retailerId && retailer.isConnect === 1
            )
          );
        }

        filteredDesignerData = filteredDesignerData.filter(
          (installer) =>
            installer.installer &&
            installer.installer.selectYourRole &&
            installer.installer.selectYourRole.includes("Designer")
        );

        const simplifiedDataDesigner = filteredDesignerData.map(
          (installerData) => ({
            name: installerData?.installer?.name,
            id: installerData?._id,
            cecExpiry: format(
              new Date(installerData?.designerlicenceExpiry),
              "MMMM d, yyyy"
            ),
          })
        );

        setDesignerData(simplifiedDataDesigner);

        let filteredElectricianData = data.installerData;

        if (userType === "Retailer") {
          filteredElectricianData = filteredElectricianData.filter(
            (innerArray) =>
              innerArray.retailers.some(
                (retailer) =>
                  retailer._id === retailerId && retailer.isConnect === 1
              )
          );
        }

        filteredElectricianData = filteredElectricianData.filter(
          (installer) =>
            installer.installer &&
            installer.installer.selectYourRole &&
            installer.installer.selectYourRole.includes("Electrician")
        );

        const simplifiedDataElectrician = filteredElectricianData.map(
          (installerData) => ({
            name: installerData?.installer?.name,
            id: installerData?._id,
            cecExpiry: format(
              new Date(installerData?.electricianlicenceExpiry),
              "MMMM d, yyyy"
            ),
          })
        );

        setElectricianData(simplifiedDataElectrician);
      } catch (err) {}
    };

    test();
  }, []);

  const handleSelectInstallerChange = (event) => {
    const selectedId = event.target.value;

    if (selectedId === "") {
      setFormdata((prev) => ({
        ...prev,
        installerName: "",
        installers: [],
        designerName: "",
        designers: [],
        electricianName: "",
        electricians: [],
      }));
    } else {
      const selectedInstaller = installerData.find(
        (installer) => installer.id === selectedId
      );

      if (selectedInstaller) {
        const currentDate = moment();
        const licenseExpiryDate = moment(selectedInstaller?.cecExpiry);
        const daysUntilExpiry = licenseExpiryDate.diff(currentDate, "days");
        if (daysUntilExpiry <= 15) {
          alert(
            `The license for ${
              selectedInstaller.name
            } is expiring on ${licenseExpiryDate.format("DD-MM-YYYY")}`
          );
        }
        setFormdata((prev) => ({
          ...prev,
          installerName: selectedInstaller.name,
          installers: [selectedInstaller.id],
          designerName: designerData.some(
            (designer) => designer.id === selectedId
          )
            ? selectedInstaller.name
            : "",
          designers: designerData.some((designer) => designer.id === selectedId)
            ? [selectedInstaller.id]
            : [],
          electricianName: electricianData.some(
            (electrician) => electrician.id === selectedId
          )
            ? selectedInstaller.name
            : "",
          electricians: electricianData.some(
            (electrician) => electrician.id === selectedId
          )
            ? [selectedInstaller.id]
            : [],
        }));
      }
    }
  };

  const handleSelectDesignerChange = (event) => {
    const selectedId = event.target.value;

    if (selectedId === "") {
      setFormdata((prev) => ({
        ...prev,
        designerName: "",
        designers: [],
      }));
    } else {
      const selectedData = designerData.find(
        (designer) => designer.id === selectedId
      );

      if (selectedData) {
        const currentDate = moment();
        const licenseExpiryDate = moment(selectedData?.cecExpiry);
        const daysUntilExpiry = licenseExpiryDate.diff(currentDate, "days");
        if (daysUntilExpiry <= 15) {
          alert(
            `The license for ${
              selectedData.name
            } is expiring on ${licenseExpiryDate.format("DD-MM-YYYY")}`
          );
        }
        setFormdata((prev) => ({
          ...prev,
          designerName: selectedData.name,
          designers: [selectedData.id],
        }));
      }
    }
  };

  const handleSelectElectricianChange = (event) => {
    const selectedId = event.target.value;

    if (selectedId === "") {
      setFormdata((prev) => ({
        ...prev,
        electricianName: "",
        electricians: [],
      }));
    } else {
      const selectedData = electricianData.find(
        (electrician) => electrician.id === selectedId
      );

      if (selectedData) {
        const currentDate = moment();
        const licenseExpiryDate = moment(selectedData?.cecExpiry);
        const daysUntilExpiry = licenseExpiryDate.diff(currentDate, "days");
        if (daysUntilExpiry <= 15) {
          alert(
            `The license for ${
              selectedData.name
            } is expiring on ${licenseExpiryDate.format("DD-MM-YYYY")}`
          );
        }
        setFormdata((prev) => ({
          ...prev,
          electricianName: selectedData.name,
          electricians: [selectedData.id],
        }));
      }
    }
  };

  const handleInputsInstructionForInstallation = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputAdditionalDetails = (e) => {
    const { name, value, type } = e.target;

    const isRadio = type === "radio";
    const normalizedValue =
      isRadio && value === "true"
        ? true
        : isRadio && value === "false"
        ? false
        : value;

    setFormdata((prev) => ({
      ...prev,
      additionalAttributes: {
        ...prev.additionalAttributes,
        [name]: normalizedValue,
      },
    }));
  };

  const handleInputSiteDetails = (e) => {
    const { name, value } = e.target;

    setFormdata((prev) => ({
      ...prev,
      siteAttributes: {
        ...prev.siteAttributes,
        [name]: value,
      },
    }));
  };

  const [file, setFile] = useState([]);
  const [error, setError] = useState("");

  const handleInputsUploadDocuments = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const fileSize = file.size;
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      let maxSizeInBytes;

      if (file.type === allowedTypes[0]) {
        maxSizeInBytes = 50 * 1024;
      } else if (file.type === allowedTypes[1]) {
        maxSizeInBytes = 100 * 1024;
      } else if (file.type === allowedTypes[2]) {
        maxSizeInBytes = 2 * 1024 * 1024;
      } else {
        alert("Invalid file type. Please upload a JPEG/PNG/PDF file only.");
        setError("Invalid file type. Please upload a JPEG/PNG/PDF file only.");
        setFormdata((prev) => ({
          ...prev,
          [name]: null,
        }));
        e.target.value = null;
        return;
      }

      if (fileSize <= maxSizeInBytes) {
        setFormdata((prev) => ({
          ...prev,
          [name]: file,
        }));
        setError("");
        setFile((prevFiles) => [...prevFiles, file]);
      } else {
        alert(
          `File too big. ${file.type
            .split("/")[1]
            .toUpperCase()} file size should not exceed ${
            maxSizeInBytes / 1024
          } kB`
        );
        setError(
          `File too big. ${file.type
            .split("/")[1]
            .toUpperCase()} file size should not exceed ${
            maxSizeInBytes / 1024
          } kB`
        );
        setFormdata((prev) => ({
          ...prev,
          [name]: null,
        }));
        e.target.value = null;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (
      formdata.ownerDetails.isGST &&
      formdata.ownerDetails.ABN.toString().trim().length !== 11
    ) {
      alert("ABN number must be 11 digits when GST is enabled.");
      return;
    }

    try {
      const formData = new FormData();

      Object.keys(formdata).forEach((key) => {
        if (
          key !== "design" &&
          key !== "customInvoice" &&
          key !== "uploadElectricityBill" &&
          key !== "STCForm" &&
          key !== "STCInvoice" &&
          key !== "testingCertificate" &&
          key !== "noticeOfCompletion"
        ) {
          if (typeof formdata[key] === "string") {
            formData.append(key, formdata[key] === "" ? "" : formdata[key]);
          } else {
            formData.append(key, JSON.stringify(formdata[key]));
          }
        }
      });

      formData.append("design", formdata.design || "");
      formData.append("customInvoice", formdata.customInvoice || "");
      formData.append(
        "uploadElectricityBill",
        formdata.uploadElectricityBill || ""
      );
      formData.append("STCForm", formdata.STCForm || "");
      formData.append("STCInvoice", formdata.STCInvoice || "");
      formData.append("testingCertificate", formdata.testingCertificate || "");
      formData.append("noticeOfCompletion", formdata.noticeOfCompletion || "");
      formData.append("panelSerialNumbers", JSON.stringify(serialNumbers));
      const response = await axios.patch(
        `${baseURL}/job/update/${jobId}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Job Updated Successfully");
        localStorage.removeItem("jobId");
        setTimeout(() => {
          window.close();
        }, 800);
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
      }
    } catch (error) {
      console.log("Error Updating Job");
      alert(error?.response?.data?.message);
    }
  };

  const fileInputRef3 = useRef(null);

  const handleImageClick3 = () => {
    fileInputRef3.current.click();
  };

  const handleRemoveImage = (imageName) => {
    setFormdata((prev) => ({
      ...prev,
      [imageName]: "",
    }));
    if (fileInputRef3.current) {
      fileInputRef3.current.value = "";
    }
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [isViewTeamOpen, setViewTeamOpen] = useState(false);
  const viewTeam = (installerId) => {
    localStorage.setItem("installerId", installerId);
    setViewTeamOpen(true);
  };

  const [panelSerialNumber, setPanelSerialNumber] = useState();
  const [inverterSerialNumber, setInverterSerialNumber] = useState();
  const [batterySerialNumber, setBatterySerialNumber] = useState();
  const [photos, setPhotos] = useState([]);
  const [toolTip, setToolTip] = useState("Copy to Clipboard");
  const [panelSeriesToolTip, setPanelSeriesToolTip] =
    useState("Copy to Clipboard");
  const [serialScanImages, setSerialScansImages] = useState([]);
  const [panelCount, setPanelCount] = useState(0);

  const fetchOnSiteData = async () => {
    setdeleteLoading(true);
    try {
      const resp = await axios.get(`${baseURL}/onSite`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = resp?.data?.data;
      const flattenedData = data.flat();
      const result = flattenedData.filter((item) => item.jobId === jobId);
      // const panels = result[0]?.panels;
      const panels =
        result[0]?.panels?.filter(
          (panel) => panel.serialNumber && panel.panelModel
        ) || [];
      const inverters =
        result[0]?.inverters?.filter(
          (inverter) => inverter.serialNumber && inverter.inverterModel
        ) || [];
      const batteries =
        result[0]?.batteries?.filter(
          (battery) => battery.serialNumber && battery.batteryModel
        ) || [];
      // const batteries = result[0]?.batteries;
      const photos = result[0]?.photos[0]?.installerPresence;
      const serialScan = result[0]?.photos[0]?.panelSerialNumberPhotos;
      setPhotos(photos);
      setSerialScansImages(serialScan);
      setPanelSerialNumber(panels);
      setInverterSerialNumber(inverters);
      setBatterySerialNumber(batteries);
      setPanelCount(result[0]?.panels.length);
      setdeleteLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setdeleteLoading(false);
    }
  };
  useEffect(() => {
    fetchOnSiteData();
  }, []);

  const handleCopyToClipboard = (serialNumbers) => {
    navigator.clipboard.writeText(serialNumbers);
  };

  const handleCopyPanelToClipboard = (event) => {
    event.preventDefault();
    const serialNumbers = panelSerialNumber
      .map((panel) => panel.serialNumber)
      .join("\n");
    handleCopyToClipboard(serialNumbers);
    setPanelSeriesToolTip("Copied to Clipboard");
  };

  const handleCopyInverterToClipboard = (event) => {
    event.preventDefault();
    const serialNumbers = inverterSerialNumber
      .map((inverter) => inverter.serialNumber)
      .join("\n");
    handleCopyToClipboard(serialNumbers);
    setToolTip("Copied to clipboard");
  };

  const [popupOpen, setPopupOpen] = useState(false);
  const [currentSerialNumber, setCurrentSerialNumber] = useState(null);
  const [currentModel, setCurrentModel] = useState("");
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [currentDistance, setCurrentDistance] = useState(null);
  const [serialID, setSerialID] = useState(null);
  const [serialIndex, setserialIndex] = useState();
  const openPopup = (
    serialNumber,
    inverterModel,
    imageUrl,
    distance,
    index,
    id
  ) => {
    if (
      serialNumber === undefined &&
      inverterModel === undefined &&
      imageUrl === undefined &&
      distance === undefined
    ) {
      setCurrentSerialNumber(null);
      setCurrentModel("");
      setCurrentImageUrl("");
      setCurrentDistance(null);
      setserialIndex(null);
      setSerialID(null);
    } else {
      setCurrentSerialNumber(serialNumber);
      setCurrentModel(inverterModel);
      setCurrentImageUrl(imageUrl);
      setCurrentDistance(distance);
      setSerialID(id);
      setserialIndex(index);
    }
    setPopupOpen(true);
    document.body.classList.add("page-modal-open");
  };
  const closePopup = () => {
    setPopupOpen(false);
    document.body.classList.remove("page-modal-open");
  };

  const [popupOpenBattery, setPopupOpenBattery] = useState(false);

  const openPopupBattery = (
    serialNumber,
    batteryModel,
    imageUrl,
    distance,
    batteryId
  ) => {
    if (serialNumber === undefined && imageUrl === undefined) {
      setCurrentSerialNumber(null);
      setCurrentModel("");
      setCurrentImageUrl("");
      setCurrentDistance(null);
      setSerialID(null);
    } else {
      setCurrentSerialNumber(serialNumber);
      setCurrentModel(batteryModel);
      setCurrentImageUrl(imageUrl);
      setCurrentDistance(distance);
      setSerialID(batteryId);
    }
    setPopupOpenBattery(true);
    document.body.classList.add("page-modal-open");
  };

  const closePopupBattery = () => {
    setPopupOpenBattery(false);
    document.body.classList.remove("page-modal-open");
  };

  const [popupOpenPanel, setPopupOpenPanel] = useState(false);

  const openPopupPanel = (
    serialNumber,
    panelModel,
    imageUrl,
    distance,
    panelId
  ) => {
    if (serialNumber === undefined && imageUrl === undefined) {
      setCurrentSerialNumber(null);
      setCurrentModel("");
      setCurrentImageUrl("");
      setCurrentDistance(null);
      setSerialID(null);
    } else {
      setCurrentSerialNumber(serialNumber);
      setCurrentModel(panelModel);
      setCurrentImageUrl(imageUrl);
      setCurrentDistance(distance);
      setSerialID(panelId);
    }
    setPopupOpenPanel(true);
    document.body.classList.add("page-modal-open");
  };

  const closePopupPanel = () => {
    setPopupOpenPanel(false);
    document.body.classList.remove("page-modal-open");
  };

  const [panelSerialData, setPanelSerialData] = useState({
    jobId: "",
    panelModel: "",
    serialNumber: "",
    panelPhotos: "",
  });

  const handleInputPanelSerialNumber = (e) => {
    const { name, value, files } = e.target;
    if (name === "panelPhotos") {
      setPanelSerialData({
        ...panelSerialData,
        panelPhotos: files[0],
      });
    } else {
      setPanelSerialData({
        ...panelSerialData,
        [name]: value,
        jobId: localStorage.getItem("jobId"),
      });
    }
  };

  const handlePanelSerialSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("jobId", panelSerialData.jobId);
      formData.append("panelModel", panelSerialData.panelModel);
      formData.append("serialNumber", panelSerialData.serialNumber);
      formData.append("panelPhotos", panelSerialData.panelPhotos);

      const response = await axios.post(
        `${baseURL}/onsitePanel/update`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Serial Number Added Successfully");
        fetchOnSiteData();
        closePopupPanel();
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const [inverterSerialData, setInverterSerialData] = useState({
    jobId: "",
    inverterModel: "",
    serialNumber: "",
    inverterPhoto: "",
  });

  const [inverterPhoto, setInverterPhoto] = useState("");

  const handleInputSerialNumber = (e) => {
    const { name, value, files } = e.target;
    if (name === "inverterPhoto") {
      setInverterSerialData({
        ...inverterSerialData,
        inverterPhoto: files[0],
        [name]: value,
      });
    } else {
      setInverterSerialData({
        ...inverterSerialData,
        [name]: value,
        jobId: localStorage.getItem("jobId"),
      });
    }
  };
  const handleInverterSerialSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("jobId", inverterSerialData.jobId);
      formData.append("inverterModel", inverterSerialData.inverterModel);
      formData.append("serialNumber", inverterSerialData.serialNumber);
      formData.append("inverterPhoto", inverterSerialData.inverterPhoto);

      const response = await axios.post(
        `${baseURL}/onsiteInverter/update`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        alert("Serial Number Added Successfully");
        fetchOnSiteData();
        closePopup();
      } else {
        console.error("Login failed. Unexpected status code:", response.status);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const handleDeleteField = (field, index) => {
    if (field === "battery") {
      const updatedBatteryInfo = formdata.batteryDetail.batteryInfo.filter(
        (_, idx) => idx !== index
      );
      setFormdata({
        ...formdata,
        batteryDetail: {
          ...formdata.batteryDetail,
          batteryInfo: updatedBatteryInfo,
        },
      });
    } else if (field === "inverter") {
      const updatedInverterInfo = formdata?.inverterDetail?.inverterInfo.filter(
        (_, idx) => idx !== index
      );
      setFormdata({
        ...formdata,
        inverterDetail: {
          ...formdata.inverterDetail,
          inverterInfo: updatedInverterInfo,
        },
      });
    } else {
      const updatedPanelInfo = formdata?.panelDetail?.panelInfo.filter(
        (_, idx) => idx !== index
      );
      setFormdata({
        ...formdata,
        panelDetail: {
          ...formdata.panelDetail,
          panelInfo: updatedPanelInfo,
        },
      });
    }
  };

  const totalPanelQuantity = formdata?.panelDetail?.panelInfo.reduce(
    (total, panel) => total + (panel.Quantity ?? 0),
    0
  );

  const totalInverterQuantity = formdata?.inverterDetail?.inverterInfo.reduce(
    (total, inverter) => total + (inverter.Quantity ?? 0),
    0
  );

  const totalBatteryQuantity = formdata?.batteryDetail?.batteryInfo.reduce(
    (total, battery) => total + (battery.Quantity ?? 0),
    0
  );

  const handleSelfieUpdate = (field, type) => (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(file, field, type);
    }
  };
  const handleFileUpload = async (file, key, type) => {
    const imageFile = file;
    const formData = new FormData();
    formData.append(key, imageFile);
    formData.append("jobId", jobId);
    formData.append("type", type);
    const res = await axios
      .post(`${baseURL}/onsite/photos/upload`, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        alert("Image Uploaded Successfully");
        fetchOnSiteData();
        if (key === "installerPresence") {
          setPhotos((prevPhotos) => ({
            ...prevPhotos,
            installerPresence: {
              ...prevPhotos.installerPresence,
              photos: response.data[0]?.photos,
            },
          }));
        } else {
          setSerialScansImages((prevSerialScansImages) => ({
            ...prevSerialScansImages,
            [key]: response.data[0]?.photos,
          }));
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  useEffect(() => {
    if (photos?.length > 0) {
      const firstSelfie = photos.find((photo) => photo?.type === "firstSelfie");
      setStartSelfie(firstSelfie);
      const middleSelfie = photos.find(
        (photo) => photo?.type === "middleSelfie"
      );
      setMiddleSelfie(middleSelfie);
      const endSelfieData = photos.find((photo) => photo?.type === "endSelfie");
      setEndSelfie(endSelfieData);
    }
  }, [photos]);

  const [onSiteSerial, setOnsiteSerialNumbers] = useState(false);
  const [serialNumberLoad, setSerialNumberLoad] = useState(false);
  const [multipleInverterSerial, setMultipleInverterSerial] = useState(null);
  const [multipleBatterySerials, setMultipleBatterySerials] = useState(null);
  const fetchMultipleSerialNumbers = async () => {
    setSerialNumberLoad(true);
    try {
      const resp = await axios.get(
        `${baseURL}/onsitePanel/multiplePanel-Inverter-BatterySerialGet/${jobId}`
      );
      const data = resp?.data;
      setOnsiteSerialNumbers(data?.onSite);
      setMultiplePanelSerial(data?.panels);
      setMultipleInverterSerial(data?.inverters);
      setMultipleBatterySerials(data?.batteries);
      setSerialNumberLoad(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSerialNumberLoad(false);
    }
  };
  const handleInputserialPanelNumbersChange = (event, field) => {
    const input = event.target.value;
    const newSerialNumbers = input.split("\n").map((serial) => serial.trim());
    if (field === "inverterSerialNumber") {
      setInverterSerialNumbers(newSerialNumbers);
    } else if (field === "batterySerialNumber") {
      setbatterySerialNumbers(newSerialNumbers);
    } else {
      setSerialNumbers(newSerialNumbers);
    }
  };
  const handleSerialNumbersSubmit = async () => {
    if (serialNumbers || inverterSerialNumbers || batterySerialNumbers) {
      let panelSerialNumbersString = "";
      if (serialNumbers) {
        panelSerialNumbersString = serialNumbers.join(",");
      } else if (inverterSerialNumbers) {
        panelSerialNumbersString = inverterSerialNumbers.join(",");
      } else {
        panelSerialNumbersString = batterySerialNumbers?.join(",");
      }
      let requestData = null;
      let endpoint = "";
      if (serialNumbers) {
        requestData = {
          jobId: localStorage.getItem("jobId"),
          panelSerialNumbers: panelSerialNumbersString,
          panelBrand: manualPanelBrand,
          panelModel: manualPanelModel,
        };
        endpoint = "onsitePanel/multiplePanelSerialAdd";
      } else if (inverterSerialNumbers) {
        requestData = {
          jobId: localStorage.getItem("jobId"),
          inverterSerialNumbers: panelSerialNumbersString,
          inverterBrand: manualInverterBrand,
          inverterModel: manualInverterModel,
          inverterSeries: manualInverterSeries,
        };
        endpoint = "onsiteInverter/multipleInverterSerialAdd";
      } else {
        requestData = {
          jobId: localStorage.getItem("jobId"),
          batterySerialNumbers: panelSerialNumbersString,
          batteryBrand: manualBatteryBrand,
          batteryModel: manualBatteryModel,
          batterySeries: manualBatterySeries,
        };
        endpoint = "onsiteBattery/multipleBatterySerialAdd";
      }

      try {
        const response = await axios.post(
          `${baseURL}/${endpoint}`,
          requestData,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          fetchMultipleSerialNumbers();
          setSerialNumbers("");
          setInverterSerialNumbers("");
          setbatterySerialNumbers("");
          fetchOnSiteData();
        }
      } catch (error) {
        alert(error?.response?.data?.message);
      }
      setOpenConfirmation(null);
    } else {
      alert("Serial numbers not added");
      setOpenConfirmation(null);
    }
  };
  //Panels enter manually Ends

  const handlePanelBlur = () => {
    setTimeout(() => {
      setShowPanelDropdown(false);
    }, 200);
  };

  const handleInverterBlur = () => {
    setTimeout(() => {
      setShowInverterDropdown(false);
    }, 200);
  };

  const handleBatteryBlur = () => {
    setTimeout(() => {
      setShowBatteryDropdown(false);
    }, 200);
  };

  const [inverterCapacity, setInverterCapacity] = useState();

  const [inverterCapacityError, setInverterCapacityError] = useState(false);
  const [totalInverterCapacity, setTotalInverterCapacity] = useState(0);

  useEffect(() => {
    if (formdata?.inverterDetail?.inverterInfo) {
      let totalCapacity = 0;

      formdata.inverterDetail.inverterInfo.forEach((inverter) => {
        const capacity = inverter.inverterCapacity || 1;
        const quantity = inverter.Quantity;
        if (quantity == 0) {
          setInverterCapacityError(false);
        } else {
          totalCapacity += capacity * quantity;
          if (capacity > 0 && totalCapacity * 1.333 < wattage) {
            setInverterCapacityError(true);
          } else {
            setInverterCapacityError(false);
          }
        }
      });

      setTotalInverterCapacity(totalCapacity);
    }
  }, [
    formdata?.inverterDetail?.inverterCapacity,
    wattage,
    formdata?.panelDetail?.panelInfo,
    formdata.inverterDetail.inverterInfo,
  ]);

  const handleDeleteSerialNumber = (arrayIndex, serialIndex, type) => {
    let updatedSerialNumbers = null;
    if (type === "panel") {
      updatedSerialNumbers = [...serialNumbers];
    } else if (type === "inverter") {
      updatedSerialNumbers = [...inverterSerialNumbers];
    } else {
      updatedSerialNumbers = [...batterySerialNumbers];
    }
    updatedSerialNumbers[arrayIndex] = updatedSerialNumbers[arrayIndex]
      .split(",")
      .filter((_, index) => index !== serialIndex)
      .join(",");
    if (type == "panel") {
      setSerialNumbers(updatedSerialNumbers);
    } else if (type == "inverter") {
      setInverterSerialNumbers(updatedSerialNumbers);
    } else {
      setbatterySerialNumbers(updatedSerialNumbers);
    }
  };

  const deleteSerialNumberHandler = async (panelId, type) => {
    setdeleteLoading(true);
    let panelUrl = "";
    if (type === "panel") {
      panelUrl = `onsitePanel/deletePanel/${jobId}/${panelId}`;
    } else if (type == "inverter") {
      let inverterId = panelId;
      panelUrl = `onsiteInverter/deleteInverter/${jobId}/${inverterId}`;
    } else {
      let batteryId = panelId;

      panelUrl = `onsiteBattery/deleteBattery/${jobId}/${batteryId}`;
    }
    try {
      const response = await axios.delete(`${baseURL}/${panelUrl}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        alert(response?.data?.message || "Serial numbers saved successfully");
        setSaveButton(false);
        fetchMultipleSerialNumbers();
        fetchOnSiteData();
        setOpenConfirmation(null);
      } else if (response?.status == 400 || response?.status == 404) {
        alert("Failed deleting");
      } else {
        console.error("Error saving serial numbers");
        setOpenConfirmation(null);
      }
      setdeleteLoading(false);
      fetchOnSiteData();
    } catch (error) {
      console.error("Error:", error);
      alert("Deleting Panel Failed");
      setOpenConfirmation(null);
      setdeleteLoading(false);
    }
  };

  const handleConfirmSubmit = (type, index, id, product) => {
    if (type == "submit") {
      if (
        (serialNumbers && formdata?.panelDetail?.panelInfo[0]?.panelModel) ||
        (batterySerialNumbers &&
          formdata?.batteryDetail?.batteryInfo[0].batteryModel) ||
        (inverterSerialNumbers &&
          formdata?.inverterDetail?.inverterInfo[0].inverterModel)
      ) {
        setOpenConfirmation({ type: type });
      } else if (
        !formdata?.panelDetail?.panelInfo[0]?.panelModel ||
        !formdata?.batteryDetail?.batteryInfo[0].batteryModel ||
        !formdata?.inverterDetail?.inverterInfo[0].inverterModel
      ) {
        alert("Please select Model to add serial numbers");
      } else {
        alert("Please add Serial numbers first");
      }
    } else {
      setOpenConfirmation({
        index: index,
        id: id,
        type: type,
        product: product,
      });
    }
  };

  const handleConfirmDelete = (index, id, type) => {
    // setOpenConfirmation({index:index,id:id});
  };
  //Open Image in POPUP Starts
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [modalLabel, setModalLabel] = useState("");
  const [modalDistance, setModalDistance] = useState(null);

  const openModal = (image, label, distance) => {
    setModalImage(image);
    setModalLabel(label);
    setModalDistance(distance);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage(null);
    setModalLabel("");
    setModalDistance(null);
  };
  //Open Image in POPUP Ends
  const [imageData, setImageData] = useState({});

  // const handleFileUpload = async (file, key) => {
  //   const uploadedFileData =
  //   setImageData((prevImageData) => ({
  //     ...prevImageData,
  //     [key]: uploadedFileData,
  //   }));
  // };

  // const handleInputPanelSerialNumberChange = (event, index) => {
  //   const newEditedSerialNumbers = [...editedSerialNumbers];
  //   newEditedSerialNumbers[index] = {
  //     ...newEditedSerialNumbers[index],
  //     serialNumber: event.target.value,
  //   };
  //   setEditedSerialNumbers(newEditedSerialNumbers);
  // };
  const [editSerial, setEditSerial] = useState(null);
  const [saveButton, setSaveButton] = useState(false);

  const handleInputChange = (event, index, id, field, isSerial) => {
    const { value, files } = event.target;
    setEditSerial(id);
    setSaveButton(true);
    if (
      field == "inverterSerial" ||
      field == "panelSerial" ||
      field == "batterySerial"
    ) {
      setCurrentSerialNumber(value);
      // setInverterPhoto(files)
    } else if (field == "inverterSerialEdit") {
      setMultipleInverterSerial((prevSerials) => {
        const updatedSerials = [...prevSerials];
        updatedSerials[index] = {
          ...updatedSerials[index],
          serialNumber: value,
        };
        return updatedSerials;
      });
    } else if (field == "batterySerialEdit") {
      setMultipleBatterySerials((prevSerials) => {
        const updatedSerials = [...prevSerials];
        updatedSerials[index] = {
          ...updatedSerials[index],
          serialNumber: value,
        };
        return updatedSerials;
      });
    } else {
      setMultiplePanelSerial((prevSerials) => {
        const updatedSerials = [...prevSerials];
        updatedSerials[index] = {
          ...updatedSerials[index],
          serialNumber: value,
        };
        return updatedSerials;
      });
    }
  };

  const handleSaveClick = async (panelId, serialNumber, field, isSerial) => {
    if (isSerial === "isSerial") {
      const formData = new FormData();
      formData.append("serialNumber", serialNumber);
      formData.append("jobId", jobId);
      formData.append("panelModel", inverterSerialData?.inverterModel);
      try {
        const response = axios.post(
          `${baseURL}/onsiteInverter/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          alert(response?.data?.message || "Serial numbers saved succesfully.");
          setSaveButton(false);
          fetchOnSiteData();
        } else {
          console.error("Error saving serial numbers");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      let endPoint = null;
      if (field == "panelSerial") {
        endPoint = `onsitePanel/editPanelSerial/${jobId}/${panelId}`;
      } else if (field == "inverterSerial") {
        endPoint = `onsiteInverter/editInverterSerial/${jobId}/${panelId}`;
      } else {
        endPoint = `onsiteBattery/editBatterySerial/${jobId}/${panelId}`;
      }
      const reqBody = {
        newSerialNumber: serialNumber,
      };
      try {
        const response = axios.patch(`${baseURL}/${endPoint}`, reqBody, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response) {
          alert("Serial Number Updated Successfully");
          setSaveButton(false);
          fetchOnSiteData();
        } else {
          console.error("Error saving serial numbers");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // const handleSaveInverterClick = async(inverterId,)
  const handlefailedPVChange = (e) => {
    const { name, value, type } = e.target;
    setFormdata((prevData) => {
      let updatedValue;
      if (type === "radio") {
        updatedValue = value === "true";
      } else {
        updatedValue = value;
      }
      return {
        ...prevData,
        failedPV: {
          ...prevData.failedPV,
          [name]: updatedValue,
        },
      };
    });
  };

  const isExpired = (dateString) => {
    const expiryDate = new Date(dateString);
    const currentDate = new Date();
    return expiryDate < currentDate;
  };

  const getTotalQuantity = (panelInfo) => {
    return panelInfo?.reduce((total, item) => total + (item.Quantity || 0), 0);
  };

  //Multiple Products Serial Number Starts

  const [selectedPanelIndex, setSelectedPanelIndex] = useState(0);
  const [selectedInverterIndex, setSelectedInverterIndex] = useState(0);
  const [selectedBatteryIndex, setSelectedBatteryIndex] = useState(0);

  const handleRadioChange = (index) => {
    setSelectedPanelIndex(index);
    const selectedPanel = formdata?.panelDetail?.panelInfo[index];
    if (selectedPanel) {
      setManualPanelBrand(selectedPanel.panelBrand);
      setManualPanelModel(selectedPanel.panelModel);
    }
  };

  const handleInverterRadioChange = (index) => {
    setSelectedInverterIndex(index);
    const selectedInverter = formdata?.inverterDetail?.inverterInfo[index];
    if (selectedInverter) {
      setManualInverterBrand(selectedInverter.inverterBrand);
      setManualInverterModel(selectedInverter.inverterModel);
      setManualInverterSeries(selectedInverter.inverterSeries);
    }
  };

  const handleBatteryRadioChange = (index) => {
    setSelectedBatteryIndex(index);
    const selectedBattery = formdata?.batteryDetail?.batteryInfo[index];
    if (selectedBattery) {
      setManualBatteryBrand(selectedBattery.batteryBrand);
      setManualBatteryModel(selectedBattery.batteryModel);
      setManualBatterySeries(selectedBattery.batterySeries);
    }
  };

  useEffect(() => {
    handleRadioChange(0);
    handleInverterRadioChange(0);
    handleBatteryRadioChange(0);
  }, [
    formdata?.panelDetail?.panelInfo,
    formdata?.inverterDetail?.inverterInfo,
    formdata?.batteryDetail?.batteryInfo,
  ]);

  //Multiple Products Serial Number Ends

  //Job Finish Functionality Starts

  const handleButtonClick = async (status) => {
    try {
      const jobId = localStorage.getItem("jobId");
      const requestData = {
        newStatus: status,
      };
      const response = await axios.patch(
        `${baseURL}/users/jobschangeStatus/${jobId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Job Status Updated Successfully");
        localStorage.removeItem("jobId");
        window.close();
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const shouldShowFinishButton = () => {
    const {
      CustomerSignStatus,
      PanelScanStatus,
      designerDeclarationStatus,
      documentsUploadStatus,
      electricianDeclarationStatus,
      installerDeclarationStatus,
      installerEndSelfie,
      installerMiddleSelfie,
      installerStartSelfie,
      inverterScanStatus,
      photosStatus,
      propertyScanStatus,
    } = status;

    return (
      CustomerSignStatus === 1 &&
      PanelScanStatus === 1 &&
      designerDeclarationStatus === 1 &&
      documentsUploadStatus === 1 &&
      electricianDeclarationStatus === 1 &&
      installerDeclarationStatus === 1 &&
      installerEndSelfie === 1 &&
      installerMiddleSelfie === 1 &&
      installerStartSelfie === 1 &&
      inverterScanStatus === 1 &&
      photosStatus === 1 &&
      propertyScanStatus === 1
    );
  };

  //Job Finish Functionality Ends

  return (
    <div className="MainOuterContainer">
      <div>
        <Aside />
      </div>
      <div>
        <div className="CreatJobDetailsForm ">
          {!loadingJobData ? (
            <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
              <div className="CreateJobContainer">
                <div className="row">
                  <HeaderComponent
                    PageTitle="Edit PV Job"
                    subTitle="Dashboard / Jobs / Edit Job"
                  />
                </div>
              </div>
              <div className="InformationFormDiv">
                <div className="row">
                  <span className="panel-info">Panel Information</span>
                </div>
                <div className="row">
                  <div className="owner-type">
                    <span>System Owner Type</span>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="Owner-type-selection">
                        <img
                          src={`${image}/Assets/CreateJob/housing-area.png`}
                          alt=""
                          onClick={() => {
                            document.getElementById("residential").click();
                          }}
                          height={"80px"}
                          style={{
                            textAlign: "end",
                            position: "relative",
                            bottom: "-3px",
                          }}
                        />

                        <div style={{ textAlign: "center", width: "12%" }}>
                          <img
                            src={`${image}/Assets/CreateJob/residential.png`}
                            alt=""
                            onClick={() => {
                              document.getElementById("commercial").click();
                            }}
                            height={"60px"}
                          />
                        </div>
                      </div>
                      <div className="Owner-type-selection">
                        <ul className="radio-ul">
                          <li>
                            {" "}
                            <input
                              id="residential"
                              type="radio"
                              className=""
                              name="systemOwnerType"
                              value="Residential"
                              onChange={handleInputsSystemOwnerType}
                              checked={
                                formdata?.systemOwnerType === "Residential"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="residential"
                            >
                              Residential
                            </label>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {" "}
                            <input
                              id="commercial"
                              type="radio"
                              className=""
                              name="systemOwnerType"
                              value="Commercial"
                              onChange={handleInputsSystemOwnerType}
                              checked={
                                formdata?.systemOwnerType === "Commercial"
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="commercial"
                            >
                              Commercial
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="panel-information">
                  <div className="panel-main-bg postal-address">
                    <p className="sectionTitleText">Installing For</p>
                  </div>
                  <div className="installing-boxes" style={{ width: "220px" }}>
                    <select
                      className="installing-type"
                      name="installingFor"
                      value={formdata?.installingFor || ""}
                      onChange={handleInputsInstallingFor}
                    >
                      <option value="" disabled>
                        SELECT
                      </option>
                      {installingForOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="panel-information">
                  <div className="panel-main-bg postal-address">
                    <p className="sectionTitleText">System Owner Details</p>
                  </div>
                  <div className="main-detail">
                    <form method="post">
                      {formdata?.installingFor === "Individual" && (
                        <React.Fragment>
                          <input
                            className="detail-input"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            maxLength={30}
                            value={formdata?.ownerDetails?.firstName || ""}
                            onChange={handleInputCustomerDetails}
                            autoComplete="off"
                            style={{ width: "280px" }}
                          />
                          <input
                            className="detail-input"
                            type="text"
                            name="lastName"
                            maxLength={30}
                            placeholder="Last Name"
                            value={formdata?.ownerDetails?.lastName || ""}
                            onChange={handleInputCustomerDetails}
                            autoComplete="off"
                            style={{ width: "280px" }}
                          />
                        </React.Fragment>
                      )}
                      {formdata?.installingFor !== "Individual" && (
                        <div style={{ display: "flex" }}>
                          <input
                            style={{ display: "flex", width: "434px" }}
                            className="detail-input"
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={formdata?.ownerDetails?.companyName || ""}
                            maxLength={60}
                            onChange={handleInputCustomerDetails}
                            autoComplete="off"
                          />
                        </div>
                      )}

                      <div
                        style={{ display: "flex" }}
                        className="detailContainerForm"
                      >
                        <input
                          className="detail-input"
                          type="email"
                          name="emailId"
                          placeholder="Email ID"
                          value={formdata?.ownerDetails?.emailId || ""}
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                          onChange={handleInputCustomerDetails}
                          autoComplete="off"
                          style={{ width: "320px", overflowX: "scroll" }}
                        />
                        <input
                          className="detail-input"
                          type="text"
                          name="mobileNum"
                          placeholder="Mobile Number"
                          value={formdata?.ownerDetails?.mobileNum || ""}
                          onChange={handleInputCustomerDetails}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 14) {
                              window.alert(
                                "Mobile number must be of 14 digits."
                              );
                              e.target.value = " ";
                            }
                          }}
                          autoComplete="off"
                        />
                        <input
                          className="detail-input phone-space"
                          type="text"
                          name="phoneNumber"
                          placeholder="Phone No"
                          value={formdata?.ownerDetails?.phoneNumber || ""}
                          onChange={handleInputCustomerDetails}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 14) {
                              window.alert(
                                "Phone number must be of 14 digits."
                              );
                              e.target.value = " ";
                            }
                          }}
                          autoComplete="off"
                        />
                      </div>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "baseline",
                            flexDirection: "column",
                            margin: "0 0 0 20px",
                          }}
                        >
                          <label className="gst-system">
                            Is Customer Registered for GST?
                          </label>

                          <ul className="system-main">
                            <li className="main-text">
                              {" "}
                              <input
                                id="gstYes"
                                type="radio"
                                name="isGST"
                                className="input-text"
                                value="true"
                                onChange={handleInputCustomerDetailsRadio}
                                checked={formdata?.ownerDetails?.isGST === true}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="gstYes"
                              >
                                Yes
                              </label>
                            </li>
                            <li>
                              {" "}
                              <input
                                id="gstNo"
                                type="radio"
                                name="isGST"
                                className="input-text"
                                value="false"
                                onChange={handleInputCustomerDetailsRadio}
                                checked={
                                  formdata?.ownerDetails?.isGST === false
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="gstNo"
                              >
                                No
                              </label>
                            </li>
                          </ul>
                        </div>
                        <input
                          className="detail-input"
                          type="number"
                          name="ABN"
                          placeholder="ABN"
                          value={formdata?.ownerDetails?.ABN || ""}
                          onChange={handleInputCustomerDetails}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 11) {
                              window.alert("ABN number must be of 11 digits.");
                              e.target.value = " ";
                            }
                          }}
                          autoComplete="off"
                          disabled={!formdata?.ownerDetails?.isGST}
                          required={formdata.ownerDetails.isGST === true}
                        />
                        <input
                          className="detail-input gst-space"
                          type="text"
                          name="gstNo"
                          placeholder="GST No."
                          value={formdata?.ownerDetails?.gstNo || ""}
                          onChange={handleInputCustomerDetails}
                          disabled={formdata?.ownerDetails?.isGST !== true}
                          autoComplete="off"
                        />
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "17px",
                    }}
                  >
                    {/* <ul className="system-main">
                  <li className="checkbox-same"> */}
                    <input
                      className="input-same"
                      type="checkbox"
                      id="sameDetails"
                      onChange={handleCheckboxChange}
                      disabled={formdata?.installingFor !== "Individual"}
                      checked={isChecked}
                    />
                    <label
                      style={{
                        cursor: "pointer",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                      htmlFor="sameDetails"
                    >
                      Same as Above
                    </label>
                    {/* </li>
                </ul> */}
                  </div>
                </div>

                <div className="panel-information">
                  <div className="panel-main-bg postal-address">
                    <p className="sectionTitleText">Co-Owner Details</p>
                  </div>
                  <div className="main-detail">
                    <form method="post">
                      <input
                        className="detail-input"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        maxLength={30}
                        value={formdata?.coOwnerDetail?.firstName || ""}
                        onChange={handleInputCoOwnerDetails}
                        disabled={isChecked}
                        style={{ width: "280px" }}
                      />
                      <input
                        type="text"
                        name="lastName"
                        className="detail-input"
                        placeholder="Last Name"
                        maxLength={30}
                        value={formdata?.coOwnerDetail?.lastName || ""}
                        onChange={handleInputCoOwnerDetails}
                        disabled={isChecked}
                        style={{ width: "280px" }}
                      />
                      <input
                        className="detail-input"
                        type="email"
                        name="emailId"
                        placeholder="Email ID"
                        value={formdata?.coOwnerDetail?.emailId || ""}
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                        onChange={handleInputCoOwnerDetails}
                        disabled={isChecked}
                        style={{ width: "320px" }}
                      />

                      <input
                        className="detail-input"
                        type="text"
                        name="mobileNum"
                        placeholder="Mobile Number"
                        value={formdata?.coOwnerDetail?.mobileNum || ""}
                        onChange={handleInputCoOwnerDetails}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 12) {
                            window.alert("Mobile number must be of 12 digits.");
                            e.target.value = " ";
                          }
                        }}
                        disabled={isChecked}
                      />

                      <input
                        className="detail-input phone-space"
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone No"
                        value={formdata?.coOwnerDetail?.phoneNumber || ""}
                        onChange={handleInputCoOwnerDetails}
                        onKeyDown={(e) => {
                          if (e.target.value.length > 12) {
                            window.alert("Phone number must be of 12 digits.");
                            e.target.value = " ";
                          }
                        }}
                        disabled={isChecked}
                      />
                    </form>
                  </div>
                </div>
                <div className="panel-information">
                  <div className="address-bg">
                    <p className="address-text sectionTitleText">
                      Installation Address
                    </p>
                  </div>
                  <div>
                    <div className="search-auto">
                      <div className="Signup-autoAdress-border">
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleAddressChange}
                          onSelect={handleSelect}
                          searchOptions={{
                            componentRestrictions: { country: "au" },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div
                              className="address-input"
                              style={{ marginLeft: " 10px" }}
                            >
                              <input
                                className="Signup-autoAdress-input"
                                id="address"
                                {...getInputProps({
                                  type: "text",
                                  placeholder: "Search Address",
                                })}
                              />
                              <div className="address-auto-complete">
                                {loading ? <div>Loading</div> : null}
                                {suggestions &&
                                  suggestions.length > 0 &&
                                  suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                      color: suggestion.active
                                        ? "#fff"
                                        : "#000",
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <div className="address-auto-complete"></div>
                      </div>
                    </div>
                    <div className="address-info">
                      <div className="Signup-Adress-container">
                        <div className="Signup-autoAdress-container"></div>
                        <div>
                          <div className="main-address-block">
                            <div className="row">
                              <div className="address-main-boxes address-box">
                                <select
                                  className="address-type"
                                  name="addressType"
                                  value={
                                    formdata?.installationAddress
                                      ?.addressType || ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  style={{color:!formdata?.installationAddress?.addressType ?"grey":"black",fontWeight:!formdata?.installationAddress?.addressType?"400":"600" }}
                              
                                >
                                  <option value="" disabled>
                                    Address Type
                                  </option>
                                  {addressTypeOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="address-main-boxes">
                                <select
                                  className="address-type"
                                  name="unitType"
                                  value={
                                    formdata?.installationAddress?.unitType ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  style={{color:!formdata?.installationAddress?.unitType ?"grey":"black",fontWeight:!formdata?.installationAddress?.unitType?"400":"600" }}
                                >
                                  <option defaultValue disabled>
                                    Unit Type
                                  </option>
                                  <option value="">Not a Unit</option>
                                  {unitOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="unit-main-boxes">
                                <input
                                  className="unit-no"
                                  placeholder="Unit Number"
                                  name="unitNumber"
                                  value={
                                    formdata?.installationAddress?.unitNumber ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  onKeyDown={(e) => {
                                    if (e.target.value.length > 8) {
                                      window.alert(
                                        "Unit number must be less than or equal to 8 digits."
                                      );
                                      e.target.value = " ";
                                    }
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="address-main-boxes address-box">
                                <input
                                  className="address-type"
                                  placeholder="Street Number"
                                  maxLength="6"
                                  name="streetNumber"
                                  value={
                                    formdata.installationAddress.streetNumber ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  onKeyDown={(e) => {
                                    if (e.target.value.length > 6) {
                                      window.alert(
                                        "Street number must be less than or equal to 6 digits."
                                      );
                                      e.target.value = " ";
                                    }
                                  }}
                                />

                                <span className=""></span>
                              </div>
                              <div className="address-main-boxes">
                                <input
                                  className="address-type"
                                  type="text"
                                  maxLength={50}
                                  name="streetName"
                                  placeholder="Street Name"
                                  value={
                                    formdata.installationAddress.streetName ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                />
                              </div>
                              <div className="unit-main-boxes">
                                <select
                                  className="street-type"
                                  name="streetType"
                                  value={
                                    formdata.installationAddress.streetType ||
                                    ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  style={{color:!formdata?.installationAddress?.streetType ?"grey":"black",fontWeight:!formdata?.installationAddress?.streetType?"400":"600" }}
                              
                                >
                                  <option value="" disabled>
                                    Street Type
                                  </option>
                                  {streetOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="row">
                              <div className="address-main-boxes address-box">
                                <input
                                  className="address-type"
                                  type="text"
                                  maxLength={25}
                                  name="suburb"
                                  placeholder="Suburb"
                                  value={
                                    formdata.installationAddress.suburb || ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                />
                              </div>
                              <div className="state-main-boxes">
                                <select
                                  className="state-code"
                                  value={
                                    formdata.installationAddress.state || ""
                                  }
                                  name="state"
                                  onChange={handleInputsInstallerAddress}
                                  style={{color:!formdata?.installationAddress?.state ?"grey":"black",fontWeight:!formdata?.installationAddress?.state?"400":"600" }}
                              
                                >
                                  <option value="" disabled>
                                    State
                                  </option>
                                  {stateOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="address-postcode">
                                <input
                                  className="address-type"
                                  type="number"
                                  placeholder="Post Code"
                                  name="postCode"
                                  value={
                                    formdata.installationAddress.postCode || ""
                                  }
                                  onChange={handleInputsInstallerAddress}
                                  onKeyDown={(e) => {
                                    if (e.target.value.length > 4) {
                                      window.alert(
                                        "Post Code must be equal to 4 digits."
                                      );
                                      e.target.value = " ";
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="map-section">
                            {address ? (
                              <div style={{ position: "relative", right: "0" }}>
                                <GoogleMap
                                  mapContainerStyle={{
                                    width: "421px",
                                    height: "196px",
                                  }}
                                  center={selectedLocation}
                                  zoom={15}
                                  onLoad={onMapLoad}
                                  options={{
                                    mapTypeControl: true,
                                  }}
                                >
                                  {selectedLocation && (
                                    <MarkerF
                                      position={{
                                        lat: selectedLocation.lat,
                                        lng: selectedLocation.lng,
                                      }}
                                    />
                                  )}
                                </GoogleMap>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <span className="border-bottom"></span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="row">
                    <div className="postal-address">
                      <div className="postal-bg">
                        <p className="sectionTitleText">
                          Different Postal Address
                        </p>
                        <div className="row">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <ul className="system-main">
                              <li className="main-checkbox">
                                {" "}
                                <input
                                  className="input-same"
                                  type="checkbox"
                                  id="sameAddress"
                                  onChange={handleAddressCheckboxChange}
                                  checked={sameAsAboveAddress}
                                  disabled={
                                    formdata?.installationAddress
                                      ?.addressType === "Postal"
                                  }
                                />
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="sameAddress"
                                >
                                  <b>Same as Above</b>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <ul className="system-main">
                          {formdata?.installationAddress?.addressType ===
                          "Physical" ? (
                            <li className="main-text">
                              {" "}
                              <input
                                id="poaddress"
                                type="radio"
                                name="radio"
                                className="input-text"
                                onClick={poBoxAdressclicked}
                                checked={
                                  (formdata?.postalAddress?.POAddress &&
                                    Object.keys(
                                      formdata?.postalAddress?.POAddress
                                    ).every(
                                      (key) =>
                                        formdata?.postalAddress?.POAddress[
                                          key
                                        ] === formdata?.installationAddress[key]
                                    ) &&
                                    sameAsAboveAddress) ||
                                  poBoxAdres
                                }
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="poaddress"
                              >
                                Address
                              </label>
                            </li>
                          ) : (
                            <li className="main-text">
                              {" "}
                              <input
                                id="pobox"
                                type="radio"
                                name="radio"
                                className="input-text"
                                onClick={poBoxclicked}
                                checked={poBox}
                                disabled={sameAsAboveAddress}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  fontSize: "0.8rem",
                                }}
                                htmlFor="pobox"
                              >
                                Po Box No
                              </label>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {formdata?.installationAddress?.addressType == "Postal" ? (
                    <div className="row">
                      <div className="row">
                        <div className="address-main-boxes address-box suburb-space">
                          <input
                            className="address-type"
                            type="number"
                            placeholder="PO Box No."
                            name="POBoxNo"
                            value={
                              formdata?.postalAddress?.POBox?.POBoxNo || ""
                            }
                            onChange={handleInputsPostalAddressPOBoxPOBoxNo}
                          />
                        </div>

                        <div className="address-main-boxes address-box suburb-space">
                          <input
                            className="address-type"
                            type="text"
                            placeholder="Area"
                            name="postalArea"
                            value={
                              formdata?.postalAddress?.POBox?.postalArea || ""
                            }
                            onChange={handleInputsPostalAddressPOBoxPOBoxNo}
                          />
                        </div>
                        <div className="address-main-boxes address-box suburb-space">
                          <input
                            className="address-type"
                            type="number"
                            name="postalCode"
                            placeholder="Post Code"
                            value={
                              formdata?.postalAddress?.POBox?.postalCode || ""
                            }
                            onChange={handleInputsPostalAddressPOBoxPOBoxNo}
                            onKeyDown={(e) => {
                              if (e.target.value.length > 4) {
                                window.alert(
                                  "Post Code must be equal to 4 digits."
                                );
                                e.target.value = " ";
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {formdata?.installationAddress?.addressType == "Physical" ? (
                    manualButtonPostal ? (
                      <div className="row">
                        <div className="row">
                          <div className="main-address-section">
                            <div className="address-main-boxes address-box">
                              <select
                                className="address-type"
                                name="addressType"
                                value={
                                  formdata?.postalAddress?.POAddress
                                    ?.addressType || ""
                                }
                                onChange={
                                  handleInputsInstallerPostalAddressDropDown
                                }
                                disabled={sameAsAboveAddress}
                              >
                                <option value="" disabled>
                                  Address Type
                                </option>
                                {addressTypeOptions.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="address-main-boxes">
                              <select
                                className="address-type"
                                name="unitType"
                                value={
                                  formdata?.postalAddress?.POAddress
                                    ?.unitType || ""
                                }
                                onChange={
                                  handleInputsInstallerPostalAddressDropDown
                                }
                                disabled={sameAsAboveAddress}
                              >
                                <option value="" disabled>
                                  Unit Type
                                </option>
                                {unitOptions.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="unit-main-boxes">
                              <input
                                className="unit-no"
                                placeholder="Unit Number"
                                name="unitNumber"
                                value={
                                  formdata?.postalAddress?.POAddress
                                    ?.unitNumber || ""
                                }
                                onChange={
                                  handleInputsPostalAddressPOBoxPOAddress
                                }
                                onKeyDown={(e) => {
                                  if (e.target.value.length > 8) {
                                    window.alert(
                                      "Unit number must be less than or equal to 8 digits."
                                    );
                                    e.target.value = " ";
                                  }
                                }}
                                disabled={sameAsAboveAddress}
                              />
                            </div>
                            <div className="address-main-boxes address-box street-space">
                              <input
                                className="address-type"
                                placeholder="Street Number"
                                name="streetNumber"
                                value={
                                  formdata?.postalAddress?.POAddress
                                    ?.streetNumber || ""
                                }
                                onChange={
                                  handleInputsPostalAddressPOBoxPOAddress
                                }
                                onKeyDown={(e) => {
                                  if (e.target.value.length > 6) {
                                    window.alert(
                                      "Street number must be less than or equal to 6 digits."
                                    );
                                    e.target.value = " ";
                                  }
                                }}
                                disabled={sameAsAboveAddress}
                              />

                              <span className=""></span>
                            </div>
                            <div className="address-main-boxes">
                              <input
                                className="address-type"
                                type="text"
                                placeholder="Street Name"
                                maxLength={50}
                                name="streetName"
                                value={
                                  formdata?.postalAddress?.POAddress
                                    ?.streetName || ""
                                }
                                onChange={
                                  handleInputsPostalAddressPOBoxPOAddress
                                }
                                disabled={sameAsAboveAddress}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="unit-main-boxes">
                            <select
                              className="street-type"
                              name="streetType"
                              value={
                                formdata?.postalAddress?.POAddress
                                  ?.streetType || ""
                              }
                              onChange={
                                handleInputsInstallerPostalAddressDropDown
                              }
                              disabled={sameAsAboveAddress}
                            >
                              <option value="" disabled>
                                Street Type<span className=""></span>
                              </option>
                              {streetOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="address-main-boxes address-box suburb-space">
                            <input
                              className="address-type"
                              type="text"
                              name="suburb"
                              placeholder="Suburb"
                              maxLength={10}
                              value={
                                formdata?.postalAddress?.POAddress?.suburb || ""
                              }
                              onChange={handleInputsPostalAddressPOBoxPOAddress}
                              disabled={sameAsAboveAddress}
                            />
                          </div>
                          <div className="state-main-boxes">
                            <select
                              className="state-code"
                              name="state"
                              value={
                                formdata?.postalAddress?.POAddress?.state || ""
                              }
                              onChange={
                                handleInputsInstallerPostalAddressDropDown
                              }
                              disabled={sameAsAboveAddress}
                            >
                              <option value="" disabled>
                                State
                              </option>
                              {stateOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="address-postcode">
                            <input
                              className="address-type"
                              type="number"
                              placeholder="Post Code"
                              name="postCode"
                              value={
                                formdata?.postalAddress?.POAddress?.postCode ||
                                ""
                              }
                              onChange={handleInputsPostalAddressPOBoxPOAddress}
                              onKeyDown={(e) => {
                                if (e.target.value.length > 4) {
                                  window.alert(
                                    "Post Code must be equal to 4 digits."
                                  );
                                  e.target.value = " ";
                                }
                              }}
                              disabled={sameAsAboveAddress}
                            />
                          </div>
                          <button
                            className="Signup-switchingAdress-btn"
                            onClick={swithingPostalAddress}
                            type="button"
                          >
                            Switch to Autocomplete
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="Signup-autoAdress-container">
                        <div
                          className="Signup-autoAdress-container"
                          style={{ marginLeft: "10px" }}
                        >
                          <div className="Signup-autoAdress-border">
                            <PlacesAutocomplete
                              value={postalAddress}
                              onChange={SetPostalAddress}
                              onSelect={handlePostalSelect}
                              searchOptions={{
                                componentRestrictions: { country: "au" },
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div className="address-input">
                                  <input
                                    className="Signup-autoAdress-input"
                                    id="address"
                                    {...getInputProps({
                                      type: "text",
                                      placeholder: "Address",
                                    })}
                                  />
                                  <div className="address-auto-complete">
                                    {loading ? <div>Loading</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "#41b6e6"
                                          : "#fff",
                                        color: suggestion.active
                                          ? "#fff"
                                          : "#000",
                                      };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            <div className="address-auto-complete"></div>
                          </div>
                          <div className="Signup-menualAdress-container">
                            <button
                              className="Signup-switchingAdress-btn"
                              onClick={swithingPostalAddress}
                            >
                              Enter Manually
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null}

                  {/* {manualButtonPostal === false ? (
                  <div className="Signup-autoAdress-container">
                    <div className="Signup-autoAdress-container">
                      <div className="Signup-autoAdress-border">
                        <PlacesAutocomplete
                          value={postalAddress}
                          onChange={SetPostalAddress}
                          onSelect={handlePostalSelect}
                          searchOptions={{
                            componentRestrictions: { country: "au" },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="address-input">
                              <input
                                className="Signup-autoAdress-input"
                                id="address"
                                {...getInputProps({
                                  type: "text",
                                  placeholder: "Address",
                                })}
                              />
                              <div className="address-auto-complete">
                                {loading ? <div>Loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                    color: suggestion.active ? "#fff" : "#000",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        <div className="address-auto-complete"></div>
                      </div>
                      <div className="Signup-menualAdress-container">
                        <button
                          className="Signup-switchingAdress-btn"
                          onClick={swithingPostalAddress}
                        >
                          Enter Manually
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null} */}
                </div>

                <div className="install-bg">
                  <div className="postal-address">
                    <p className="sectionTitleText ">Installation Type</p>
                  </div>
                </div>

                <div className="row">
                  <div className="installation-Type">
                    <div>
                      <form
                        action="post"
                        style={{ display: "flex", gap: "10px" }}
                        className="installationTypeForm"
                      >
                        <label
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "0px",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          htmlFor="firstTime"
                        >
                          <input
                            id="firstTime"
                            type="radio"
                            name="installationType"
                            className="radio-rirst"
                            value="First Time"
                            onChange={handleInputsInstallationDetailsRadio}
                            checked={
                              formdata?.installationDetails
                                ?.installationType === "First Time"
                            }
                          />
                          First Time{" "}
                        </label>
                        <label
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "0px",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          htmlFor="additional"
                        >
                          <input
                            id="additional"
                            type="radio"
                            name="installationType"
                            className="radio-second"
                            value="Additional"
                            onChange={handleInputsInstallationDetailsRadio}
                            checked={
                              formdata?.installationDetails
                                ?.installationType === "Additional"
                            }
                          />{" "}
                          Additional{" "}
                        </label>
                        <label
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "0px",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          htmlFor="extension"
                        >
                          <input
                            id="extension"
                            type="radio"
                            name="installationType"
                            className="radio-third"
                            value="Extension"
                            onChange={handleInputsInstallationDetailsRadio}
                            checked={
                              formdata?.installationDetails
                                ?.installationType === "Extension"
                            }
                          />{" "}
                          Extension{" "}
                        </label>
                        <label
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "0px",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          htmlFor="replacement"
                        >
                          <input
                            id="replacement"
                            type="radio"
                            name="installationType"
                            className="radio-forth"
                            value="Replacement"
                            onChange={handleInputsInstallationDetailsRadio}
                            checked={
                              formdata?.installationDetails
                                ?.installationType === "Replacement"
                            }
                          />{" "}
                          Replacement{" "}
                        </label>
                      </form>
                    </div>
                  </div>
                  {formdata?.installationDetails?.installationType ===
                    "Additional" ||
                  formdata?.installationDetails?.installationType ===
                    "Extension" ? (
                    <div>
                      <div>
                        <span className="note">
                          Note:{" "}
                          <span className="note-text">
                            Selecting an inappropriate installation type will
                            delay the installation process by 15 days.
                          </span>
                        </span>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div className="row">
                            <div className="panel-location panel-bg">
                              <input
                                className="address-type"
                                type="text"
                                placeholder="Old Panel Location"
                                name="newPanelLocation"
                                value={
                                  formdata?.installationDetails
                                    ?.newPanelLocation || ""
                                }
                                onChange={handleInputsInstallationDetails}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="panel-location panel-bg">
                              <input
                                className="address-type"
                                type="number"
                                placeholder="Kilo Watt"
                                name="kilowatt"
                                value={
                                  formdata?.installationDetails?.kilowatt || ""
                                }
                                onChange={handleInputsInstallationDetails}
                                onKeyDown={(e) => {
                                  if (e.target.value.length > 5) {
                                    window.alert(
                                      "Kilo Watt must be equal to or less than 5 digits."
                                    );
                                    e.target.value = " ";
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="panel-location remark-bg ">
                            <textarea
                              className="address-type"
                              style={{
                                height: "100px",
                                border: "none",
                              }}
                              placeholder="Remarks"
                              type="text"
                              name="remarks"
                              maxLength={500}
                              value={
                                formdata?.installationDetails?.remarks || ""
                              }
                              onChange={handleInputsInstallationDetails}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : formdata?.installationDetails?.installationType ===
                    "Replacement" ? (
                    <>
                      <span className="note">
                        Note:{" "}
                        <span className="note-text">
                          Selecting an inappropriate installation type will
                          delay the installation process by 15 days.
                        </span>
                      </span>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                          paddingTop: "20px",
                        }}
                      >
                        <span className="note">Comments: </span>
                        <div
                          className="remark-bg"
                          style={{ marginLeft: "20px" }}
                        >
                          <input
                            className="address-type"
                            type="text"
                            name="remarks"
                            value={formdata?.installationDetails?.remarks}
                            defaultValue={
                              "This installation will be a replacement"
                            }
                            onChange={(e) => handleInputsInstallationDetails(e)}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="row">
                    <span className="border-bottom"></span>
                  </div>
                </div>

                <div className="install-bg">
                  <div className="postal-address">
                    <p className="sectionTitleText ">Site Attributes</p>
                  </div>
                  <div style={{ display: "flex", paddingLeft: "13px" }}>
                    <div className="mainmodel-boxes">
                      <select
                        className="installing-type"
                        name="roof"
                        value={formdata?.siteAttributes?.roof || ""}
                        onChange={handleInputSiteDetails}
                        style={{color:!formdata?.siteAttributes?.roof ?'grey':"black",fontWeight:!formdata?.siteAttributes?.roof?"400":"600"}}
                      >
                        <option value="" disabled>
                          Roof
                        </option>
                        {roofTypeOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mainmodel-boxes">
                      <select
                        className="installing-type"
                        name="storey"
                        value={formdata?.siteAttributes?.storey || ""}
                        onChange={handleInputSiteDetails}
                        style={{color:!formdata?.siteAttributes?.storey ?'grey':"black",fontWeight:!formdata?.siteAttributes?.storey?"400":"600"}}
                      >
                      <option value="" disabled>
                          Storey
                        </option>
                        {storeyTypeOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mainmodel-boxes">
                      <select
                        className="installing-type"
                        name="panelType"
                        value={formdata?.siteAttributes?.panelType || ""}
                        onChange={handleInputSiteDetails}
                        style={{color:!formdata?.siteAttributes?.panelType ?'grey':"black",fontWeight:!formdata?.siteAttributes?.panelType?"400":"600"}}
 
                      >
                        <option value="" disabled>
                          Panel Type
                        </option>
                        {panelTypeOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mainmodel-boxes">
                      <select
                        className="installing-type"
                        name="phase"
                        value={formdata?.siteAttributes?.phase || ""}
                        onChange={handleInputSiteDetails}
                        style={{color:!formdata?.siteAttributes?.phase ?'grey':"black",fontWeight:!formdata?.siteAttributes?.phase?"400":"600"}}
 
                      >
                        <option value="" disabled>
                          Phase
                        </option>
                        {phaseTypeOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <span className="border-bottom"></span>
                <br />

                <div className="install-bg">
                  <div className="postal-address">
                    <p className="sectionTitleText">Installation Details</p>
                  </div>
                  <div className="main-detail">
                    <input
                      className="detail-input"
                      type="date"
                      name="installingDate"
                      placeholder="Installation Date"
                      value={formdata?.installationDate?.installingDate || ""}
                      onChange={handleInputsInstallationDate}
                      // min={new Date().toISOString().split("T")[0]}
                    />
                    <div className="relation-boxes">
                      <input
                        className="relation-type"
                        type="text"
                        name="STCDeemingPeriod"
                        placeholder="STC Deeming Period"
                        value={
                          formatDateForDeemingPeriod(
                            formdata?.installationDate?.STCDeemingPeriod
                          ) || ""
                        }
                        onChange={handleInputsInstallationDate}
                        readOnly
                      />
                    </div>
                    <div className="relation-boxes">
                      <select
                        className="relation-type"
                        name="mountingType"
                        value={formdata?.installationDate?.mountingType || ""}
                        onChange={handleInputsInstallationDate}
                        style={{color:!formdata?.installationDate?.mountingType ?"grey":"black",fontWeight:!formdata?.installationDate?.mountingType?"400":"600" }}
                      >
                        <option value="" disabled>
                          Mounting Type
                        </option>
                        {mountingTypeOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{ display: "flex" }}
                      className="InstallationDetailsGridSection"
                    >
                      <li>
                        {" "}
                        <div className="system-boxes">
                          <select
                            className="system-type"
                            name="connectionType"
                            value={
                              formdata?.gridConnectionDetail?.connectionType ||
                              ""
                            }
                            onChange={
                              handleInputIsGridConnectionDetailsSystemType
                            }
                            style={{color:!formdata?.gridConnectionDetail?.connectionType ?"grey":"black",fontWeight:!formdata?.gridConnectionDetail?.connectionType?"400":"600" }}
                          >
                            <option value="" disabled>
                              Grid Connection Type
                            </option>
                            {connectionTypeOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </li>
                      <li>
                        {" "}
                        <div className="connection-boxes">
                          <select
                            className="system-type"
                            name="systemType"
                            value={
                              formdata?.gridConnectionDetail?.systemType || ""
                            }
                            onChange={
                              handleInputIsGridConnectionDetailsSystemType
                            }
                            disabled={
                              formdata?.gridConnectionDetail?.connectionType ===
                              "Stand-alone (not connected to an electricity grid)"
                            }
                            style={{color:!formdata?.gridConnectionDetail?.systemType ?"grey":"black",fontWeight:!formdata?.gridConnectionDetail?.systemType?"400":"600" }}
                         
                          >
                            <option value="" disabled>
                              System Type
                            </option>
                            {systemTypeOptions.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </li>
                      <li>
                        <input
                          className="national-input"
                          type="text"
                          name="nmi"
                          placeholder="National Metering Identifier (NMI)"
                          minLength={10}
                          maxLength={11}
                          value={formdata?.gridConnectionDetail?.nmi || ""}
                          onChange={handleInputIsGridConnectionDetails}
                          onKeyUp={handleNMI}
                          onKeyDown={(e) => {
                            if (e.target.value.length > 11) {
                              window.alert(
                                "NMI number must be of 10 or 11 digits."
                              );
                              e.target.value = " ";
                            }
                          }}
                          disabled={
                            formdata?.gridConnectionDetail?.connectionType ===
                            "Stand-alone (not connected to an electricity grid)"
                          }
                        />
                      </li>
                    </div>
                  </div>

                  <div className="row">
                    <input
                      className="application-input"
                      placeholder="Grid Application Reference No"
                      type="text"
                      name="gridAppReferenceNo"
                      maxLength={20}
                      value={
                        formdata?.gridConnectionDetail?.gridAppReferenceNo || ""
                      }
                      onChange={handleInputIsGridConnectionDetails}
                      disabled={
                        formdata?.gridConnectionDetail?.connectionType ===
                        "Stand-alone (not connected to an electricity grid)"
                      }
                    />
                    <input
                      className="meter-input"
                      type="number"
                      name="meterNum"
                      placeholder="Meter Number"
                      value={formdata?.gridConnectionDetail?.meterNum || ""}
                      onChange={handleInputIsGridConnectionDetails}
                      onKeyDown={(e) => {
                        if (e.target.value.length > 11) {
                          window.alert("Meter number must be of 11 digits.");
                          e.target.value = " ";
                        }
                      }}
                      disabled={
                        formdata?.gridConnectionDetail?.connectionType ===
                        "Stand-alone (not connected to an electricity grid)"
                      }
                    />
                    <select
                      className="meter-input"
                      name="distributor"
                      value={formdata?.gridConnectionDetail?.distributor || ""}
                      onChange={handleInputIsGridConnectionDetails}
                      disabled={
                        formdata?.gridConnectionDetail?.connectionType ===
                        "Stand-alone (not connected to an electricity grid)"
                      }
                      style={{color:!formdata?.gridConnectionDetail?.distributor?"grey":"black",fontWeight:!formdata?.gridConnectionDetail?.distributor?"400":"600"}}
                    >
                      <option value="" disabled>
                        Not a Distributor
                      </option>
                      <option value="">Not a Distributor</option>
                      {distributorOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="install-bg">
                  <div className="postal-address">
                    <p className="sectionTitleText">System detail:</p>
                  </div>
                  <div className="panel-information">
                    <div className="panel-main-bg postal-address">
                      <p className="sectionTitleText">Panel</p>
                    </div>
                  </div>
                  <div className="panel-information">
                    <input
                      className="search-panel"
                      style={{ width: "97%" }}
                      type="text"
                      placeholder="Search Panel"
                      value={searchTerms?.panels || ""}
                      autoComplete="off"
                      onChange={(e) =>
                        handleProductChange("panels", e.target.value)
                      }
                      onFocus={() => {
                        setActiveProductType("panels");
                        setShowPanelDropdown(true);
                      }}
                      onBlur={handlePanelBlur}
                    />
                    {showPanelDropdown && (
                      <table
                        className="createjob_panelbrand_ul-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "94%",
                          marginLeft: "21px",
                          maxHeight: "200px",
                          overflowY: "auto",
                          zIndex: 1000,
                        }}
                      >
                        <thead>
                          <tr className="PanelDataDropDown">
                            <th style={{ width: "35%" }}>Brand</th>
                            <th style={{ width: "25%" }}>Model</th>
                            <th style={{ width: "20%" }}> Watt</th>
                            <th style={{ width: "20%" }}>Expiry Date</th>
                          </tr>
                        </thead>
                        {/* <tbody className="tableBodyDropdown">
                          {panelData.length > 0 ? (
                            panelData.map((panel, index) => {
                              const formattedDate = moment(
                                panel["Expiry Date"]
                              ).format("DD/MM/YYYY");
                              return (
                                <tr
                                  className="PanelDataDropDown"
                                  key={index}
                                  onClick={() =>
                                    handleProductSelection("panel", panel)
                                  }
                                >
                                  <td style={{ width: "35%" }}>
                                    {panel.Brand}
                                  </td>
                                  <td style={{ width: "25%" }}>
                                    {panel["Model Number"]}
                                  </td>
                                  <td style={{ width: "20%" }}>{panel.Watt}</td>
                                  <td
                                    style={{
                                      width: "20%",
                                      color: isExpired(panel["Expiry Date"])
                                        ? "red"
                                        : "black",
                                    }}
                                  >
                                    {formattedDate}
                                    {isExpired(panel["Expiry Date"])
                                      ? "(Expired)"
                                      : null}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <td colSpan="4">No Panel Data found</td>
                            </tr>
                          )}
                        </tbody> */}
                        <tbody className="tableBodyDropdown">
  {panelData.length > 0 ? (
    panelData.map((panel, index) => {
      const formattedDate = moment(panel["Expiry Date"]).format("DD/MM/YYYY");
      const isPanelExpired = isExpired(panel["Expiry Date"]);

      return (
        // Conditionally render the entire <tr> based on whether the panel is expired
        !isPanelExpired && (
          <tr
            className="PanelDataDropDown"
            key={index}
            onClick={() => handleProductSelection("panel", panel)}
          >
            <td style={{ width: "35%" }}>{panel.Brand}</td>
            <td style={{ width: "25%" }}>{panel["Model Number"]}</td>
            <td style={{ width: "20%" }}>{panel.Watt}</td>
            <td style={{ width: "20%" }}>
              {formattedDate}
            </td>
          </tr>
        )
      );
    })
  ) : (
    <tr
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <td colSpan="4">No Panel Data found</td>
    </tr>
  )}
</tbody>

                      </table>
                    )}
                  </div>

                  <div className="panelAndAddButton">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {Array.isArray(formdata?.panelDetail?.panelInfo) &&
                        formdata?.panelDetail.panelInfo.map((panel, index) => (
                          <div key={index} className="panelAndAddButton">
                            <div className="InfoAndDeleteContainer">
                              <div>
                                <input
                                  type="radio"
                                  name="selectedPanel"
                                  style={{ marginLeft: "4px" }}
                                  checked={selectedPanelIndex === index}
                                  onChange={() => handleRadioChange(index)}
                                />
                                <input
                                  className="brand-input"
                                  style={{ marginLeft: "4px" }}
                                  type="text"
                                  placeholder="Brand"
                                  value={panel.panelBrand || ""}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="invertermodel-boxes">
                                <select
                                  name="panelModel"
                                  className="installing-type"
                                  value={panel.panelModel}
                                  defaultValue={""}
                                  onChange={(e) =>
                                    handlePanelModelChange(e, index)
                                  }
                                >
                                  <option value="" disabled>
                                    Model
                                  </option>
                                  {panelBrandList[index]?.map(
                                    (panelModel, idx) => (
                                      <option
                                        key={idx}
                                        value={panelModel["Model Number"]}
                                      >
                                        {panelModel["Model Number"]}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>

                              <input
                                className="supplier-input"
                                type="text"
                                style={{ display: "none" }}
                                placeholder="Supplier"
                                value={panel.panelSupplier || ""}
                                readOnly
                              />

                              <input
                                className="quantity-text"
                                placeholder="Power"
                                id="panelbrand-input"
                                type="number"
                                name="panelPower"
                                value={panel.panelPower || ""}
                                autoComplete="off"
                                readOnly
                              />

                              <input
                                className="quantity-text"
                                type="number"
                                placeholder="Quantity"
                                name="Quantity"
                                value={panel.Quantity || ""}
                                onChange={(e) =>
                                  handleInputsPanelInfo(e, index)
                                }
                              />
                              {ismultipleFieldsPanel ||
                              formdata?.panelDetail?.panelInfo?.length ? (
                                <div style={{ height: "100%" }}>
                                  <Tooltip title="Delete Panel Details">
                                    <button
                                      type="button"
                                      className="delete-field-button"
                                      onClick={() =>
                                        handleDeleteField("panel", index)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </Tooltip>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))}
                    </div>

                    {/* <div class="paneladd-btn">
                    <Tooltip title="Add Panel">
                      <button
                        type="button"
                        className="plus-add"
                        onClick={handleAddPanelInfo}
                      >
                        Add
                      </button>
                    </Tooltip>
                  </div> */}
                  </div>

                  <div className="row">
                    <form>
                      <input
                        className="systemmain-input"
                        type="number"
                        placeholder="System kW"
                        name="systemKw"
                        value={wattage || formdata?.panelDetail?.wattage || ""}
                        readOnly
                      />
                      <input
                        className="numberput-input"
                        type="number"
                        placeholder="Number of STCs"
                        name="stc"
                        value={stc || formdata?.panelDetail?.stc || ""}
                        readOnly
                      />

                      <input
                        className="finanacial-input"
                        type="number"
                        placeholder="Financial Payment"
                        name="financialPayment"
                        value={formdata?.panelDetail?.financialPayment || ""}
                        readOnly
                      />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "65px",
                        }}
                      >
                        <span className="job-panel" htmlFor="" required>
                          Would you like to assign panel to this job now?
                        </span>

                        <ul className="main-text">
                          <li style={{ marginTop: "12px" }}>
                            {" "}
                            <input
                              className="input-text"
                              type="radio"
                              name="isAssignPanelJob"
                              id="isAssignPanelJobYes"
                              value="true"
                              onChange={handleInputsPanelInfo}
                              checked={
                                formdata?.panelDetail?.isAssignPanelJob === true
                              }
                              onClick={() => {
                                handleRadioChange(0);
                                handleInverterRadioChange(0);
                                handleBatteryRadioChange(0);
                              }}
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="isAssignPanelJobYes"
                            >
                              Yes (Non-SPV)
                            </label>
                          </li>
                          <li style={{ marginTop: "12px" }}>
                            {" "}
                            <input
                              className="input-text"
                              type="radio"
                              style={{ marginLeft: "15px" }}
                              name="isAssignPanelJob"
                              id="isAssignPanelJobNo"
                              value="false"
                              onChange={handleInputsPanelInfo}
                              checked={
                                formdata?.panelDetail?.isAssignPanelJob ===
                                false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="isAssignPanelJobNo"
                            >
                              No (Onsite SPV)
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "70%",
                          }}
                        >
                          <a
                            href="#"
                            target="_self"
                            onClick={handleCopyPanelToClipboard}
                            style={{ paddingLeft: "20px" }}
                          >
                            <Tooltip
                              className="clipboard-copy"
                              title={panelSeriesToolTip}
                            >
                              <ContentPaste
                                sx={{ fontSize: "20px", marginTop: "0px" }}
                              />
                            </Tooltip>
                          </a>
                          {formdata?.panelDetail?.panelInfo?.some(
                            (item) => item.Quantity
                          ) && (
                            <span
                              style={{
                                fontSize: "25px",
                                color:
                                  panelCount ===
                                  getTotalQuantity(
                                    formdata?.panelDetail?.panelInfo
                                  )
                                    ? "green"
                                    : "black",
                              }}
                            >
                              {panelCount}/
                              {getTotalQuantity(
                                formdata?.panelDetail?.panelInfo
                              )}
                            </span>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              gap: "20px",
                              alignItems: "end",
                            }}
                          >
                            {formdata?.panelDetail?.isAssignPanelJob ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p style={{ marginLeft: "17px" }}>
                                  Add Serial Numbers for {manualPanelModel}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <textarea
                                    className="serialno-addinput"
                                    type="Number"
                                    placeholder="Add Serial Numbers"
                                    value={serialNumbers}
                                    onChange={(e) =>
                                      handleInputserialPanelNumbersChange(
                                        e,
                                        "panelSerialNumbers"
                                      )
                                    }
                                  />

                                  <Tooltip title="Add Serial Numbers">
                                    <button
                                      type="button"
                                      className="plus-add"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: "20px",
                                        alignItems: "center",
                                        background: "transparent",
                                        border: "none",
                                        gap: "5px",
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        handleConfirmSubmit("submit")
                                      }
                                    >
                                      Submit
                                    </button>
                                  </Tooltip>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {serialNumbers.length > 0 &&
                            formdata?.panelDetail?.isAssignPanelJob &&
                            serialNumbers?.map((serialList, index) => {
                              const serialArray = Array.isArray(serialList)
                                ? serialList.map((serial) => serial.trim())
                                : serialList
                                    .split(",")
                                    .map((serial) => serial.trim());
                              return (
                                <div
                                  key={index}
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {serialArray.map((serial, id) => (
                                    <div style={{ position: "relative" }}>
                                      <input
                                        id={id}
                                        className="serialno-addinput"
                                        type="text"
                                        placeholder="Serial Numbers"
                                        value={serial}
                                        name="serialNumber"
                                        onChange={handleInputPanelSerialNumber}
                                      />

                                      <div
                                        style={{
                                          position: "absolute",
                                          right: "5px",
                                          top: "10px",
                                        }}
                                      >
                                        <Tooltip title="Delete">
                                          <CloseRounded
                                            // onClick={() =>
                                            //   handleDeleteSerialNumber(index, id)
                                            // }
                                            onClick={() =>
                                              handleDeleteSerialNumber(
                                                index,
                                                id,
                                                "panel"
                                              )
                                            }
                                            sx={{ fontSize: "medium" }}
                                          />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              );
                            })}

                          {serialNumberLoad ? (
                            <FadeLoader color="#154397" />
                          ) : multiplePanelSerial?.length > 0 &&
                            !onSiteSerial &&
                            formdata?.panelDetail?.isAssignPanelJob ? (
                            multiplePanelSerial?.map((serial, index) => (
                              <div key={index} style={{ position: "relative" }}>
                                <input
                                  className="serialno-addinput"
                                  type="text"
                                  placeholder="Serial Number"
                                  name="serialNumber"
                                  value={serial?.serialNumber}
                                  onChange={(event) =>
                                    handleInputChange(event, index, serial?._id)
                                  }
                                />

                                <div
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "10px",
                                  }}
                                >
                                  <Tooltip title="Delete">
                                    <CloseRounded
                                      onClick={() =>
                                        handleConfirmSubmit(
                                          "delete",
                                          index,
                                          serial?._id,
                                          "panel"
                                        )
                                      }
                                      sx={{ fontSize: "medium" }}
                                    />
                                    {/* sx={{ fontSize: "medium" }} */}
                                    {/* /> */}
                                  </Tooltip>
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "10px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      display:
                                        editSerial !== serial?._id ||
                                        !saveButton
                                          ? "none"
                                          : "flex",
                                      paddingLeft: "15px",
                                    }}
                                    onClick={() =>
                                      handleSaveClick(
                                        serial?._id,
                                        serial?.serialNumber,
                                        "panelSerial"
                                      )
                                    }
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            ))
                          ) : null}

                          {openConfirmation ? (
                            <div
                              style={{
                                position: "fixed",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                backdropFilter: "blur(4px)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "1999",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  padding: "20px",
                                  borderRadius: "8px",
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                  zIndex: "999",
                                  width: "30%",
                                  height: "35%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="PopupBoxStyle">
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {`${
                                        openConfirmation?.type == "submit"
                                          ? "Submit Serial Numbers"
                                          : "Delete Serial Number"
                                      }`}
                                    </p>
                                  </div>
                                  <button
                                    className="add-model-close-btn"
                                    type="button"
                                    onClick={() => {
                                      setOpenConfirmation(null);
                                    }}
                                  >
                                    ✕
                                  </button>
                                </div>
                                <div
                                  style={{
                                    padding: "30px",
                                    display: "flex",
                                    gap: "20px",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p>{`${
                                    openConfirmation?.type == "submit"
                                      ? "Are you sure you want to submit?"
                                      : "Are you sure you want Delete this serial Number?"
                                  }`}</p>
                                  {openConfirmation?.type == "submit" ? (
                                    <p>
                                      {" "}
                                      You won't be able to Edit serial numbers
                                      once submitted
                                    </p>
                                  ) : null}
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "end",
                                    }}
                                  >
                                    <button
                                      className="filter-submit-btn"
                                      onClick={() => setOpenConfirmation(null)}
                                    >
                                      Cancel
                                    </button>
                                    {openConfirmation?.type == "submit" ? (
                                      <button
                                        type="button"
                                        className="filter-submit-btn"
                                        onClick={handleSerialNumbersSubmit}
                                      >
                                        Submit
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="filter-submit-btn"
                                        onClick={() => {
                                          deleteSerialNumberHandler(
                                            openConfirmation?.id,
                                            openConfirmation?.product
                                          );
                                        }}
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {!formdata?.panelDetail?.isAssignPanelJob
                            ? Array.from(
                                { length: totalPanelQuantity },
                                (_, index) => {
                                  const panel =
                                    panelSerialNumber &&
                                    panelSerialNumber[index];
                                  const serialNumber = panel
                                    ? panel.serialNumber
                                    : "";

                                  const panelModel = panel
                                    ? panel.panelModel
                                    : "";
                                  const imageUrl = panel
                                    ? panel.panelPhoto
                                    : "";
                                  const distance = panel
                                    ? panel.distance
                                    : null;
                                  const panelId = panel?._id;
                                  const handleInputClick = () => {
                                    if (serialNumber) {
                                      openPopupPanel(
                                        serialNumber,
                                        panelModel,
                                        imageUrl,
                                        distance,
                                        panelId
                                      );
                                    } else {
                                      if (
                                        formdata?.panelDetail
                                          ?.isAssignPanelJob === true
                                      ) {
                                        openPopupPanel();
                                      }
                                    }
                                  };
                                  return (
                                    <input
                                      className="serialno-addinput"
                                      type="text"
                                      placeholder="Serial Numbers"
                                      value={serialNumber}
                                      readOnly
                                      onClick={handleInputClick}
                                    />
                                  );
                                }
                              )
                            : null}
                          {popupOpenPanel && (
                            <div
                              style={{
                                position: "fixed",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                backdropFilter: "blur(4px)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "1999",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  padding: "20px",
                                  borderRadius: "8px",
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                  zIndex: "999",
                                  width: "50%",
                                  height: "90%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="PopupBoxStyle">
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "90%",
                                    }}
                                  >
                                    {/* {currentSerialNumber ? ( */}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p>
                                        Serial Number:{" "}
                                        <input
                                          className="serialno-addinput"
                                          type="text"
                                          placeholder="Serial Numbers"
                                          value={currentSerialNumber}
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              serialIndex,
                                              serialID,
                                              "panelSerial",
                                              "isSerial"
                                            )
                                          }
                                        />
                                      </p>
                                      <p>Panel Model: {currentModel}</p>
                                    </div>

                                    <div>
                                      <Button
                                        style={{
                                          display:
                                            editSerial !== serialID ||
                                            !saveButton
                                              ? "none"
                                              : "flex",
                                          paddingLeft: "15px",
                                        }}
                                        onClick={() =>
                                          handleSaveClick(
                                            serialID,
                                            currentSerialNumber,
                                            "panelSerial"
                                          )
                                        }
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </div>
                                  <button
                                    className="add-model-close-btn"
                                    type="button"
                                    onClick={closePopupPanel}
                                  >
                                    ✕
                                  </button>
                                </div>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "55%",
                                  }}
                                >
                                  <img
                                    style={{ width: "80%", height: "150%" }}
                                    src={currentImageUrl}
                                    alt="Serial Number Image"
                                  />
                                </div>
                                {currentDistance ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: "27%",
                                    }}
                                  >
                                    <span>
                                      Distance:{" "}
                                      {currentDistance > 1
                                        ? `${currentDistance} km`
                                        : `${currentDistance * 1000} m`}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* {!onSiteSerial && (
                      <>
                        <UploadButton
                          label="Upload Panel Scan Images /Drag and drop files here, or click to select files"
                          fileTypes={[
                            "image/png",
                            "image/jpeg",
                            "application/pdf",
                          ]}
                          formDataKey="panelSerialNumberPhotos"
                          handleFileUpload={handleFileUpload}
                          photos={photos}
                          token={token}
                          onSiteData={fetchOnSiteData}
                          jobId={jobId}
                          // serialScansImages={serialScanImages}
                        />
                      </>
                    )} */}
                    </form>
                  </div>

                  <div className="panel-information">
                    <div className="panel-main-bg postal-address">
                      <p className="sectionTitleText">Inverter</p>
                    </div>
                    <input
                      className="search-panel"
                      style={{ width: "97%" }}
                      type="text"
                      placeholder="Search Inverter"
                      value={searchTerms?.inverters || ""}
                      autoComplete="off"
                      onChange={(e) =>
                        handleProductChange("inverters", e.target.value)
                      }
                      onFocus={() => {
                        setActiveProductType("inverters");
                        setShowInverterDropdown(true);
                      }}
                      onBlur={handleInverterBlur}
                    />
                    {showInverterDropdown && (
                      <table
                        className="createjob_panelbrand_ul-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "94%",
                          marginLeft: "21px",
                          maxHeight: "200px",
                          overflowY: "auto",
                          zIndex: 1000,
                        }}
                      >
                        <thead>
                          <tr className="PanelDataDropDownHeader">
                            <th style={{ width: "35%" }}>Brand</th>
                            <th style={{ width: "30%" }}>Model</th>
                            <th style={{ width: "25%" }}>Series</th>
                            <th style={{ width: "20%" }}>Capacity (kW)</th>
                          </tr>
                        </thead>
                        <tbody className="tableBodyDropdown">
                          {inverterData.length > 0 ? (
                            inverterData.map((inverter, index) => (
                              <tr
                                className="PanelDataDropDown"
                                key={index}
                                onClick={() =>
                                  handleProductSelection("inverter", inverter)
                                }
                                style={{ padding: "0 10px" }}
                              >
                                <td style={{ width: "35%" }}>
                                  {inverter.Manufacturer}
                                </td>
                                <td style={{ width: "30%" }}>
                                  {inverter["Model Number"]}
                                </td>
                                <td style={{ width: "25%" }}>
                                  {inverter.Series}
                                </td>
                                <td style={{ width: "20%" }}>
                                  {inverter["AC Power (kW)"]}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <td colSpan="4">No Inverter Data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}

                    <div className="panelAndAddButton">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {Array.isArray(
                          formdata?.inverterDetail?.inverterInfo
                        ) &&
                          formdata?.inverterDetail?.inverterInfo.map(
                            (inverter, index) => (
                              <div key={index} className="panelAndAddButton">
                                <div className="InfoAndDeleteContainer">
                                  <div>
                                    <input
                                      type="radio"
                                      name="selectedInverter"
                                      style={{ marginLeft: "4px" }}
                                      checked={selectedInverterIndex === index}
                                      onChange={() =>
                                        handleInverterRadioChange(index)
                                      }
                                    />
                                    <input
                                      className="inverter-input"
                                      style={{ marginLeft: "4px" }}
                                      type="text"
                                      placeholder="Brand"
                                      value={inverter.inverterBrand || ""}
                                      autoComplete="off"
                                    />
                                  </div>
                                  <div className="invertermodel-boxes">
                                    <select
                                      name="inverterModel"
                                      className="installing-type"
                                      value={inverter.inverterModel}
                                      defaultValue={""}
                                      onChange={(e) =>
                                        handleInverterModelChange(e, index)
                                      }
                                    >
                                      <option value="" disabled>
                                        Model
                                      </option>
                                      {inverterBrandList[index]?.map(
                                        (inverterModel, idx) => (
                                          <option
                                            key={idx}
                                            value={
                                              inverterModel["Model Number"]
                                            }
                                          >
                                            {inverterModel["Model Number"]}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>

                                  <div className="invertermodel-boxes">
                                    <input
                                      name="inverterSeries"
                                      className="installing-type"
                                      placeholder="Series"
                                      value={inverter.inverterSeries || ""}
                                      readOnly
                                    />
                                  </div>

                                  <input
                                    style={{ display: "none" }}
                                    className="quantity-input"
                                    type="number"
                                    placeholder="Capacity kW"
                                    name="inverterCapacity"
                                    value={inverter.inverterCapacity || ""}
                                    readOnly
                                  />

                                  <input
                                    className="quantity-input"
                                    type="number"
                                    placeholder="Capacity kW"
                                    name="inverterCapacity"
                                    style={{ display: "none" }}
                                    value={inverter.inverterCapacity || ""}
                                    readOnly
                                  />

                                  <input
                                    className="quantity-input"
                                    type="number"
                                    placeholder="Quantity"
                                    name="Quantity"
                                    value={inverter.Quantity || ""}
                                    onChange={(e) =>
                                      handleInputsInverterInfo(e, index)
                                    }
                                  />
                                  {multiplefieldsInverter ||
                                  formdata?.inverterDetail?.inverterInfo
                                    .length ? (
                                    <div style={{ height: "100%" }}>
                                      <Tooltip title="Delete Inverter Details">
                                        <button
                                          type="button"
                                          className="delete-field-button"
                                          onClick={() =>
                                            handleDeleteField("inverter", index)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </Tooltip>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )
                          )}
                      </div>

                      {/* <div class="paneladd-btn">
                      <Tooltip title="Add Inverter">
                        <button
                          type="button"
                          className="plus-add"
                          onClick={handleAddInverterInfo}
                        >
                          Add
                        </button>
                      </Tooltip>
                    </div> */}
                    </div>

                    {inverterCapacityError ? (
                      <div>
                        <p
                          style={{
                            color: "red",
                            margin: "0px 20px 0 20px",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {`Selected Inverter does not match the requirements of selected Panels Wattage.`}
                        </p>
                      </div>
                    ) : null}
                    <div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          gap: "20px",
                          alignItems: "end",
                        }}
                      >
                        {formdata?.panelDetail?.isAssignPanelJob ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p style={{ marginLeft: "17px" }}>
                              Add Serial Numbers for {manualInverterModel}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <textarea
                                className="serialno-addinput"
                                type="Number"
                                placeholder="Add Serial Numbers"
                                value={inverterSerialNumbers}
                                onChange={(e) =>
                                  handleInputserialPanelNumbersChange(
                                    e,
                                    "inverterSerialNumber"
                                  )
                                }
                              />

                              <Tooltip title="Add Serial Numbers">
                                <button
                                  type="button"
                                  className="plus-add"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "20px",
                                    alignItems: "center",
                                    background: "transparent",
                                    border: "none",
                                    gap: "5px",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => handleConfirmSubmit("submit")}
                                >
                                  Submit
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {inverterSerialNumbers.length > 0 &&
                          formdata?.panelDetail?.isAssignPanelJob &&
                          inverterSerialNumbers?.map((serialList, index) => {
                            const serialArray = Array.isArray(serialList)
                              ? serialList.map((serial) => serial.trim())
                              : serialList
                                  .split(",")
                                  .map((serial) => serial.trim());
                            return (
                              <div
                                key={index}
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {serialArray.map((serial, id) => (
                                  <div style={{ position: "relative" }}>
                                    <input
                                      id={id}
                                      className="serialno-addinput"
                                      type="text"
                                      placeholder="Serial Numbers"
                                      value={serial}
                                      name="serialNumber"
                                      onChange={handleInputPanelSerialNumber}
                                    />

                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "5px",
                                        top: "10px",
                                      }}
                                    >
                                      <Tooltip title="Delete">
                                        <CloseRounded
                                          // onClick={() =>
                                          //   handleDeleteSerialNumber(index, id)
                                          // }
                                          onClick={() =>
                                            handleDeleteSerialNumber(
                                              index,
                                              id,
                                              "inverter"
                                            )
                                          }
                                          sx={{ fontSize: "medium" }}
                                        />
                                      </Tooltip>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            );
                          })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                        }}
                      >
                        <a
                          href="#"
                          target="_self"
                          onClick={handleCopyInverterToClipboard}
                          style={{ paddingLeft: "20px" }}
                        >
                          <Tooltip title={toolTip}>
                            <ContentPaste
                              className="clipboard-copy"
                              sx={{ fontSize: "20px" }}
                            />
                          </Tooltip>
                        </a>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {serialNumberLoad ? (
                            <FadeLoader color="#154397" />
                          ) : multipleInverterSerial?.length > 0 &&
                            !onSiteSerial &&
                            formdata?.panelDetail?.isAssignPanelJob ? (
                            multipleInverterSerial?.map((serial, index) => (
                              <div key={index} style={{ position: "relative" }}>
                                <input
                                  className="serialno-addinput"
                                  type="text"
                                  placeholder="Serial Number"
                                  name="serialNumber"
                                  value={serial?.serialNumber}
                                  onChange={(event) =>
                                    handleInputChange(
                                      event,
                                      index,
                                      serial?._id,
                                      "inverterSerialEdit"
                                    )
                                  }
                                />

                                <div
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "10px",
                                  }}
                                >
                                  <Tooltip title="Delete">
                                    <CloseRounded
                                      onClick={() =>
                                        handleConfirmSubmit(
                                          "delete",
                                          index,
                                          serial?._id,
                                          "inverter"
                                        )
                                      }
                                      sx={{ fontSize: "medium" }}
                                    />
                                    {/* sx={{ fontSize: "medium" }} */}
                                    {/* /> */}
                                  </Tooltip>
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "10px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      display:
                                        editSerial !== serial?._id ||
                                        !saveButton
                                          ? "none"
                                          : "flex",
                                      paddingLeft: "15px",
                                    }}
                                    onClick={() =>
                                      handleSaveClick(
                                        serial?._id,
                                        serial?.serialNumber,
                                        "inverterSerial"
                                      )
                                    }
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            ))
                          ) : null}
                          {!formdata?.panelDetail?.isAssignPanelJob
                            ? Array.from(
                                { length: totalInverterQuantity },
                                (_, index) => {
                                  const inverter =
                                    inverterSerialNumber &&
                                    inverterSerialNumber[index];
                                  const serialNumber = inverter
                                    ? inverter.serialNumber
                                    : "";
                                  const inverterModel = inverter
                                    ? inverter.inverterModel
                                    : "";
                                  const imageUrl = inverter
                                    ? inverter.inverterPhoto
                                    : "";
                                  const distance = inverter
                                    ? inverter.distance
                                    : null;
                                  const id = inverter ? inverter._id : null;
                                  const handleInputClick = () => {
                                    if (serialNumber || imageUrl) {
                                      openPopup(
                                        serialNumber,
                                        inverterModel,
                                        imageUrl,
                                        distance,
                                        index,
                                        id
                                      );
                                    }
                                    // else {
                                    //   openPopup();
                                    // }
                                  };
                                  return (
                                    <input
                                      className="serialno-addinput"
                                      type="text"
                                      placeholder="Serial Numbers"
                                      value={serialNumber}
                                      readOnly
                                      onClick={handleInputClick}
                                    />
                                  );
                                }
                              )
                            : null}
                          {popupOpen && (
                            <div
                              style={{
                                position: "fixed",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                backdropFilter: "blur(4px)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "1999",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  padding: "20px",
                                  borderRadius: "8px",
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                  zIndex: "999",
                                  width: "50%",
                                  height: "85%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="PopupBoxStyle">
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "90%",
                                      height: "100%",
                                    }}
                                  >
                                    {/* {currentSerialNumber || currentImageUrl ? ( */}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>
                                          Serial Number:
                                          <input
                                            className="serialno-addinput"
                                            type="text"
                                            placeholder="Serial Numbers"
                                            value={currentSerialNumber}
                                            onChange={(event) =>
                                              handleInputChange(
                                                event,
                                                serialIndex,
                                                serialID,
                                                "inverterSerial"
                                              )
                                            }
                                            // onClick={handleInputClick}
                                          />
                                        </p>
                                        <div
                                          style={
                                            {
                                              // position: "absolute",
                                              // right: "500px",
                                              // top: "30px",
                                            }
                                          }
                                        >
                                          <Button
                                            style={{
                                              display:
                                                editSerial !== serialID ||
                                                !saveButton
                                                  ? "none"
                                                  : "flex",
                                              paddingLeft: "15px",
                                            }}
                                            onClick={() =>
                                              handleSaveClick(
                                                serialID,
                                                currentSerialNumber,
                                                "inverterSerial"
                                              )
                                            }
                                          >
                                            Save
                                          </Button>
                                        </div>
                                      </div>
                                      <p>Inverter Model: {currentModel}</p>
                                    </div>
                                  </div>
                                  <button
                                    className="add-model-close-btn"
                                    type="button"
                                    style={{}}
                                    onClick={closePopup}
                                  >
                                    ✕
                                  </button>
                                </div>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "55%",
                                  }}
                                >
                                  <img
                                    style={{ width: "80%", height: "150%" }}
                                    src={currentImageUrl}
                                    alt="Serial Number Image"
                                  />
                                </div>
                                <br />
                                {currentDistance ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: "22%",
                                    }}
                                  >
                                    <span>
                                      Distance:{" "}
                                      {currentDistance > 1
                                        ? `${currentDistance} km`
                                        : `${currentDistance * 1000} m`}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(formdata?.gridConnectionDetail?.connectionType ===
                    "Connected to an electricity grid with battery storage" ||
                    formdata?.gridConnectionDetail?.connectionType ===
                      "Stand-alone (not connected to an electricity grid)") && (
                    <>
                      <div className="panel-information">
                        <div className="panel-main-bg postal-address">
                          <p className="sectionTitleText">Battery</p>
                        </div>
                        <input
                          className="search-battery"
                          style={{ width: "97%" }}
                          type="text"
                          placeholder="Search Battery"
                          value={searchTerms?.batteries || ""}
                          autoComplete="off"
                          onChange={(e) =>
                            handleProductChange("batteries", e.target.value)
                          }
                          onFocus={() => {
                            setActiveProductType("batteries");
                            setShowBatteryDropdown(true);
                          }}
                          disabled={
                            formdata?.gridConnectionDetail?.connectionType ===
                            "Connected to an electricity grid without battery storage"
                          }
                          onBlur={handleBatteryBlur}
                          // onBlur={()=>{
                          //   setShowBatteryDropdown(false);
                          // }}
                        />
                        {showBatteryDropdown && (
                          <table
                            className="createjob_panelbrand_ul-container"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              width: "94%",
                              marginLeft: "21px",
                              maxHeight: "200px",
                              overflowY: "auto",
                              zIndex: 1000,
                            }}
                          >
                            <thead>
                              <tr className="PanelDataDropDownHeader">
                                <th style={{ width: "35%" }}>Brand</th>
                                <th style={{ width: "25%" }}>Model</th>
                                <th style={{ width: "20%" }}>Series</th>
                                <th style={{ width: "20%" }}>Capacity (kWh)</th>
                              </tr>
                            </thead>
                            <tbody className="tableBodyDropdown">
                              {batteryData.length > 0 ? (
                                batteryData.map((battery, index) => (
                                  <tr
                                    className="PanelDataDropDown"
                                    key={index}
                                    onClick={() =>
                                      handleProductSelection("battery", battery)
                                    }
                                  >
                                    <td style={{ width: "35%" }}>
                                      {battery.Brand}
                                    </td>
                                    <td style={{ width: "25%" }}>
                                      {battery["Model Number"]}
                                    </td>
                                    <td style={{ width: "20%" }}>
                                      {battery.Series}
                                    </td>
                                    <td style={{ width: "20%" }}>
                                      {
                                        battery[
                                          "Nominal Battery Capacity (kWh)"
                                        ]
                                      }
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <td colSpan="4">No Battery Data found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}

                        <div className="panelAndAddButton">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {Array.isArray(
                              formdata?.batteryDetail?.batteryInfo
                            ) &&
                              formdata?.batteryDetail?.batteryInfo.map(
                                (battery, index) => (
                                  <div
                                    key={index}
                                    className="battery-info-container"
                                  >
                                    <div className="InfoAndDeleteContainer">
                                      <div>
                                        <input
                                          type="radio"
                                          name="selectedBattery"
                                          style={{ marginLeft: "4px" }}
                                          checked={
                                            selectedBatteryIndex === index
                                          }
                                          onChange={() =>
                                            handleBatteryRadioChange(index)
                                          }
                                        />
                                        <input
                                          className="inverter-input"
                                          style={{ marginLeft: "4px" }}
                                          type="text"
                                          placeholder="Brand"
                                          value={battery.batteryBrand || ""}
                                          autoComplete="off"
                                        />
                                      </div>

                                      <div className="invertermodel-boxes">
                                        <select
                                          name="batteryModel"
                                          className="installing-type"
                                          value={battery.batteryModel}
                                          defaultValue={""}
                                          onChange={(e) =>
                                            handleBatteryModelChange(e, index)
                                          }
                                        >
                                          <option value="" disabled>
                                            Model
                                          </option>
                                          {batteryBrandList[index]?.map(
                                            (batteryModel, idx) => (
                                              <option
                                                key={idx}
                                                value={
                                                  batteryModel["Model Number"]
                                                }
                                              >
                                                {batteryModel["Model Number"]}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>

                                      <div className="invertermodel-boxes">
                                        <input
                                          name="batterySeries"
                                          className="installing-type"
                                          placeholder="Series"
                                          value={battery.batterySeries || ""}
                                          readOnly
                                        />
                                      </div>

                                      <input
                                        className="quantity-input"
                                        type="number"
                                        placeholder="Quantity"
                                        name="Quantity"
                                        value={battery.Quantity || ""}
                                        onChange={(e) =>
                                          handleInputsBatteryInfo(e, index)
                                        }
                                      />
                                      {multiplefieldsBattery ||
                                      formdata?.batteryDetail?.batteryInfo
                                        .length ? (
                                        <div style={{ height: "100%" }}>
                                          <Tooltip title="Delete Battery Details">
                                            <button
                                              type="button"
                                              className="delete-field-button"
                                              onClick={() =>
                                                handleDeleteField(
                                                  "battery",
                                                  index
                                                )
                                              }
                                            >
                                              Delete
                                            </button>
                                          </Tooltip>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              )}
                          </div>

                          {/* <div class="paneladd-btn">
                          <Tooltip title="Add Battery">
                            <button
                              type="button"
                              className="plus-add"
                              onClick={handleAddBatteryInfo}
                            >
                              Add
                            </button>
                          </Tooltip>
                        </div> */}
                        </div>
                      </div>
                      {formdata?.panelDetail?.isAssignPanelJob ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p style={{ marginLeft: "17px" }}>
                            Add Serial Numbers for {manualBatteryModel}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <textarea
                              className="serialno-addinput"
                              type="Number"
                              placeholder="Add Serial Numbers"
                              value={batterySerialNumbers}
                              onChange={(e) =>
                                handleInputserialPanelNumbersChange(
                                  e,
                                  "batterySerialNumber"
                                )
                              }
                            />

                            <Tooltip title="Add Serial Numbers">
                              <button
                                type="button"
                                className="plus-add"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "20px",
                                  alignItems: "center",
                                  background: "transparent",
                                  border: "none",
                                  gap: "5px",
                                  fontSize: "14px",
                                }}
                                onClick={() => handleConfirmSubmit("submit")}
                              >
                                Submit
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      ) : null}
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                          }}
                        >
                          <a
                            href="#"
                            target="_self"
                            onClick={handleCopyInverterToClipboard}
                            style={{ paddingLeft: "20px" }}
                          >
                            <Tooltip title={toolTip}>
                              <ContentPaste
                                className="clipboard-copy"
                                sx={{ fontSize: "20px" }}
                              />
                            </Tooltip>
                          </a>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "10px",
                            }}
                          >
                            {batterySerialNumbers.length > 0 &&
                              formdata?.panelDetail?.isAssignPanelJob &&
                              batterySerialNumbers?.map((serialList, index) => {
                                const serialArray = Array.isArray(serialList)
                                  ? serialList.map((serial) => serial.trim())
                                  : serialList
                                      .split(",")
                                      .map((serial) => serial.trim());
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {serialArray.map((serial, id) => (
                                      <div style={{ position: "relative" }}>
                                        <input
                                          id={id}
                                          className="serialno-addinput"
                                          type="text"
                                          placeholder="Serial Numbers"
                                          value={serial}
                                          name="serialNumber"
                                          onChange={
                                            handleInputPanelSerialNumber
                                          }
                                        />

                                        <div
                                          style={{
                                            position: "absolute",
                                            right: "5px",
                                            top: "10px",
                                          }}
                                        >
                                          <Tooltip title="Delete">
                                            <CloseRounded
                                              // onClick={() =>
                                              //   handleDeleteSerialNumber(index, id)
                                              // }
                                              onClick={() =>
                                                handleDeleteSerialNumber(
                                                  index,
                                                  id,
                                                  "battery"
                                                )
                                              }
                                              sx={{ fontSize: "medium" }}
                                            />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })}
                            {serialNumberLoad ? (
                              <FadeLoader color="#154397" />
                            ) : multipleBatterySerials?.length > 0 &&
                              !onSiteSerial &&
                              formdata?.panelDetail?.isAssignPanelJob ? (
                              multipleBatterySerials?.map((serial, index) => (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    className="serialno-addinput"
                                    type="text"
                                    placeholder="Serial Number"
                                    name="serialNumber"
                                    value={serial?.serialNumber}
                                    onChange={(event) =>
                                      handleInputChange(
                                        event,
                                        index,
                                        serial?._id,
                                        "batterySerialEdit"
                                      )
                                    }
                                  />

                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "10px",
                                    }}
                                  >
                                    <Tooltip title="Delete">
                                      <CloseRounded
                                        onClick={() =>
                                          handleConfirmSubmit(
                                            "delete",
                                            index,
                                            serial?._id,
                                            "battery"
                                          )
                                        }
                                        sx={{ fontSize: "medium" }}
                                      />
                                      {/* sx={{ fontSize: "medium" }} */}
                                      {/* /> */}
                                    </Tooltip>
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "10px",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        display:
                                          editSerial !== serial?._id ||
                                          !saveButton
                                            ? "none"
                                            : "flex",
                                        paddingLeft: "15px",
                                      }}
                                      onClick={() =>
                                        handleSaveClick(
                                          serial?._id,
                                          serial?.serialNumber,
                                          "batterySerial"
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              ))
                            ) : null}
                            {!formdata?.panelDetail?.isAssignPanelJob
                              ? Array.from(
                                  { length: totalBatteryQuantity },
                                  (_, index) => {
                                    const battery =
                                      batterySerialNumber &&
                                      batterySerialNumber[index];
                                    const serialNumber = battery
                                      ? battery.serialNumber
                                      : "";
                                    const batteryModel = battery
                                      ? battery.batteryModel
                                      : "";
                                    const imageUrl = battery
                                      ? battery.batteryPhoto
                                      : "";
                                    const distance = battery
                                      ? battery.distance
                                      : null;
                                    const batteryId = battery?._id;
                                    const handleInputClick = () => {
                                      if (serialNumber) {
                                        openPopupBattery(
                                          serialNumber,
                                          batteryModel,
                                          imageUrl,
                                          distance,
                                          batteryId
                                        );
                                      }
                                      // else {
                                      //   openPopupBattery();
                                      // }
                                    };
                                    return (
                                      <input
                                        className="serialno-addinput"
                                        type="text"
                                        placeholder="Serial Numbers"
                                        value={serialNumber}
                                        readOnly
                                        onClick={handleInputClick}
                                      />
                                    );
                                  }
                                )
                              : null}
                            {popupOpenBattery && (
                              <div
                                style={{
                                  position: "fixed",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "90%",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  backdropFilter: "blur(4px)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: "1999",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "8px",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                    zIndex: "999",
                                    width: "50%",
                                    height: "85%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="PopupBoxStyle">
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "90%",
                                        // height: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p>
                                            Serial Number:
                                            <input
                                              className="serialno-addinput"
                                              type="text"
                                              placeholder="Serial Numbers"
                                              value={currentSerialNumber}
                                              onChange={(event) =>
                                                handleInputChange(
                                                  event,
                                                  serialIndex,
                                                  serialID,
                                                  "batterySerial"
                                                )
                                              }
                                            />
                                          </p>
                                          <div
                                            style={
                                              {
                                                // position: "absolute",
                                                // right: "500px",
                                                // top: "30px",
                                              }
                                            }
                                          >
                                            <Button
                                              style={{
                                                display:
                                                  editSerial !== serialID ||
                                                  !saveButton
                                                    ? "none"
                                                    : "flex",
                                                paddingLeft: "15px",
                                              }}
                                              onClick={() =>
                                                handleSaveClick(
                                                  serialID,
                                                  currentSerialNumber,
                                                  "batterySerial"
                                                )
                                              }
                                            >
                                              Save
                                            </Button>
                                          </div>
                                        </div>
                                        <p>Battery Model: {currentModel}</p>
                                      </div>
                                      {/* ) : (
                                      <>
                                        <br />
                                        <p>Serial Number</p>
                                      </>
                                    )} */}
                                    </div>
                                    <button
                                      className="add-model-close-btn"
                                      type="button"
                                      style={{}}
                                      onClick={closePopupBattery}
                                    >
                                      ✕
                                    </button>
                                  </div>
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      height: "55%",
                                    }}
                                  >
                                    {/* {currentSerialNumber ? ( */}
                                    <img
                                      style={{ width: "80%", height: "150%" }}
                                      src={currentImageUrl}
                                      alt="Serial Number Image"
                                    />
                                    {/* ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <input
                                        id="inputSerial"
                                        className="quantity-input"
                                        style={{ width: "300px" }}
                                        type="text"
                                        placeholder="Battery Model"
                                        name="batteryModel"
                                        onChange={handleInputSerialNumber}
                                      />
                                      <input
                                        id="inputSerial"
                                        className="quantity-input"
                                        style={{ width: "300px" }}
                                        type="text"
                                        placeholder="Serial Number"
                                        name="serialNumber"
                                        onChange={handleInputSerialNumber}
                                      />
                                      <input
                                        id="inputSerial"
                                        className="quantity-input"
                                        style={{ width: "300px" }}
                                        type="file"
                                        placeholder="File"
                                        name="batteryPhoto"
                                        onChange={handleInputSerialNumber}
                                      />
                                      <br />
                                      <div className="add-model-actions">
                                        <button
                                          type="button"
                                          className="submit-hover"
                                          onClick={handleInverterSerialSubmit}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  )} */}
                                  </div>
                                  <br />
                                  {currentDistance ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "27%",
                                      }}
                                    >
                                      <span>
                                        Distance:{" "}
                                        {currentDistance > 1
                                          ? `${currentDistance} km`
                                          : `${currentDistance * 1000} m`}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="panel-information">
                    <div className="panel-main-bg postal-address">
                      <p className="sectionTitleText">Warranty Detail</p>
                    </div>
                    <div className="SubSectionEditJob">
                      <div
                        style={{ display: "flex", gap: "20px" }}
                        className="subSectionInsideDiv"
                      >
                        <input
                          className="quantity-input"
                          style={{ margin: "0px", width: "auto" }}
                          type="number"
                          name="warrantyYear"
                          placeholder="Warranty Years"
                          onChange={handleInputsPanelWarrantyDetails}
                          value={formdata?.warrantyDetails?.warrantyYear || ""}
                        />
                        <div>
                          <textarea
                            className="warranty-description-textarea"
                            style={{
                              width: "580px",
                              height: "52px",
                              borderRadius: "5px",
                            }}
                            type="text"
                            name="warrantyDescription"
                            placeholder="Warranty Description"
                            onChange={handleInputsPanelWarrantyDetails}
                            value={
                              formdata?.warrantyDetails?.warrantyDescription ||
                              ""
                            }
                            maxLength={500}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-information">
                    <div className="panel-main-bg postal-address">
                      <p className="sectionTitleText">Installation Team</p>
                    </div>
                  </div>
                </div>
                <div className="subSectionInstallationteam">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="row">
                          <button
                            type="button"
                            onClick={() => formdata?.installers[0]? viewTeam(formdata?.installers[0]):alert("Select an Installer First")}
                            className="view-details"
                          >
                            View Details
                          </button>
                        <div className="">
                          <h4>Installer Selection</h4>
                          <div className="name-boxes">
                            <select
                              className="installing-type"
                              onChange={handleSelectInstallerChange}
                              value={
                                formdata?.installers?.length > 0 &&
                                formdata?.installers[0]
                              }
                            >
                              <option value="">Name</option>
                              {/* {installerData.map((installer) => {
                              return (
                                formdata?.installationDate?.installingDate === installer?.cecExpiry  ?
                                <option value={installer.id} >
                                    {installer.name}
                                </option>
                                  :null
                              );
                            })} */}
                              {installerData.map((installer) => {
                                const installingDate = moment(
                                  formdata?.installationDate?.installingDate
                                );
                                let cecExpiry = moment(installer?.cecExpiry);
                                const fifteenDaysBeforeExpiry = cecExpiry.diff(
                                  installingDate,
                                  "days"
                                );
                                if (fifteenDaysBeforeExpiry <= 1) {
                                  return null;
                                } else {
                                  return (
                                    <option
                                      key={installer.id}
                                      value={installer.id}
                                    >
                                      {installer.name}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      {isViewTeamOpen && (
                        <ProfileInstallationTeam
                          onClose={() => setViewTeamOpen(false)}
                        />
                      )}
                      <div className="row">
                          <button
                            type="button"
                            onClick={() => formdata?.electricians[0] ? viewTeam(formdata?.electricians[0]):alert("Select an Electrician First")}
                            className="view-details"
                          >
                            View Details
                          </button>

                        <h4>Electrician Selection</h4>
                        <div className="name-boxes">
                          <select
                            className="installing-type"
                            onChange={handleSelectElectricianChange}
                            value={
                              formdata?.electricians?.length > 0 &&
                              formdata?.electricians[0]
                            }
                          >
                            <option value="">Name</option>
                            {electricianData.map((installer) => {
                              const installingDate = moment(
                                formdata?.installationDate?.installingDate
                              );
                              let cecExpiry = moment(installer?.cecExpiry);
                              const fifteenDaysBeforeExpiry = cecExpiry.diff(
                                installingDate,
                                "days"
                              );
                              if (fifteenDaysBeforeExpiry <= 1) {
                                return null;
                              } else {
                                return (
                                  <option
                                    key={installer.id}
                                    value={installer.id}
                                  >
                                    {installer.name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="row">
                          <button
                            type="button"
                            onClick={() => formdata?.designers[0]?viewTeam(formdata?.designers[0]):alert("Select an Designer first")}
                            className="view-details"
                          >
                            View Details
                          </button>
                       
                        <h4>Designer Selection</h4>
                        <div className="name-boxes">
                          <select
                            className="installing-type"
                            onChange={handleSelectDesignerChange}
                            value={
                              formdata?.designers?.length > 0 &&
                              formdata?.designers[0]
                            }
                          >
                            <option value="">Name</option>
                            {designerData.map((installer) => {
                              const installingDate = moment(
                                formdata?.installationDate?.installingDate
                              );
                              let cecExpiry = moment(installer?.cecExpiry);
                              const fifteenDaysBeforeExpiry = cecExpiry.diff(
                                installingDate,
                                "days"
                              );
                              if (fifteenDaysBeforeExpiry <= 1) {
                                return null;
                              } else {
                                return (
                                  <option
                                    key={installer.id}
                                    value={installer.id}
                                  >
                                    {installer.name}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 60 }}
                    >
                      <div className="selection-photos">
                        <div style={{ display: "flex" }}>
                          <ul className="setup-selfi">
                            <li>
                              {/* {photos &&
                            photos.length > 0 &&
                            photos.find(
                              (photo) => photo?.type === "firstSelfie"
                            ) ? ( */}
                              {startSelfie ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    className="first-selfiimg"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                    }}
                                    src={startSelfie?.url}
                                    alt="First  Selfie"
                                    onClick={() =>
                                      openModal(
                                        startSelfie?.url,
                                        "Start Selfie",
                                        startSelfie?.distance
                                      )
                                    }
                                  />
                                  <button
                                    type="button"
                                    class="onsitedata-model-close-btn"
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "-12px",
                                    }}
                                    onClick={() => setStartSelfie(null)}
                                  >
                                    ✕
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <label htmlFor="fileInput-firstSelfie">
                                    <img
                                      className="first-selfiimg"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                      }}
                                      src={`${image}/Assets/CreateJob/onsite-last-selfi.svg`}
                                      alt=""
                                    />
                                  </label>
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="fileInput-firstSelfie"
                                    accept="image/png, image/jpeg, application/pdf"
                                    name="uploadFile"
                                    onChange={handleSelfieUpdate(
                                      "installerPresence",
                                      "firstSelfie"
                                    )}
                                  />
                                </div>
                              )}
                              <span className="set-date">Start Selfie</span>
                              <span className="set-date">
                                {photos && startSelfie?.dateAndTime
                                  ? moment(startSelfie?.dateAndTime).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )
                                  : ""}
                              </span>
                              {startSelfie && startSelfie?.distance && (
                                <span className="set-date">
                                  Distance:{" "}
                                  {startSelfie?.distance > 1
                                    ? `${startSelfie.distance} km`
                                    : `${startSelfie.distance * 1000} m`}
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="selection-photos">
                        <div style={{ display: "flex" }}>
                          <ul className="setup-selfi">
                            <li>
                              {middleSelfie ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    className="first-selfiimg"
                                    src={middleSelfie?.url}
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                    }}
                                    alt="Middle selfie"
                                    onClick={() =>
                                      openModal(
                                        middleSelfie?.url,
                                        "Middle Selfie",
                                        middleSelfie?.distance
                                      )
                                    }
                                  />
                                  <button
                                    type="button"
                                    class="onsitedata-model-close-btn"
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "-12px",
                                    }}
                                    onClick={() => setMiddleSelfie(null)}
                                  >
                                    ✕
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <label htmlFor="fileInput-middleSelfie">
                                    <img
                                      className="first-selfiimg"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                      }}
                                      src={`${image}/Assets/CreateJob/onsite-last-selfi.svg`}
                                      alt=""
                                    />
                                  </label>
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="fileInput-middleSelfie"
                                    accept="image/png, image/jpeg, application/pdf"
                                    name="uploadFile"
                                    onChange={handleSelfieUpdate(
                                      "installerPresence",
                                      "middleSelfie"
                                    )}
                                  />
                                </div>
                              )}
                              <span className="set-date">Mid Selfie</span>
                              <span className="set-date">
                                {middleSelfie && middleSelfie?.dateAndTime
                                  ? moment(middleSelfie?.dateAndTime).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )
                                  : ""}
                              </span>
                              {middleSelfie && middleSelfie?.distance && (
                                <span className="set-date">
                                  Distance:{" "}
                                  {middleSelfie?.distance > 1
                                    ? `${middleSelfie.distance} km`
                                    : `${middleSelfie.distance * 1000} m`}
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="selection-photos">
                        <div style={{ display: "flex" }}>
                          <ul className="setup-selfi">
                            <li>
                              {endSelfie ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    className="first-selfiimg"
                                    src={endSelfie?.url}
                                    alt="End Selfie"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                    }}
                                    onClick={() =>
                                      openModal(
                                        endSelfie?.url,
                                        "End Selfie",
                                        endSelfie?.distance
                                      )
                                    }
                                  />
                                  <button
                                    type="button"
                                    class="onsitedata-model-close-btn"
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "-12px",
                                    }}
                                    onClick={() => setEndSelfie(null)}
                                  >
                                    ✕
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <label htmlFor="fileInput-endSelfie">
                                    <img
                                      className="first-selfiimg"
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                      }}
                                      src={`${image}/Assets/CreateJob/onsite-last-selfi.svg`}
                                      alt=""
                                    />
                                  </label>
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="fileInput-endSelfie"
                                    accept="image/png, image/jpeg, application/pdf"
                                    name="uploadFile"
                                    onChange={handleSelfieUpdate(
                                      "installerPresence",
                                      "endSelfie"
                                    )}
                                  />
                                </div>
                              )}
                              <span className="set-date">Last Selfie</span>
                              <span className="set-date">
                                {endSelfie && endSelfie?.dateAndTime
                                  ? moment(endSelfie?.dateAndTime).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )
                                  : ""}
                              </span>
                              {endSelfie && endSelfie?.distance && (
                                <span className="set-date">
                                  Distance:{" "}
                                  {endSelfie?.distance > 1
                                    ? `${endSelfie.distance} km`
                                    : `${endSelfie.distance * 1000} m`}
                                </span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <textarea
                      className="instructions-input"
                      type="text"
                      name="instructionForInstallation"
                      placeholder="Instructions for Installation"
                      value={formdata?.instructionForInstallation || ""}
                      onChange={handleInputsInstructionForInstallation}
                      maxLength={500}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="system-approved">
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className="system-text">
                          CEC Accreditation System
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="CECAccreditationSystemYes"
                              type="radio"
                              name="CECAccreditationSystem"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.CECAccreditationSystem === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="CECAccreditationSystemYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="CECAccreditationSystemNo"
                              type="radio"
                              name="CECAccreditationSystem"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.CECAccreditationSystem === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="CECAccreditationSystemNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>

                        <label className="system-text">
                          Electrical Safety Documentation
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="electricalSafetyDocYes"
                              type="radio"
                              name="electricalSafetyDoc"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.electricalSafetyDoc === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="electricalSafetyDocYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="electricalSafetyDocNo"
                              type="radio"
                              name="electricalSafetyDoc"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.electricalSafetyDoc === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="electricalSafetyDocNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>

                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="approved-govt"
                      >
                        <label className="system-text">
                          Local, State and Territory Government Sitting Approval
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="territoryGovermentApprovalYes"
                              type="radio"
                              name="territoryGovermentApproval"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.territoryGovermentApproval === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="territoryGovermentApprovalYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="territoryGovermentApprovalNo"
                              type="radio"
                              name="territoryGovermentApproval"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.territoryGovermentApproval === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="territoryGovermentApprovalNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                        <label className="system-text">
                          Australian / New Zealand Standard Statement
                        </label>

                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="AusNZStandardStatementYes"
                              type="radio"
                              name="AusNZStandardStatement"
                              className="input-text"
                              value="true"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.AusNZStandardStatement === true
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="AusNZStandardStatementYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="AusNZStandardStatementNo"
                              type="radio"
                              name="AusNZStandardStatement"
                              className="input-text"
                              value="false"
                              onChange={handleInputsInstallationDetails}
                              checked={
                                formdata?.installationDetails
                                  ?.AusNZStandardStatement === false
                              }
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="AusNZStandardStatementNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-information">
                  <div className="panel-main-bg postal-address">
                    <p className="sectionTitleText">Additional Attributes</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "40px",
                      }}
                    >
                      <div>
                        <div style={{ display: "flex" }}>
                          <input
                            className="attributes-input"
                            type="text"
                            placeholder=" CRM ID or Project ID"
                            name="CRMID"
                            maxLength={20}
                            value={formdata?.additionalAttributes?.CRMID || ""}
                            onChange={handleInputAdditionalDetails}
                          />
                          <input
                            className="retailer-input"
                            type="text"
                            placeholder="Current Energy Retailer ID"
                            name="currentEnergyRetailer"
                            maxLength={30}
                            value={
                              formdata?.additionalAttributes
                                ?.currentEnergyRetailer || ""
                            }
                            onChange={handleInputAdditionalDetails}
                          />
                        </div>
                        <div className="retailer-main doc-upload">
                          <div style={{ display: "flex" }}>
                            <label className="system-any">
                              <b>Will there be any optimisers installed?</b>
                              <ul className="system-main">
                                <li className="main-text">
                                  {" "}
                                  <input
                                    id="optimisersYes"
                                    className="input-text"
                                    type="radio"
                                    name="WillThereBeAnyOptimisersInstalled"
                                    value="true"
                                    onChange={handleInputAdditionalDetails}
                                    checked={
                                      formdata?.additionalAttributes
                                        ?.WillThereBeAnyOptimisersInstalled ===
                                      true
                                    }
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="optimisersYes"
                                  >
                                    Yes
                                  </label>
                                </li>
                                <li>
                                  {" "}
                                  <input
                                    id="optimisersNo"
                                    className="input-text"
                                    type="radio"
                                    name="WillThereBeAnyOptimisersInstalled"
                                    value="false"
                                    onChange={handleInputAdditionalDetails}
                                    checked={
                                      formdata?.additionalAttributes
                                        ?.WillThereBeAnyOptimisersInstalled ===
                                      false
                                    }
                                  />
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="optimisersNo"
                                  >
                                    No
                                  </label>
                                </li>
                              </ul>
                            </label>
                            <div
                              style={{ position: "relative", display: "none" }}
                            >
                              <input
                                ref={fileInputRef3}
                                className="current-input"
                                type="file"
                                id="uploadElectricityBill"
                                placeholder="Upload Electricity Bill"
                                accept="image/png, image/jpeg, application/pdf"
                                name="uploadElectricityBill"
                                onChange={handleInputsUploadDocuments}
                              />
                              {!formdata?.uploadElectricityBill ? (
                                <img
                                  src={`${image}/Assets/CreateJob/doc-upload.svg`}
                                  alt=""
                                  className="upload"
                                  onClick={handleImageClick3}
                                />
                              ) : (
                                <img
                                  src={`${image}/Assets/UploadFiles/subtract.svg`}
                                  alt=""
                                  className="upload"
                                  onClick={() => {
                                    handleRemoveImage("uploadElectricityBill");
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <input
                            className="meter-input"
                            type="text"
                            placeholder="Meter Approval letter"
                            name="jobReferenceNumber"
                            maxLength={20}
                            value={
                              formdata?.additionalAttributes
                                ?.jobReferenceNumber || ""
                            }
                            onChange={handleInputAdditionalDetails}
                          />
                        </div>
                      </div>
                      <div>
                        <textarea
                          className="instructions-input notes-input"
                          type="text"
                          name="notes"
                          placeholder="Notes"
                          value={formdata?.notes || ""}
                          onChange={handleInputsInstructionForInstallation}
                          maxLength={500}
                        />
                      </div>
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div>
                        <label className="system-text">
                          Creating Certificates for previously failed PV?
                        </label>
                        <ul className="system-main">
                          <li className="main-text">
                            {" "}
                            <input
                              id="failedPVYes"
                              type="radio"
                              name="isItForPreviouslyFailedPV"
                              className="input-text"
                              value="true"
                              checked={
                                formdata?.failedPV
                                  ?.isItForPreviouslyFailedPV === true
                              }
                              onChange={handlefailedPVChange}
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="failedPVYes"
                            >
                              Yes
                            </label>
                          </li>
                          <li>
                            {" "}
                            <input
                              id="failedPVNo"
                              type="radio"
                              name="isItForPreviouslyFailedPV"
                              className="input-text"
                              value="false"
                              checked={
                                formdata?.failedPV
                                  ?.isItForPreviouslyFailedPV === false
                              }
                              onChange={handlefailedPVChange}
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="failedPVNo"
                            >
                              No
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <input
                          className="attributes-input"
                          type="text"
                          style={{ margin: "0px 10px" }}
                          placeholder="Accreditation Code"
                          name="accreditationCode"
                          maxLength={20}
                          value={formdata?.failedPV?.accreditationCode || ""}
                          disabled={
                            formdata?.failedPV?.isItForPreviouslyFailedPV ===
                            false
                          }
                          onChange={handlefailedPVChange}
                        />
                      </div>
                      <textarea
                        className="instructions-input notes-input"
                        style={{
                          height: "70px",
                          width: "305px",
                          margin: "15px 0 0 0",
                        }}
                        type="text"
                        name="explanatoryNotes"
                        placeholder="Explanatory Notes"
                        value={formdata?.failedPV?.explanatoryNotes || ""}
                        disabled={
                          formdata?.failedPV?.isItForPreviouslyFailedPV ===
                          false
                        }
                        onChange={handlefailedPVChange}
                        maxLength={500}
                      />
                    </div>
                  </div>
                </div>
                <div className="panel-information">
                  <ManageFolder
                    deletePanelImage={deleteSerialNumberHandler}
                    deleteLoading={deleteLoading}
                  />
                </div>
                <br />
                <div className="panel-information">
                  <DocumentRequired
                    getJobData={fetchJobData}
                    jobData={formdata}
                  />
                </div>

                <br />
                <div className="panel-information">
                  <SignaturePage
                    emailId={formdata?.ownerDetails?.emailId}
                    handleSubmit={handleSubmit}
                    jobType={formdata?.jobType}
                  />
                </div>
                <br />
                <div className="footer-bg">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "90%",
                      paddingLeft: "10%",
                    }}
                  >
                    <div>
                      <button
                        type="submit"
                        className="btn-partial"
                        // onClick={handleSerialNumbersSubmit}
                      >
                        Partial Save & Exit
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          window.close();
                          localStorage.removeItem("jobId");
                        }}
                        className="submit-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div>
                    {statusCode === 3 && shouldShowFinishButton() && (
                      <button
                        type="button"
                        onClick={() => {
                          handleButtonClick(6);
                        }}
                        className="submit-btn"
                      >
                        Finish Job
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div
              style={{
                display: "flex",
                height: "60vh",
                justifyContent: "center",
                alignItems: "center",
                width: "82vw",
              }}
            >
              <FadeLoader color="#154397" radius={100} />
            </div>
          )}
        </div>
      </div>
      <ImageModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        image={modalImage}
        label={modalLabel}
        distance={modalDistance}
      />
    </div>
  );
};

export default CreatePVJob;
