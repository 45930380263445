import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const image = process.env.PUBLIC_URL;

const PanelSerial = () => {
  const token = localStorage.getItem("userToken");
  const jobId = localStorage.getItem("jobId");

  //Storing Data from API
  const [photos, setPhotos] = useState([]);
  const [panelsData, setPanelData] = useState([]);
  const [panelInfo, setPanelInfo] = useState();
  //Get On Site Details Starts
  useEffect(() => {
    const fetchOnSiteData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/onSite`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = resp?.data?.data;
        const flattenedData = data.flat();
        const result = flattenedData.filter((item) => item.jobId === jobId);
        const photos = result[0]?.photos[0]?.installerPresence;
        const panels = result[0]?.panels;
        setPhotos(photos);
        setPanelData(panels);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOnSiteData();
  }, []);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const resp = await axios.get(`${baseURL}/job/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const flattenedData = resp.data.flat();
        const result = flattenedData.filter((item) => item._id === jobId);
        const panel = result[0]?.panelDetail?.panelInfo;
        setPanelInfo(panel);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchJobData();
  }, []);
  //Get On Site Details Ends

  const [addPanelPopUp, setAddPanelPopUp] = useState(false);

  return (
    <>
      <div className="row">
        <div className="checkin-panel">
          <h3>Check In</h3>
        </div>

        <div style={{ display: "flex" }}>
          <ul className="setup-selfi">
            <li>
              {photos &&
              photos.length > 0 &&
              photos[0]?.type === "firstSelfie" ? (
                <img
                  src={photos[0]?.url}
                  alt=""
                  style={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <img
                  src={`${image}/Assets/OnSiteData/LeftPanel/onsite-last-selfi.svg`}
                  alt=""
                />
              )}
              <span className="set-selfi">Setup Selfie</span>
            </li>
            <li>
              {photos &&
              photos.length > 0 &&
              photos[1]?.type === "middleSelfie" ? (
                <img
                  src={photos[1]?.url}
                  alt=""
                  style={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <img
                  src={`${image}/Assets/OnSiteData/LeftPanel/onsite-last-selfi.svg`}
                  alt=""
                />
              )}
              <span className="set-selfi">Mid Selfie</span>
            </li>
            <li>
              {photos &&
              photos.length > 0 &&
              photos[2]?.type === "endSelfie" ? (
                <img
                  src={photos[2]?.url}
                  alt=""
                  style={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <img
                  src={`${image}/Assets/OnSiteData/LeftPanel/onsite-last-selfi.svg`}
                  alt=""
                />
              )}
              <span className="set-selfi">Last Selfie</span>
            </li>
            <li>
              {photos &&
              photos.length > 0 &&
              photos[3]?.type === "extraSelfie" ? (
                <img
                  src={photos[3]?.url}
                  alt=""
                  style={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <img
                  src={`${image}/Assets/OnSiteData/LeftPanel/onsite-last-selfi.svg`}
                  alt=""
                />
              )}
              <span className="set-selfi">Extra Selfie</span>
            </li>
          </ul>
        </div>

        <div className="row">
          <div style={{ display: "flex" }}>
            <div className="panelserial-bg">
              <b>Panel Serials</b>
            </div>
            <div class="paneladd-btn">
              <button
                className="btn-invite"
                onClick={() => {
                  setAddPanelPopUp(false);
                  alert("This feature will be functional later.");
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div className="serial-section">
            <div className="serial-main-bg">
              <table>
                <tr className="panelserial-table">
                  <td className="serial-main">
                    <b>Serial</b>
                  </td>
                  <td className="serial-role">
                    <b>Brand</b>
                  </td>
                  <td className="serial-role">
                    <b>Model</b>
                  </td>
                  <td className="serial-role">
                    <b>SPV</b>
                  </td>
                  <td className="serial-actions">
                    <b>Action</b>
                  </td>
                </tr>
              </table>
              <table>
                {panelsData.map((panel) => (
                  <tr className="panelserial-table" key={panel._id}>
                    <td className="serial-maindata">{panel?.serialNumber}</td>
                    <td className="serial-maindata">{panelInfo?.panelBrand}</td>
                    <td className="serial-maindata">{panelInfo?.panelModel}</td>
                    <td className="serial-maindata">
                      {panel.spvVerified.toString()}
                    </td>
                    <td className="serial-maindatadelete">
                      <img
                        src={`${image}/Assets/OnSiteData/LeftPanel/onsite-action-button.svg`}
                        alt=""
                        onClick={() => {
                          setAddPanelPopUp(false);
                          alert("This feature will be functional later.");
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </table>
              <div className="panel-data" style={{ display: "none" }}>
                <p>No Panel Serial Found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {addPanelPopUp && (
          <div className="invite-model-backdrop">
            <div className="delete-model-content onsitedate-popup-height">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="text-panel">Panel Serials</h2>
                <button
                  class="onsitedata-model-close-btn"
                  onClick={() => setAddPanelPopUp(false)}
                >
                  ✕
                </button>
              </div>
              <span className="panel-bottom-border"></span>

              <div className="row">
                <form method="post">
                  <input
                    className="onsitedata-input"
                    type="text"
                    placeholder="Serial"
                    required
                  />
                  <input
                    className="onsitedatabrand-input"
                    type="text"
                    placeholder="Brand"
                    autoComplete="off"
                    required
                  />
                  <div className="onsite-boxes">
                    <select
                      className="installing-type"
                      name="panelModel"
                      id="panelModel"
                      required
                    >
                      <option value="" disabled>
                        Model
                      </option>
                    </select>
                  </div>

                  <input
                    className="onsitspv-input"
                    type="text"
                    placeholder="SPV"
                    autoComplete="off"
                    required
                  />
                </form>
              </div>

              <span className="panel-bottom-border"></span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className="onsitebtn-cancel">Cancel</button>
                <button className="onsitebtn-save">Save</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PanelSerial;
